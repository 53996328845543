import { Box, IconButton, TextField, Typography } from '@mui/material'
import { Edit2, Plus, X } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { DropdownTitleWrapper, DropdownWrapper } from 'common/components/FilterSearchDropdown'
import { FilterLabel } from './FilterLabel'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { animateProps } from '../util/filterAnimateProps'
import { Check } from 'lucide-react'
import { useDebounce } from 'react-use'
import { keys } from 'lodash'
import Button from 'common/components/Button'
import { Chip } from 'common/components/Chip'

const CustomTagRow = ({ isNew, onInputChange, item, onAddNew, onRemove }) => {
  const [isDisabled, setIsDisabled] = useState(!isNew)
  const keyInputRef = useRef()
  const valueInputRef = useRef()
  useEffect(() => {
    if (!isDisabled) {
      keyInputRef?.current?.focus()
    }
  }, [isDisabled])
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '15px',
      }}
    >
      <TextField
        inputRef={keyInputRef}
        disabled={isDisabled}
        variant="outlined"
        size="small"
        value={item.key}
        onChange={(e) => onInputChange({ key: 'key', value: e.target.value })}
        placeholder={isNew ? 'Key' : ''}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && item.key) {
            valueInputRef?.current?.focus()
          }
        }}
      />
      <Typography
        variant="textSecondary"
        color="text.secondary"
        sx={{
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        =
      </Typography>
      <TextField
        inputRef={valueInputRef}
        disabled={isDisabled}
        variant="outlined"
        value={item.value}
        onBlur={() => !isNew && setIsDisabled(true)}
        onChange={(e) => onInputChange({ key: 'value', value: e.target.value })}
        placeholder={isNew ? 'Value' : ''}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && item.key && item.value) {
            if (isNew) {
              onAddNew()
              keyInputRef?.current?.focus()
            } else {
              setIsDisabled((prev) => !prev)
            }
          }
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '120px',
        }}
      >
        {isNew ? (
          <Button
            variant="outlined"
            sx={{
              color: 'text.secondary',
              minWidth: '100%',
            }}
            onClick={onAddNew}
            disabled={!item.key || !item.value}
          >
            <Plus size={15} />
          </Button>
        ) : (
          <>
            <IconButton
              sx={{
                color: 'text.secondary',
              }}
              onClick={() => {
                setIsDisabled((prev) => !prev)
              }}
            >
              {isDisabled ? <Edit2 size={13} /> : <Check size={15} />}
            </IconButton>
            <IconButton
              sx={{
                color: 'text.secondary',
              }}
              onClick={() => onRemove(item.key)}
            >
              <X size={15} />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  )
}
export const CustomTagsFilter = ({ filter, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [tags, setTags] = useState(
    keys(filter.value).map((key) => ({ key, value: filter.value?.[key] }))
  )
  const [newTag, setNewTag] = useState({
    key: '',
    value: '',
  })

  useDebounce(
    () => {
      onChange(filter, tags)
    },
    500,
    [JSON.stringify(tags)]
  )
  useEffect(() => {
    setTags(keys(filter.value).map((key) => ({ key, value: filter.value?.[key] })))
  }, [filter])
  const onRemove = (key) => setTags((prev, idx) => prev.filter((i) => i.key !== key))
  return (
    <DropdownWrapper is-open={isOpen ? 'true' : undefined}>
      <DropdownTitleWrapper
        is-collapsible="true"
        is-open={isOpen ? 'true' : undefined}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FilterLabel label={filter.label} />

          <DropdownIcon isOpen={isOpen} />
        </Box>
        {/* Show selected tags only when dropdown is closed, and only show first 8 with a message stating the total number of tags selected */}
        {tags?.length && !isOpen ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}
            {...animateProps}
          >
            {tags.slice(0, 8)?.map((tag) => (
              <Chip
                key={tag?.value}
                label={`${tag?.key}:${tag?.value}`}
                onDelete={(e) => onRemove(tag?.key)}
                size="small"
                autoTruncate={false}
              />
            ))}
          </Box>
        ) : null}
        {tags?.length > 8 && !isOpen ? (
          <Typography variant="textTertiary" color="text.secondary">
            and {tags?.length - 8} more...
          </Typography>
        ) : null}
        {isOpen && tags?.length ? (
          <Typography variant="textTertiary" color="text.secondary">
            {tags?.length} Added
          </Typography>
        ) : null}
      </DropdownTitleWrapper>
      {isOpen && (
        <Box
          sx={{
            overflow: 'hidden',
            padding: '10px',
          }}
          {...animateProps}
        >
          {tags.map((item, index) => (
            <CustomTagRow
              key={`custom-tags-${item?.key}-${index}`}
              item={item}
              onInputChange={({ key, value }) =>
                setTags((prev) =>
                  prev.map((item, idx) => (index === idx ? { ...item, [key]: value } : item))
                )
              }
              onRemove={onRemove}
            />
          ))}
          <CustomTagRow
            isNew
            item={newTag}
            onInputChange={({ key, value }) => setNewTag((prev) => ({ ...prev, [key]: value }))}
            onAddNew={() => {
              if (newTag.key && newTag.value) {
                setTags((prev) => [...prev, newTag])
                setNewTag({ key: '', value: '' })
              }
            }}
          />
        </Box>
      )}
    </DropdownWrapper>
  )
}
