import { useContext, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Divider,
  accordionSummaryClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  globalScopes as scopes,
  FilterContext,
  timeFrameDefaults,
} from 'filter/context/FilterContext'
import { TimeFrame } from 'filter/components/TimeFrame'
import { FiltersWidget } from 'filter/components/FiltersWidget'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { motion } from 'framer-motion'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { getFormattedTimeFrameRange } from 'filter/util/time'

export const ExplorerSidebar = () => {
  const { getFilterValue, setFilterValue } = useContext(FilterContext)
  const [expandedTimeFrame, setExpandedTimeFrame] = useState(false)
  const globalTimeFrame = getFilterValue('globalTimeFrame')

  const selectedTimeFrame = timeFrameDefaults.find(({ value }) => value === globalTimeFrame)

  const timeFrameLabel = selectedTimeFrame
    ? selectedTimeFrame.label
    : getFormattedTimeFrameRange(globalTimeFrame)

  const onExpandChange = (event, isExpanded) => {
    setExpandedTimeFrame(isExpanded)
  }

  const closeExpand = () => {
    setExpandedTimeFrame(false)
  }

  const scope = getFilterValue('globalScope')

  /**
   * Prepare Dropdown for Scope
   */

  // Format data to work with Dropdown component
  let formattedScope
  const formattedScopes = []
  scopes.forEach((s) => {
    formattedScopes.push({
      displayValue: s.displayName,
      icon: s.icon,
      value: s.name,
    })
    if (s.name === scope) {
      formattedScope = {
        displayValue: s.displayName,
        icon: s.icon,
        value: s.name,
      }
    }
  })

  return (
    <PrimaryBar
      initial={{ x: -20, opacity: 0 }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { delay: 0.25, duration: 0.3, ease: 'easeOut' },
      }}
    >
      <Box
        paddingLeft="5px"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        margin="14px 0 20px 0"
      >
        <IconAwsLambda size={15} />
        <Typography variant="h3" sx={{ margin: '0 0 0 10px' }}>
          {formattedScope?.displayValue}
        </Typography>
      </Box>
      <Divider />
      <StyledAccordion expanded={expandedTimeFrame} onChange={onExpandChange}>
        <StyledAccordionSummary aria-controls="timeframe-panel-content" id="timeframe-panel">
          <Typography variant="h3">{timeFrameLabel}</Typography>
          <DropdownIcon isOpen={expandedTimeFrame} style={{ margin: '2px 0 0 10px' }} />
        </StyledAccordionSummary>
        <AccordionDetails sx={{ margin: '0 0 15px 0!important' }}>
          <Box display="flex" flexDirection="column" sx={{ padding: '0 0 0 10px!important' }}>
            <TimeFrame
              timeFrameDefaults={timeFrameDefaults}
              timeFrameKey="globalTimeFrame"
              globalTimeFrame={globalTimeFrame}
              setTimeFrame={({ key, timeFrameCSV }) => {
                setFilterValue(key, timeFrameCSV)
              }}
              closeDropdown={closeExpand}
            />
          </Box>
        </AccordionDetails>
      </StyledAccordion>
      <Divider />
      <FiltersWidget inDropdown={false} />
    </PrimaryBar>
  )
}
const PrimaryBar = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  zIndex: 4,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
  height: '100vh',
  width: '100%',
  padding: '16px 30px 30px 30px',
  borderRight: `1px solid ${theme.palette.border.main}`,
  boxSizing: 'border-box',
}))

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingLeft: '10px',
  paddingRight: '10px',
  [`& .${accordionSummaryClasses.content}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}))

const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  background: 'none',
  transition: 'all 0.2s ease',
  padding: '10px 0',
  borderRadius: 4,

  '&:not(.Mui-expanded):hover': {
    background: theme.palette.grey.dark,
    borderRadius: 4,
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px',
  },
  '& .MuiAccordionDetails-root': {
    margin: '0',
    padding: '0px',
  },
  '&::before': {
    display: 'none',
  },
}))
