import decode from 'jwt-decode'
import config from 'config'
import get from 'lodash.get'

const identity = config.platform.identityUrl
const query = config.platform.queryUrl
const consoleUrl = config.platform.consoleUrl
const eventsUrl = config.platform.eventsUrl
const devMode = config.platform.devModeUrl
const integrationsUrl = config.platform.integrationsUrl
const billingUrl = config.platform.billingUrl
const inventoryUrl = config.platform.inventoryUrl
const searchUrl = config.platform.searchUrl

const cache = {}

const getFromCache = ({ token, path }) => {
  const { sub } = decode(token)
  if (cache[sub] && cache[sub][path]) {
    return cache[sub][path]
  }
  return null
}

const setCache = ({ token, path, data }) => {
  const { sub } = decode(token)
  cache[sub] = cache[sub] || {}
  cache[sub][path] = data
}

export const getSessionData = (key) => {
  try {
    const dataStr = localStorage.getItem(config.localStorageKey)
    if (!dataStr) throw new Error('No token')
    const data = JSON.parse(dataStr)
    return get(data, key || 'idToken')
  } catch (error) {
    return null
  }
}

/**
 *
 * Get info about the current logged in user
 *
 * @returns Info about the current logged in user
 */
export const getMe = async ({ refresh }) => {
  const token = getSessionData()
  if (!token) return null

  const path = 'me'
  const cacheData = getFromCache({ path, token })
  if (cacheData && !refresh) {
    return cacheData
  }
  const res = await fetch(`${identity}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) throw new Error('Failed to fetch me info')

  const me = await res.json()
  setCache({ path, token, data: me })
  return me
}

/**
 *
 * Gets a list of orgs that the current logged in user belongs to
 *
 * @returns Returns list of orgs
 */
export const getMyOrgs = async ({ userId, refresh }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `users/${encodeURIComponent(userId)}/orgs`
  const cacheData = getFromCache({ path, token })
  if (cacheData && !refresh) {
    return cacheData
  }

  const res = await fetch(`${identity}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }

  const { orgs } = await res.json()

  setCache({ path, token, data: orgs })

  return orgs
}

/**
 *
 * @param { orgId } param0 OrgId we want to connect with for dev mode logs
 * @returns accessToken that can be used to connect to web socket
 */
export const logTokenSwap = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `token/${orgId}`

  const res = await fetch(`${devMode}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }

  const { socketToken } = await res.json()

  return socketToken
}

/**
 *
 * Gets a list of orgs that the current logged in user belongs to
 *
 * @returns Returns list of orgs
 */
export const getOrg = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/${encodeURIComponent(orgId)}`
  const cacheData = getFromCache({ path, token })
  if (cacheData) {
    return cacheData
  }

  const res = await fetch(`${identity}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) throw new Error('Failed to fetch org info')

  const org = await res.json()

  setCache({ path, token, data: org })

  return org
}

/**
 *
 * Gets a specific org by org name
 *
 * @returns Returns and org by name
 */
export const getOrgByName = async ({ orgName }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/name/${encodeURIComponent(orgName)}`
  const cacheData = getFromCache({ path, token })
  if (cacheData) {
    return cacheData
  }

  const res = await fetch(`${identity}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) return null

  const org = await res.json()

  setCache({ path, token, data: org })

  return org
}

/**
 *
 * Update org name
 *
 * @param {orgId, orgName} param0
 */
export const updateOrgName = async ({ orgId, orgName }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/${orgId}`

  const res = await fetch(`${identity}/${path}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orgName,
    }),
  })
  if (!res.ok) return res.json()

  const org = await res.json()

  return org
}

/**
 *
 * Updated org member role
 *
 * @param {orgId, memberId, memberRole} param0
 * @returns
 */
export const updateMemberRole = async ({ orgId, memberId, memberRole }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/${orgId}/members/${memberId}/role`

  const res = await fetch(`${identity}/${path}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ memberRole }),
  })
  if (!res.ok) throw new Error('Failed to update org member')

  return res.json()
}

/**
 *
 * Remove org member
 *
 * @param {orgId, memberId} param0
 * @returns
 */
export const removeOrgMember = async ({ orgId, memberId }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/${orgId}/members/${memberId}`

  const res = await fetch(`${identity}/${path}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) throw new Error('Failed to remove org member')

  return { success: false }
}

/**
 *
 * Remove pending invite
 *
 * @param {token} param0
 * @returns
 */
export const removePendingInvite = async ({ token: inviteToken }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `invitations/${inviteToken}`

  const res = await fetch(`${identity}/${path}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) throw new Error('Failed to remove pending invite')

  return { success: false }
}

/**
 *
 * Get pending invites for an org
 *
 * @param {orgId, startKey} param0
 * @returns
 */
export const getPendingInvites = async ({ orgId, startKey }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `invitations`

  const queryParams = `?${new URLSearchParams({
    orgId,
    ...startKey,
  })}`

  const res = await fetch(`${identity}/${path}${queryParams}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) return null

  return res.json()
}

/**
 *
 * Get next set of team members
 *
 * @param {orgId, lastKey} param0
 * @returns
 */
export const getTeamMembers = async ({ orgId, startKey }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `orgs/${orgId}/members`

  let queryParams = ''
  if (startKey) {
    queryParams = `?${new URLSearchParams(startKey)}`
  }

  const res = await fetch(`${identity}/${path}${queryParams}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) return null

  return res.json()
}

/**
 *
 * Get org details with an invitation token
 *
 * @param {inviteToken} param0
 * @returns
 */
export const getOrgDetailsWithInviteToken = async ({ inviteToken }) => {
  const token = getSessionData()
  if (!token) throw new Error('Missing token')

  const res = await fetch(`${identity}/invitations/${inviteToken}/org`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  const data = await res.json()
  if (!res.ok || data === null) {
    throw new Error('Failed to get org info')
  }

  return data
}

/**
 *
 * Accept an invitation token
 *
 * @param {inviteToken} param0
 * @returns
 */
export const acceptOrgInvitation = async ({ inviteToken }) => {
  const token = getSessionData()
  if (!token) throw new Error('Missing token')

  const res = await fetch(`${identity}/invitations/${inviteToken}/accept`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    throw new Error('Failed to accept invitation')
  }

  return { statusCode: 200 }
}

/**
 *
 * Create an invitation for organization
 *
 * @param {orgId, email, role} param0
 * @returns
 */
export const sendOrgInvitation = async ({ orgId, email, role }) => {
  const token = getSessionData()
  if (!token) throw new Error('Missing token')

  const res = await fetch(`${identity}/invitations`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orgId,
      inviteeEmail: email,
      inviteeRole: role,
    }),
  })

  if (!res.ok) {
    const output = await res
      .json()
      .catch(() => ({ statusCode: 500, message: 'Failed to send invitation' }))
    return output
  }

  return { statusCode: 200, ...(await res.json()) }
}

/**
 *
 * Get organization by org name
 *
 * @param {orgName} param0
 */
export const createOrganization = async ({ orgName }) => {
  const token = getSessionData()
  if (!token) throw new Error('Missing token')

  const res = await fetch(`${identity}/orgs`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orgName,
    }),
  })

  if (!res.ok) return res.json()

  const { userId } = getSessionData('user')

  if (userId) {
    // Refresh org list
    await getMyOrgs({ userId })
  }

  return res.json()
}

/**
 * Checks if the org has data in it or not
 * @param {orgId} param0
 * @returns {data: }
 */
export const getBuckets = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const path = `${encodeURIComponent(orgId)}/metrics/bucket`

  const res = await fetch(`${query}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) throw new Error('Failed to fetch org info')

  const buckets = await res.json()

  return buckets
}

/**
 *
 * List metric views
 *
 * @param {orgId, data} param0
 * @returns
 */
export const listMetricViews = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${consoleUrl}/${orgId}/view`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Failed to list filter views')
  }
  return res.json()
}

/**
 *
 * @param {orgId, metricViewUid, data} param0
 * @returns
 */
export const updateMetricView = async ({ orgId, metricViewUid, data }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${consoleUrl}/${orgId}/view/${metricViewUid}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (!res.ok) {
    throw new Error('Failed to update filter view')
  }
  return res.json()
}

/**
 *
 * Get Metric view by Uid
 *
 * @param {orgId, metricViewUid} param0
 * @returns
 */
export const getMetricView = async ({ orgId, metricViewUid }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${consoleUrl}/${orgId}/view/${metricViewUid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) {
    throw new Error('Failed to get filter view')
  }
  return res.json()
}

export const removeMetricsView = async ({ orgId, metricViewUid }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${consoleUrl}/${orgId}/view/${metricViewUid}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res.ok) {
    throw new Error('Failed to delete filter view')
  }
  try {
    // return res.json()
  } catch (err) {
    return null
  }
}

/**
 *
 * Create new metrics view
 *
 * @param {orgId, data} param0
 * @returns
 */
export const createMetricsView = async ({ orgId, data }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${consoleUrl}/${orgId}/view`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
  if (!res.ok) {
    throw new Error('Failed to create filter view')
  }
  return res.json()
}

/**
 *
 * Get list of possible filter values
 *
 * @param {orgId, filter} param0
 * @returns
 */
export const getFilterValuesList = async ({ orgId, filter, startTime }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${query}/${orgId}/metrics/filters/${filter}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ startTime }),
  })
  if (!res.ok) {
    throw new Error('Failed to get filter values')
  }
  return res.json()
}

/**
 *
 * Send login session success so CLI will be logged in
 *
 * @param {loginSessionToken} param0
 * @returns
 */
export const sendLoginSessionSuccess = async ({ transactionId }) => {
  const token = getSessionData()
  if (!token) return null
  const res = await fetch(`${identity}/auth/login-sessions/${transactionId}/refresh-token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    return res.json()
  }
  return res.json()
}

/**
 *
 * Exchanges an auth0 code for an identity token and refresh token
 *
 * @param {code, redirectUri} param0
 * @returns identity token payload
 */
export const getIdentityToken = async ({
  code,
  redirectUri,
  loginSessionToken,
  queryParameters,
}) => {
  const res = await fetch(`${identity}/auth/tokens/auth0`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      redirectUri,
      loginSessionToken,
      queryParameters,
    }),
  })
  if (!res.ok) {
    throw new Error('Failed to exchange auth0 token for sls token')
  }
  return res.json()
}

/**
 *
 * Refresh current session token
 *
 * @param {refreshToken} param0
 * @returns
 */
export const refreshAuthToken = async ({ refreshToken }) => {
  const res = await fetch(`${identity}/auth/tokens/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken,
    }),
  })
  if (!res.ok) {
    throw new Error('Failed to exchange auth0 token for sls token')
  }
  return res.json()
}

/**
 *
 * Initiate a new AWS integration
 *
 * @param {orgId} param0
 * @returns AWS Integration payload
 */
export const initAWSIntegration = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${integrationsUrl}/aws/initial?${new URLSearchParams({
      orgId,
    })}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 * Get integration by integration id
 */
export const getIntegrationById = async ({ integrationId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${integrationsUrl}/${integrationId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * Add/Update environment and namespace to integration
 *
 * @param {integrationId, environment, namespace} param0
 * @returns
 */
export const updateIntegration = async ({ integrationId, environment, namespace, alias }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${integrationsUrl}/${integrationId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      alias,
      environment,
      namespace,
    }),
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * List integrations by orgId
 *
 * @param {orgId} param0
 * @returns list of integrations
 */
export const listIntegrationsByOrgId = async ({ orgId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${integrationsUrl}/?${new URLSearchParams({
      orgId,
    })}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * List integration resources by integrationId
 *
 * @param {orgId} param0
 * @returns list of integrations
 */
export const listInventory = async ({ integrationId, startKey }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${inventoryUrl}/?${new URLSearchParams({
      integrationId,
      ...(startKey ? { startKey: JSON.stringify(startKey) } : {}),
    })}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * List integration resources by integrationId
 *
 * @param {orgId} param0
 * @returns list of integrations
 */
export const removeIntegrationResource = async ({ resourceKey }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${integrationsUrl}/aws/instrumentations/console-layer?${new URLSearchParams({
      resourceKey,
    })}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * Enable logs for a specific resource
 *
 * @param {resourceKey} param0
 * @returns
 */
export const enableFeatureForResource = async ({ resourceKey, feature = 'logs' }) => {
  const token = getSessionData()

  if (!token) return null
  const res = await fetch(`${integrationsUrl}/aws/instrumentations/${feature}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      resourceKey,
    }),
  })

  if (!res.ok) {
    const parsedRes = await res
      .json()
      .then(({ message }) => message)
      .catch(() => false)
    throw new Error(parsedRes)
  }
  return true
}

/**
 *
 * Enable logs for a specific resource
 *
 * @param {resourceKey} param0
 * @returns
 */
export const disableFeatureForResource = async ({ resourceKey, feature = 'logs' }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${integrationsUrl}/aws/instrumentations/${feature}?${new URLSearchParams({
      resourceKey,
    })}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )

  if (!res.ok) {
    const parsedRes = await res
      .json()
      .then(({ message }) => message)
      .catch(() => false)
    throw new Error(parsedRes)
  }
  return true
}

/**
 *
 * List integration resources by integrationId
 *
 * @param {orgId} param0
 * @returns list of integrations
 */
export const addIntegrationResource = async ({ resourceKey, tags, environment, namespace }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${integrationsUrl}/aws/instrumentations/console-layer`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      resourceKey,
      tags,
      environment,
      namespace,
    }),
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * Update integration item
 *
 * @param {resourceKey, body} param0
 * @returns updated integration item
 */
export const updateInventoryItem = async ({ resourceKey, body }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${eventsUrl}/inventories/${resourceKey}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * Remove integration
 *
 * @param {integrationId} param0
 * @returns
 */
export const removeIntegrationById = async ({ integrationId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${integrationsUrl}/${integrationId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return true
}

/**
 *
 * Get the limits defined by our billing service
 *
 * @returns Limits as described by the billing service
 */
export const getBillingLimits = async () => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${billingUrl}/limits`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res.json()
}

/**
 *
 * Get req/res data for trace
 *
 * @param {orgId, traceId} param0
 * @returns
 */
export const traceReqRes = async ({ orgId, traceId }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${query}/${orgId}/reqres/${traceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }

  return res.json()
}

/**
 *
 * Tell the backend to sync a specific type of resources
 * to our system. (Right now we only support lambda)
 *
 * @param {integrationId, types} param0
 * @returns
 */
export const syncResources = async ({ integrationId, types = 'lambda' }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(
    `${integrationsUrl}/aws/inventories?${new URLSearchParams({
      integrationId,
    })}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integrationId,
      }),
    }
  )

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }
  return res
}

/**
 *
 * Fetch a set of logs for a given time frame
 *
 * @param {orgId, query, filters, startTime, stopTime} param0
 * @returns
 */
export const fetchLogs = async ({
  orgId,
  queryStr = '',
  filters = {},
  startTime,
  stopTime,
  size,
}) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${query}/${orgId}/search/logs`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: queryStr,
      filters,
      startTime,
      stopTime,
      size,
    }),
  })

  if (!res.ok) {
    const parsedRes = await res.json()
    throw new Error(parsedRes.message)
  }

  return res.json()
}

/**
 *
 * Fetch a set of logs with context (root spans and req/res)
 *
 * @param {orgId, query, filters, startTime, stopTime} param0
 * @returns
 */
export const fetchLogsWithContext = async ({
  orgId,
  size = 10,
  afterId,
  afterTimestamp,
  startTime,
  stopTime,
  filters,
}) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${query}/${orgId}/logs/recent`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size,
      afterId,
      afterTimestamp,
      startTime,
      stopTime,
      filters,
    }),
  })

  if (!res.ok && res.status !== 404) {
    const parsedRes = await res.text()
    throw new Error(parsedRes.message)
  } else if (res.status >= 400) {
    return { spans: [] }
  }

  return res.json()
}

export const searchInventory = async ({ orgId, body }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${searchUrl}/orgs/${orgId}/search`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  if (!res.ok && res.status !== 400) {
    const parsedRes = await res.text()
    throw new Error(parsedRes.message)
  } else if (res.status === 400) {
    return {
      total: 0,
      hits: [],
    }
  }

  return res.json()
}

export const instrumentAWSFunctions = async ({ orgId, resources }) => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${integrationsUrl}/aws/instrumentations`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orgId,
      resources,
    }),
  })
  return {
    success: res.ok,
  }
}

export const resourceCompatibility = async () => {
  const token = getSessionData()
  if (!token) return null

  const res = await fetch(`${inventoryUrl}/compatibility`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok && res.status !== 400) {
    const parsedRes = await res.text()
    throw new Error(parsedRes.message)
  } else if (res.status === 400) {
    return null
  }

  return res.json()
}
