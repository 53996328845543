import { format, subMilliseconds } from 'date-fns'
import { get } from 'lodash'

export const formatNumber = (number = 0) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const formatPercent = (number = 0) => (number % 1 !== 0 ? number.toFixed(2) : number)

export const getRectanglePath = (x, y, width, height, radius) => {
  const maxRadius = Math.min(Math.abs(width) / 2, Math.abs(height) / 2)
  const ySign = height >= 0 ? 1 : -1
  const xSign = width >= 0 ? 1 : -1
  const clockWise = (height >= 0 && width >= 0) || (height < 0 && width < 0) ? 1 : 0
  let path

  if (maxRadius > 0 && radius instanceof Array) {
    const newRadius = [0, 0, 0, 0]
    for (let i = 0, len = 4; i < len; i++) {
      newRadius[i] = radius[i] > maxRadius ? maxRadius : radius[i]
    }

    path = `M${x},${y + ySign * newRadius[0]}`

    if (newRadius[0] > 0) {
      path += `A ${newRadius[0]},${newRadius[0]},0,0,${clockWise},${x + xSign * newRadius[0]},${y}`
    }

    path += `L ${x + width - xSign * newRadius[1]},${y}`

    if (newRadius[1] > 0) {
      path += `A ${newRadius[1]},${newRadius[1]},0,0,${clockWise},
        ${x + width},${y + ySign * newRadius[1]}`
    }
    path += `L ${x + width},${y + height - ySign * newRadius[2]}`

    if (newRadius[2] > 0) {
      path += `A ${newRadius[2]},${newRadius[2]},0,0,${clockWise},
        ${x + width - xSign * newRadius[2]},${y + height}`
    }
    path += `L ${x + xSign * newRadius[3]},${y + height}`

    if (newRadius[3] > 0) {
      path += `A ${newRadius[3]},${newRadius[3]},0,0,${clockWise},
        ${x},${y + height - ySign * newRadius[3]}`
    }
    path += 'Z'
  } else if (maxRadius > 0 && radius === +radius && radius > 0) {
    const newRadius = Math.min(maxRadius, radius)

    path = `M ${x},${y + ySign * newRadius}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + xSign * newRadius},${y}
            L ${x + width - xSign * newRadius},${y}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + width},${y + ySign * newRadius}
            L ${x + width},${y + height - ySign * newRadius}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + width - xSign * newRadius},${
      y + height
    }
            L ${x + xSign * newRadius},${y + height}
            A ${newRadius},${newRadius},0,0,${clockWise},${x},${y + height - ySign * newRadius} Z`
  } else {
    path = `M ${x},${y} h ${width} v ${height} h ${-width} Z`
  }

  return path
}

export const combineGraphData = (
  first = [],
  firstKey,
  second = [],
  secondKey,
  third = [],
  thirdKey,
  chartDateFormat
) => {
  const grouped = [
    ...first.map((item) => ({ ...item, from: 'list1' })),
    ...second.map((item) => ({ ...item, from: 'list2' })),
    ...third.map((item) => ({ ...item, from: 'list3' })),
  ].reduce((obj, item) => {
    const formatTime = format(subMilliseconds(new Date(item.time), 1), chartDateFormat)
    if (!obj[formatTime]) obj[formatTime] = []

    return {
      ...obj,
      [formatTime]: [...obj[formatTime], item],
    }
  }, {})

  return Object.keys(grouped)
    .map((key) => {
      const list = grouped[key]

      // combine all values from each list
      let val1 = Math.floor(
        list.filter((item) => item.from === 'list1').reduce((sum, { value }) => value + sum, 0) || 0
      )
      let val2 = Math.floor(
        list.filter((item) => item.from === 'list2').reduce((sum, { value }) => value + sum, 0) || 0
      )
      let val3 = Math.floor(
        list.filter((item) => item.from === 'list3').reduce((sum, { value }) => value + sum, 0) || 0
      )

      const val1Time =
        list.find((item) => item.from === 'list1' && item.value && item.value !== null)?.time ||
        list.find((item) => item.from === 'list1')?.time ||
        0
      const val2Time = list.find((item) => item.from === 'list2')?.time || 0
      const val3Time = list.find((item) => item.from === 'list3')?.time || 0

      // If the values vary widely the stacked bar graph has issues showing the smaller value.
      // This helps ensure the graphed values are always able to be displayed
      const realVal1 = val1
      const realVal2 = val2
      const realVal3 = val3
      if (realVal1 > realVal2 && realVal2 !== 0) {
        const percent = (realVal2 / realVal1) * 100
        val2 = percent < 4 ? Math.floor(realVal1 * 0.13) : realVal2
      } else if (realVal2 > realVal1 && realVal1 !== 0) {
        const percent = (realVal1 / realVal2) * 100
        val1 = percent < 4 ? Math.floor(realVal2 * 0.13) : realVal1
      }

      return {
        name: key,
        [firstKey]: val1 > val2 ? val1 - val2 : val1, // Show successful invocations not total
        [secondKey]: val2,
        [thirdKey]: val3,
        [`${firstKey}Real`]: realVal1,
        [`${secondKey}Real`]: realVal2,
        [`${thirdKey}Real`]: realVal3,
        rawDate: val1Time || val2Time || val3Time,
      }
    })
    .sort((a, b) => new Date(a.rawDate).getTime() - new Date(b.rawDate).getTime())
}

export const medianValue = (data = [], key) => {
  if (data.length === 0) return 0
  const filtered = data.filter((val) => val[key] !== 0)
  const total = filtered.reduce((sum, val) => val[key] + sum, 0)
  return (total / filtered.length).toFixed(2)
}
export const maxValue = (data = [], key) =>
  data.reduce(
    (high, val) => (!high || val[key] > high ? val[key] : high),
    get(data, `[0][${key}]`, 0)
  )
