import { useContext, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { BooleanParam, useQueryParam } from 'use-query-params'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { addMonths, intervalToDuration, isBefore } from 'date-fns'
import config from 'config'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import { PromoBannerMessage } from 'common/components/PromoBannerMessage'
import { useDiscounts } from 'common/hooks/useDiscounts'
import { baseDiscounts } from 'common/plans'

const { WebElementClickedV1Event } = WebEvents

export const useUsageTopBanner = () => {
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const {
    isFreePlan,
    loadingUsage,
    loadingSubscription,
    tracesUsagePercent,
    isTracesApproachingLimit,
  } = useOrgUsage({ orgId })
  const discounts = useDiscounts()
  const location = useLocation()
  const navigate = useNavigate()
  /**
   * A map of all promo interactions. This storage item data should look like this
   *
   * {
   *   PROMO_NAME: { interacted: boolean }
   * }
   *
   * For example, if we have a promo we want to display to all users called HI-MOM then
   * we would check this object to see if we have a HI-MOM key. If we do not have a HI-MOM
   * key then we would want to display the banner. If we do have a HI-MOM key and the value is
   * true then we would not want to display the promo banner.
   */
  const [promoInteractions, setPromoInteractions] = useLocalStorage('promo-banners', {})
  const [, setOpenPaymentDialog] = useQueryParam('upgradePlan', BooleanParam)

  const sendClickEvent = async (publishEventProps) => {
    try {
      const localData = window.localStorage.getItem(config.localStorageKey)
      const storageData = JSON.parse(localData)
      if (
        storageData?.idToken?.trim() === '' ||
        storageData?.user?.userId?.trim() === '' ||
        storageData?.activeOrg?.orgId?.trim() === ''
      ) {
        return
      }
      const token = storageData?.idToken
      const userId = storageData?.user?.userId
      const orgUid = storageData?.activeOrg?.orgId
      const slsCoreEventsClient = new SlsCoreEventsClient(config.platform.eventsUrl, token)
      const clickEvent = new WebElementClickedV1Event({
        orgUid,
        userId,
        elementId: publishEventProps.elementId,
        action: publishEventProps.action,
        actionDetails: publishEventProps.actionDetails,
      })
      await slsCoreEventsClient.publish(clickEvent)
    } catch (error) {
      console.error(error)
    }
  }

  const warningBannerClick = () => setOpenPaymentDialog(true)
  const promoBannerClick = async (dismissed, promoName) => {
    let clickEventInput = {
      elementId: 'global-promo-banner',
      action: 'hide_element',
      promoCode: promoName,
    }
    if (dismissed) {
      setPromoInteractions((current) => ({
        ...current,
        [promoName]: { interacted: true },
      }))
    } else {
      const match = matchPath({ path: '/:orgName/*' }, location.pathname)
      const params = match?.params
      if (params?.orgName) {
        navigate(
          `/${params.orgName}/settings/billing/plans?discountCode=${encodeURIComponent(
            promoName
          )}&upgradePlan=1`
        )
        clickEventInput = {
          elementId: 'global-promo-banner',
          action: 'link_internal',
          actionDetails: {
            href: `/${params.orgName}/settings/billing/plans?discountCode=${encodeURIComponent(
              promoName
            )}&upgradePlan=1`,
            promoCode: promoName,
          },
        }
      }
    }
    sendClickEvent(clickEventInput)
  }
  const loading = loadingUsage || loadingSubscription
  const showWELCOME22DiscountBanner =
    isBefore(new Date(), baseDiscounts[0].expiration) &&
    isFreePlan &&
    !promoInteractions?.[discounts?.[0]?.code]?.interacted &&
    !loading
  const isWarning = isTracesApproachingLimit && !loading

  const isVisible = isWarning || showWELCOME22DiscountBanner

  const onClick = isWarning
    ? warningBannerClick
    : () => promoBannerClick(false, discounts?.[0]?.code)
  const message = useMemo(() => {
    if (isWarning) {
      return `Warning — You have used ${tracesUsagePercent}% of your traces for the month. Click
    here to upgrade now for $8/mo to avoid losing metrics, traces & logs.`
    } else if (showWELCOME22DiscountBanner) {
      return (
        <PromoBannerMessage
          promoDuration={
            discounts?.[0]?.promoDuration ||
            intervalToDuration({
              start: new Date(),
              end: addMonths(new Date()),
            })
          }
          onDismiss={(e) => {
            e?.stopPropagation()
            promoBannerClick(true, discounts?.[0]?.code)
          }}
          percentDiscount={
            discounts?.[0]?.tracePercentNumber > 0
              ? discounts?.[0]?.percent
              : discounts?.[0]?.resourcePercent
          }
        />
      )
    }
  }, [isWarning, showWELCOME22DiscountBanner])

  return {
    message,
    isWarning,
    onClick,
    isVisible,
  }
}
