import { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useSnackbar } from 'notistack'
import { getIntegrationById, resourceCompatibility } from 'util/core.api'
import { isEqual, isUndefined, keys, omit, values } from 'lodash'
import { useIntegrationFiltersQueries } from '../hooks/useIntegrationFiltersQueries'
import { useSearch } from 'common/hooks/useSearch'
import { coreApiClient } from 'util/coreApiClient'
import config from 'config'
import { AppContext } from 'app/context/AppContext'
import { useIsInstrumenting } from 'common/hooks/useIsInstrumenting'
import useSWR from 'swr'

export const IntegrationContext = createContext()

const resourceRowStyles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(300px,23%) minmax(150px,180px) 130px minmax(200px,260px) 250px 40px',
    padding: '10px 15px',

    justifyContent: 'space-between',
  },
  children: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // Name
    ':nth-of-type(1) > span': {},
    // Region, Runtime
    ':nth-of-type(2) > span': {
      minWidth: '80px',
    },
    // Modified
    ':nth-of-type(3) > span': {},
    // Environment, Namespace wrapper
    ':nth-of-type(4)': {
      justifyContent: 'flex-start',
      gap: '10px',
    },
    // Environment, Namespace
    ':nth-of-type(4) > span': {
      minWidth: '120px',
    },
  },
}
const DEFAULT_PAGE_SIZE = 50
export const MAXIMUM_ALLOWED_TO_SELECT = 50

export const IntegrationProvider = ({ handleNext, children }) => {
  const { activeOrg } = useContext(AppContext)
  const { setInstrumenting } = useIsInstrumenting()
  const { orgId } = activeOrg || {}
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [integrationId] = useQueryParam('integrationId', StringParam)
  const { data: compatibility } = useSWR(['integrationCompatibly'], () => resourceCompatibility())
  /**
   * Get query parameters
   */
  const {
    globalFilters,
    search,
    globalSearch,
    setSearch,
    currentPage,
    setCurrentPage,
    sort,
    setSort,
    resetIntegrationQueryParams,
  } = useIntegrationFiltersQueries()

  const [integration, setIntegration] = useState()
  const [changedResources, setChangedResources] = useState({})

  const isMaximumSelected = useMemo(
    () => keys(changedResources)?.length > MAXIMUM_ALLOWED_TO_SELECT,
    [changedResources]
  )

  // Loading state
  const [loadingIntegration, setLoadingIntegration] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [saveError, setSaveError] = useState('')

  /**
   * Get filter query schema based on selected globalFilters
   */
  const filterQuery = useMemo(
    () =>
      keys(globalFilters)?.reduce(
        (acc, key) =>
          !isUndefined(globalFilters[key])
            ? [
                ...acc,
                {
                  match: { [key.includes('tag_') ? `${key}.keyword` : key]: globalFilters[key] },
                },
              ]
            : acc,
        []
      ),
    [globalFilters]
  )

  /**
   * Get search query schema based on search input value
   */
  const searchQuery = useMemo(
    () =>
      globalSearch
        ? [
            {
              multi_match: {
                query: globalSearch,
                fields: ['*'],
                type: 'phrase_prefix',
              },
            },
          ]
        : [],
    [globalSearch]
  )

  const query = {
    bool: {
      must: [
        {
          match: {
            type: 'resource_aws_lambda',
          },
        },
        {
          match: {
            tag_account_id: integration?.vendorAccount,
          },
        },
        ...filterQuery,
        ...searchQuery,
      ],
    },
  }

  const size = DEFAULT_PAGE_SIZE

  /**
   * List inventories with query and sort
   */
  const { data, loading, refreshItems, isValidating } = useSearch({
    ready: !!integration?.vendorAccount, // Only fetch when we have integrationId
    size,
    currentPage,
    query,
    sort,
  })
  const [resources, setResources] = useState([])
  useEffect(() => {
    setResources(data?.hits || [])
  }, [data?.hits, isValidating])

  const total = data?.total

  useEffect(() => {
    const getIntegration = async () => {
      try {
        const integrationRes = await getIntegrationById({ integrationId })
        setIntegration(integrationRes)
      } catch (error) {
        enqueueSnackbar('Failed to fetch integration. Redirecting back to integrations list...', {
          variant: 'error',
          autoHideDuration: 5000,
        })
        setTimeout(handleNext, 4000)
      } finally {
        setLoadingIntegration(false)
      }
    }

    if (integrationId) {
      getIntegration().catch((error) => {
        console.error(error)
      })
    }
  }, [integrationId])
  useEffect(() => {
    if (keys(changedResources)?.length > MAXIMUM_ALLOWED_TO_SELECT) {
      promptMaxSelectedReached()
    }
  }, [changedResources])

  const promptMaxSelectedReached = () => {
    closeSnackbar()
    enqueueSnackbar(
      `You can only make changes to ${MAXIMUM_ALLOWED_TO_SELECT} functions at a time.`
    )
  }
  /**
   * Clear error message
   */
  useEffect(() => {
    setSaveError('')
  }, [openConfirmDialog])
  const saveChanges = async () => {
    setLoadingSave(true)
    setSaveError('')
    try {
      await coreApiClient({
        baseURL: config.platform.integrationsUrl,
        method: 'post',
        url: '/aws/instrumentations',
        data: {
          orgId,
          resources: values(changedResources).map((resource) => ({
            instrumentations: {
              mode: resource?.instrument_mode,
            },
            resourceKey: resource?.id,
            updateFields: {
              namespace: resource?.tag_namespace,
              environment: resource?.tag_environment,
            },
          })),
        },
      })

      setResources((prev) => {
        return prev?.map((resource) => changedResources[resource?.id] || resource)
      })
      const modes = values(changedResources).reduce(
        (arr, resource) => [
          ...arr,
          ...(arr.includes(resource?.instrument_mode) ? [] : [resource?.instrument_mode]),
        ],
        []
      )
      setInstrumenting(modes)
      setOpenConfirmDialog(false)
      setChangedResources({})
      enqueueSnackbar('Request Submitted Successfully')
    } catch (err) {
      setSaveError('Something went wrong, please try again')
    }
    setLoadingSave(false)
  }
  const onResourcePropertyChange = async ({ key, value, resource }) => {
    const isChangeDisabled = isMaximumSelected && !changedResources[resource.id]
    const valueChanged = resource[key] !== value
    if (!isChangeDisabled) {
      setChangedResources((prev) => {
        if (isEqual(resource, { ...prev[resource.id], [key]: value }) || !valueChanged) {
          return omit(prev, resource.id)
        } else {
          return {
            ...prev,
            [resource.id]: {
              ...resource,
              ...prev?.[resource.id],
              [key]: value,
            },
          }
        }
      })
    }
  }

  const refresh = () => {
    refreshItems()
    setChangedResources({})
  }
  return (
    <IntegrationContext.Provider
      value={{
        resourceCompatibility: compatibility,
        onResourcePropertyChange,
        promptMaxSelectedReached,
        saveError,
        resetIntegrationQueryParams,
        isMaximumSelected,
        // State data
        integrationId,
        integration,
        setIntegration,
        resources,
        search,
        setSearch,

        globalFilters,
        total,
        currentPage,
        setCurrentPage,
        size,
        sort,
        setSort,
        refresh,
        // Loading state
        loading,
        loadingIntegration,
        loadingSave,
        isValidating,

        // Row styles to use in header and resource row
        resourceRowStyles,

        // Changed resources
        changedResources,
        setChangedResources,

        saveChanges,

        openConfirmDialog,
        setOpenConfirmDialog,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  )
}
