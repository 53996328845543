import { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { AppContext } from 'app/context/AppContext'
import { FilterContext } from 'filter/context/FilterContext'
import {
  createMetricsView as createView,
  listMetricViews as listViews,
  getMetricView as getView,
  updateMetricView as updateView,
  removeMetricsView,
} from 'util/core.api'

import { keys } from 'lodash'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { getTimeFrame } from 'filter/util/time'

/**
 * Defaults
 */

// These are Metric View data records for the 2 static views
export const StaticViewRecords = {
  awsLambda: {
    id: 'awsLambda',
    name: 'AWS Lambda',
    version: 1,
    filters: [{ globalScope: 'awsLambda' }],
    isStatic: true,
  },
  api: {
    id: 'api',
    name: 'API',
    description:
      'This Metrics view shows the API endpoints you have across your infrastructure. It works with AWS API Gateway, as well as with API endpoints defined in your code frameworks (e.g. Express.js).',
    version: 1,
    filters: [{ globalScope: 'api' }],
    isStatic: true,
  },
}

/**
 * Context & Provider
 */

export const MetricsContext = createContext()

export const MetricsProvider = ({ children }) => {
  const { activeOrg } = useContext(AppContext)
  const {
    getFilterValue,
    setCurrentTimeFrame,
    setAllFilterValues,
    serializeFiltersForStorage,
    deserializeFiltersFromStorage,
  } = useContext(FilterContext)
  const navigate = useNavigate()
  // The current Metric View that is being viewed within the Console
  const { metricsViewUid } = useParams()
  const [metricsView, setMetricsView] = useState(null)

  // On Metrics View UID
  useEffect(async () => {
    // If no Metrics View UID was found (the user likely is just starting up the application),
    // or the UID is invalid, try and fetch the previous view from session storage, or redirect to the default API Metrics View
    if (!metricsViewUid || metricsViewUid === 'undefined' || metricsViewUid === 'null') {
      let defaultMetricsViewUid = StaticViewRecords.awsLambda.id
      navigate(`/${activeOrg.orgName}/metrics/${defaultMetricsViewUid}`)
      return
    }

    // Fetch view.  This fetches from the API or static Metrics Views, depending on the UID
    let view
    try {
      view = await getMetricsView(metricsViewUid)
    } catch (error) {
      console.error(error)
    }

    // If we are already on this global scope we want to remove that filter from
    // the list. This way default scopes like awsLambda and API won't cause back button weirdness
    const globalScope = view.filters.find(({ filter }) => filter === 'globalScope')
    if (getFilterValue('globalScope') === globalScope.value) {
      view.filters = view.filters.filter(({ filter }) => filter !== 'globalScope')
    }

    // Set the current Filter Values globally first from the current Metrics View
    if (view.filters.length > 0) {
      setAllFilterValues(view.filters)
      // Then set the current Metrics View globally, which ends the loading screen
    }
    setMetricsView(view)
  }, [metricsViewUid])

  /**
   * Functions & state for refreshing and auto-refreshing data, used w/ "useMetricsAPIData".
   * The view must implement the interval timer itself.  This state is stored
   * here so other Components can access/react to it.
   */
  const [metricsAutoRefreshEnabled, setMetricsAutoRefreshEnabled] = useState(false)
  // Ensure "trigger" is set to null initially, or it will trigger multiple state/adta fetches on initial load
  const [metricsRefreshTrigger, setMetricsRefreshTrigger] = useState({
    lastRefreshType: 'manual',
    trigger: null,
  })

  const refreshMetricsData = ({ refreshType = 'manual', globalTimeFrame } = {}) => {
    setMetricsRefreshTrigger({
      lastRefreshType: refreshType,
      trigger: new Date().getTime(),
    })

    // We only want to update the time frame if we have a relative time
    if (refreshType === 'auto' && !globalTimeFrame.includes(',')) {
      setCurrentTimeFrame(getTimeFrame(globalTimeFrame))
    }
  }
  const toggleMetricsAutoRefresh = () => {
    setMetricsAutoRefreshEnabled(!metricsAutoRefreshEnabled)
  }

  /**
   * Functions
   */

  /**
   * A helper function to redirect to a new Metrics View based on UID
   * @param {*} metricsViewUid
   */
  const navigateToMetricsView = async (metricsViewUid) => {
    setMetricsView(null)
    navigate(`/${activeOrg.orgName}/metrics/${metricsViewUid}`)
  }

  /**
   * Creates a new Metrics View for the Organization
   * @param {*} data
   * @returns
   */
  const createMetricsView = async (data) => {
    // Validate
    if (!Array.isArray(data.filters) || !data.filters.length) {
      throw new Error('Filters are required')
    }
    const gsFilter = data.filters.find((f) => f.filter === 'globalScope')
    if (!gsFilter) {
      throw new Error('A "globalScope" filter is required')
    }

    // Serialize filters
    data.filters = serializeFiltersForStorage(data.filters)

    return createView({
      data,
      orgId: activeOrg.orgId,
    })
  }

  /**
   * List Metrics Views for the Organization.  Adds in static views and icons.
   * @param {*} metricViewUid
   * @returns
   */
  const listMetricsViews = async () => {
    try {
      const res = await listViews({ orgId: activeOrg.orgId })
      let records = res?.data || []
      // Always add Static Metrics Views
      records = [
        // cloneDeep(StaticViewRecords.awsLambda), // The "awsLambda" view is not ready yet
        // cloneDeep(StaticViewRecords.api),
        cloneDeep(StaticViewRecords.awsLambda),
        ...records,
      ]
      // Deserialize all filters and add icons
      records.forEach((view) => {
        view.filters = deserializeFiltersFromStorage(view.filters || [])
        const gsFilter = view.filters.find((f) => f.filter === 'globalScope')
        if (gsFilter) {
          view.icon = addIcon(gsFilter.value)
        }
      })
      return records
    } catch (error) {
      console.error('Error', error)
      return []
    }
  }

  /**
   * Get a Metrics View via its ID.
   * Pulls from the static views or the databse.
   * Deserializes filters and adds icon.
   * @param {*} metricViewUid
   * @returns
   */
  const getMetricsView = async (metricViewUid) => {
    let data

    switch (metricsViewUid) {
      // Handle static "awsLambda" Metric View
      case StaticViewRecords.awsLambda.id:
        data = cloneDeep(StaticViewRecords.awsLambda)
        break
      // Handle static "API" Metric View
      case StaticViewRecords.api.id:
        data = cloneDeep(StaticViewRecords.api)
        break
      // Load the Metrics View from the API
      default:
        const res = await getView({
          orgId: activeOrg.orgId,
          metricViewUid,
        })
        data = res?.data || null
        break
    }

    // Deserialize all filters
    if (data?.filters) {
      data.filters = deserializeFiltersFromStorage(data.filters)
    }
    // Add icon
    const gsFilter = data.filters.find((f) => f.filter === 'globalScope')
    data.icon = addIcon(gsFilter.value)

    return data
  }

  /**
   * Upates an existing Metrics View within the Organization
   * @param {*} metricViewUid
   * @param {*} metricViewData
   * @returns
   */
  const updateMetricsView = async (metricViewUid, metricViewData) => {
    try {
      /**
       * Don't update if view is one of the two global default views
       */
      if (keys(StaticViewRecords).some((id) => metricsView.id === id)) {
        return
      }
      // Only submit updateable fields or validation will fail
      const newMetricViewData = {}
      newMetricViewData.name = metricViewData.name
      newMetricViewData.description = metricViewData.description
      newMetricViewData.filters = metricViewData.filters
      newMetricViewData.version = metricViewData.version

      // Serialize filters
      newMetricViewData.filters = serializeFiltersForStorage(newMetricViewData.filters)

      const res = await updateView({
        orgId: activeOrg.orgId,
        metricViewUid,
        data: newMetricViewData,
      })
      const data = res?.data || null
      // Deserialize all filters
      if (data?.filters) {
        data.filters = deserializeFiltersFromStorage(data.filters)
      }
      // Add icon
      const gsFilter = data.filters.find((f) => f.filter === 'globalScope')
      data.icon = addIcon(gsFilter.value)
      return data
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * A convenience function that abstracts the update function to update the current Metrics Views' Filters
   * @param {*} metricViewFilters
   * @returns
   */
  const updateMetricsViewFilters = async (newFilters) => {
    // Ensure a current Metric View exists
    if (!metricsView) {
      throw new Error('No Metrics View is selected')
    }

    const updatedMetricViews = cloneDeep(metricsView)
    updatedMetricViews.filters = newFilters

    return await updateMetricsView(updatedMetricViews.id, updatedMetricViews)
  }

  /**
   * Deletes a Metrics View within the Organization
   * @param {*} metricViewUid
   * @returns
   */
  const deleteMetricsView = async (metricToDeleteViewUid) => {
    await removeMetricsView({
      orgId: activeOrg.orgId,
      metricViewUid: metricToDeleteViewUid,
    })
    /**
     * Redirect only if current view is not the default AND it is the one that is being deleted
     */
    if (
      metricsViewUid !== StaticViewRecords.awsLambda.id &&
      metricToDeleteViewUid === metricsViewUid
    ) {
      navigateToMetricsView(StaticViewRecords.awsLambda.id)
    }
  }

  /**
   * Adds the Icon Component based on what "globalScope" Filter is used
   * @param {*} globalScopeFilter
   * @returns
   */
  const addIcon = (globalScopeFilter) => {
    if (globalScopeFilter === 'awsLambda') {
      return <IconAwsLambda />
    }
  }

  return (
    <MetricsContext.Provider
      value={{
        metricsView,
        // Refresh & Auto-Refresh
        metricsRefreshTrigger,
        metricsAutoRefreshEnabled,
        toggleMetricsAutoRefresh,
        setMetricsAutoRefreshEnabled,
        refreshMetricsData,
        // View Management
        navigateToMetricsView,
        createMetricsView,
        getMetricsView,
        listMetricsViews,
        updateMetricsView,
        updateMetricsViewFilters,
        deleteMetricsView,
      }}
    >
      {children}
    </MetricsContext.Provider>
  )
}
