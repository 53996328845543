/**
 * Config
 */
const env = window.location.hostname.includes('serverless.com') ? 'prod' : 'dev'

/**
 * Update this announcement object to show a banner to users when a new version of console
 * is announced.
 *
 * prod - This section will show the announcement on the explorer and metrics pages
 * dev - This section will show the announcement on the dev mode page
 *
 * Adding new announcements:
 * You can remove the old announcement by deleting the key/value pair from the object or
 * you can add a new announcement with a greater date than the previous key in the object.
 *
 * Supported properties:
 * title - The title of the announcement
 * description - The description of the announcement (This section will be collapsible)
 */
const announcements = {
  prod: {
    '2023-03-29': {
      title: 'Introducing Console v3',
      description: `<p>On 03/29, we released Console V.3 which offers...<p>
      <ul>
        <li>More querying options across all AWS Lambda Metrics & Traces.</li>
        <li>Custom Tags, Error Events & Warning Events.</li>
        <li>Automatic Trace Sampling (20%) to reduce your bill when you set your Instrumentation Mode to "production".</li>
      </ul>
      <p>Console Instrumentation must be enabled on your AWS Lambda functions to use all features. This is now true for Metrics as well. Currently only Node.js 14+ and Python 3.8+ are supported, with other runtime support coming soon.</p>`,
    },
  },
}

/**
 * Dev
 */
const devConfig = {
  localStorageKey: 'serverless-console-dev',
  auth0: {
    domain: 'auth.serverless-dev.com',
    baseDomain: 'https://serverlessdev.auth0.com',
    clientId: 'XF15eFgsSFWK8sO8GAqOpK0tp23im3Yh',
    audience: 'https://serverlessdev.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  legacy: {
    appUrl: 'https://app.serverless-dev.com',
    orgWhitelist: [
      '9MJvd98pmm7B77YdMQ', // serverlesssteam
      'tRx86pbvR92ndc0n34',
      'MHKYGnY9YsnDHY9Qmc', // serverlessinc
      'BP3LkMvm02lQQHpQfz', // mnapoli
    ],
  },
  platform: {
    stage: 'dev',
    identityUrl: 'https://core.serverless-dev.com/api/identity',
    queryUrl: 'https://core.serverless-dev.com/api/v2/query',
    consoleUrl: 'https://core.serverless-dev.com/api/console',
    integrationsUrl: 'https://integrations.core.serverless-dev.com/api/integrations',
    inventoryUrl: 'https://core.serverless-dev.com/api/inventories',
    logSocketUrl: 'wss://dev.socket.core.serverless-dev.com',
    devModeUrl: 'https://core.serverless-dev.com/dev-mode/log-socket',
    eventsUrl: 'https://core.serverless-dev.com/api',
    searchUrl: 'https://core.serverless-dev.com/api/search',
    billingUrl: 'https://core.serverless-dev.com/api/billing',
    announcements,
    roles: {
      owner: {
        name: 'Owner',
        description: 'Owners have full permissions to manage org settings and add team members.',
      },
      contributor: {
        name: 'Developers',
        description:
          'Developers have full permissions manage org settings but can not add or remove team members.',
      },
    },
  },
  sentry: {
    dsn: null,
  },
  stripe: {
    apiKey: 'pk_test_q68RE9RoItF3A3c9Qx903A0Y004VeuwvWj',
  },
}

/**
 * Prod
 */
const prodConfig = {
  localStorageKey: 'serverless-console',
  auth0: {
    domain: 'auth.serverless.com',
    baseDomain: 'https://serverlessinc.auth0.com',
    clientId: 'bUSe7gMktT7jVOxFCtuAgO0EwcS6zlIJ',
    audience: 'https://serverlessinc.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  legacy: {
    appUrl: 'https://app.serverless.com',
    orgWhitelist: [
      '9MJvd98pmm7B77YdMQ', // serverlesssteam
      'tRx86pbvR92ndc0n34',
      'MHKYGnY9YsnDHY9Qmc', // serverlessinc
      'BP3LkMvm02lQQHpQfz', // mnapoli
    ],
  },
  platform: {
    stage: 'prod',
    identityUrl: 'https://core.serverless.com/api/identity',
    queryUrl: 'https://core.serverless.com/api/v2/query',
    consoleUrl: 'https://core.serverless.com/api/console',
    integrationsUrl: 'https://integrations.core.serverless.com/api/integrations',
    inventoryUrl: 'https://core.serverless.com/api/inventories',
    logSocketUrl: 'wss://socket.core.serverless.com',
    devModeUrl: 'https://core.serverless.com/dev-mode/log-socket',
    searchUrl: 'https://core.serverless.com/api/search',
    eventsUrl: 'https://core.serverless.com/api',
    billingUrl: 'https://core.serverless.com/api/billing',
    announcements,
    roles: {
      owner: {
        name: 'Owner',
        description: 'Owners have full permissions to manage org settings and add team members.',
      },
      contributor: {
        name: 'Developers',
        description:
          'Developers have full permissions manage org settings but can not add or remove team members.',
      },
    },
  },
  sentry: {
    dsn: 'https://627bdb17a50849e583b539c159f1f7d8@o179172.ingest.sentry.io/5288803',
  },
  stripe: {
    apiKey: 'pk_live_fUrnuMKodQM6UMXPZnFcNQ3N002icn5R5D',
  },
}

module.exports = env === 'prod' || process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig
