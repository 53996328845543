import { useContext, useEffect } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from 'app/context/AppContext'
import { Team } from 'settings/components/team/Team'
import { General } from 'settings/components/General'
import { Billing } from 'settings/components/billing/Billing'
import { Integrations } from 'settings/components/integrations/Integrations'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { capitalize } from 'lodash'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

const tabData = [
  {
    value: 'general',
    label: 'General',
    hasAccess: (isOwner, superAdmin) => isOwner || superAdmin,
  },
  {
    value: 'team',
    label: 'Team',
    hasAccess: () => true,
  },
  {
    value: 'integrations',
    label: 'Integrations',
    hasAccess: () => true,
  },
  {
    value: 'billing',
    label: 'Usage & Billing',
    hasAccess: () => true,
  },
]

const Settings = ({ children }) => {
  const { pageName } = useParams()
  const navigate = useNavigate()
  const {
    activeOrg: { orgName, isOwner },
    user,
  } = useContext(AppContext)
  usePageTitle(`${capitalize(pageName)} - Settings`)

  const superAdmin = user?.superAdmin

  if (!tabData.find(({ value }) => value === pageName)) {
    navigate(`/${orgName}/settings/team`)
  }

  const handleChange = (_, newValue) => {
    navigate(`/${orgName}/settings/${newValue}`)
  }

  useEffect(() => {
    const filteredList = tabData.filter(({ hasAccess }) => hasAccess(isOwner, superAdmin))
    const inList = filteredList.find(({ value }) => value === pageName)
    if (!inList) {
      navigate(`/${orgName}/settings/${filteredList[0]?.value}`)
    }
  }, [pageName, isOwner, superAdmin])

  return (
    <ErrorBoundary>
      <NavBar
        initial={{ y: -15, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.25, duration: 0.4, ease: 'easeOut' } }}
      >
        <Box padding="5px 0 0 30px">
          <OrgName component="h1">{orgName}</OrgName>

          <StyledTabs value={pageName} onChange={handleChange}>
            {tabData
              .filter(({ hasAccess }) => hasAccess(isOwner, superAdmin))
              .map(({ value, label }) => (
                <StyledTab value={value} label={label} key={value} />
              ))}
          </StyledTabs>
        </Box>
        <BorderBottom />
      </NavBar>

      {pageName === 'team' && <Team />}
      {pageName === 'billing' && <Billing />}
      {pageName === 'integrations' && <Integrations />}
      {pageName === 'general' && <General />}
    </ErrorBoundary>
  )
}

const BorderBottom = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: theme.palette.border.main,
  height: '1px',
  zIndex: 4,
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  zIndex: 6,
  left: 24,
  display: 'flex',
  alignItems: 'flex-end',
  '& .MuiTabs-indicator': {
    height: '2px',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '40px',
}))

const OrgName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.h1.fontSize,
  lineHeight: '33px',
  marginTop: '15px',
  color: theme.palette.text.primary,
}))

const NavBar = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  zIndex: 4,
  overflow: 'hidden',
  height: '100px',
  width: '100%',
  borderRadius: '20px 0 0 0',
  boxSizing: 'border-box',
  '& .notSelected': {
    color: '#BBBBBB',
    '& svg': {
      opacity: '0.3!important',
    },
  },
}))

export default Settings
