import React, { useContext } from 'react'
import { styled, Tooltip, Box } from '@mui/material'
import { motion } from 'framer-motion'
import { FilterContext } from 'filter/context/FilterContext'

import { IconFilter } from 'common/icons/IconFilter'
import { NavBarItem } from '../../../common/components/NavBarItem'
import { FiltersDropdown } from './FiltersDropdown'

export const FiltersNavItem = ({
  open,
  setOpen = () => {},
  onSave,
  onCancel,

  ...props
}) => {
  const { filterCount } = useContext(FilterContext)

  return (
    <NavBarItem
      data-cy="metrics-filter"
      className={open && open !== 'filters' ? `notSelected` : null}
      onClick={setOpen}
      {...props}
    >
      <Tooltip title={`Set Filters`} placement="bottom">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <IconFilter size={22} />
          {filterCount ? (
            <NavBarFilterCount
              data-cy="metrics-filter-count"
              className={open && open !== 'filters' ? `notSelected` : null}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
            >
              {filterCount}
            </NavBarFilterCount>
          ) : null}
        </Box>
      </Tooltip>

      {open && <FiltersDropdown onSave={onSave} onCancel={onCancel} />}
    </NavBarItem>
  )
}

const NavBarFilterCount = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.secondary.main,
  fontSize: '10px',
  fontWeight: '800',
  background: theme.palette.primary.main,
  borderRadius: '14px',
  width: '22px',
  height: '22px',
  marginLeft: '10px',
  padding: '0 0 0 0.5px',
}))
