import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { isUndefined, some } from 'lodash'
import { integrationFilters } from 'filter/context/FilterContext'

const INITIAL_PAGE = 1
const initialPage = withDefault(NumberParam, INITIAL_PAGE)
const defaultSort = [
  {
    'aws_lambda_name.keyword': {
      order: 'asc',
    },
  },
]
export const useIntegrationFiltersQueries = () => {
  const [globalSearch, setGlobalSearch] = useQueryParam('search', StringParam)

  const [globalFilters, setGlobalFilters] = useQueryParams(
    integrationFilters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.name]: filter.type === 'string' ? StringParam : BooleanParam,
      }),
      {}
    )
  )
  const [search, setSearch] = useState(globalSearch)
  const [currentPage, setCurrentPage] = useQueryParam('page', initialPage)
  const [sort, setSort] = useState(defaultSort)

  useDebounce(
    () => {
      setGlobalSearch(search)
    },
    500,
    [search]
  )
  useEffect(() => {
    if (globalSearch || some(globalFilters, (filter) => !isUndefined(filter))) {
      setCurrentPage(INITIAL_PAGE)
    }
  }, [globalFilters, globalSearch])

  const clearFilters = () => {
    setGlobalFilters(
      integrationFilters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.name]: undefined,
        }),
        {}
      )
    )
  }
  const resetIntegrationQueryParams = () => {
    clearFilters()
    setSearch(undefined)
    setSort(undefined)
    setCurrentPage(undefined)
  }
  return {
    resetIntegrationQueryParams,
    globalFilters,
    setGlobalFilters,
    clearFilters,
    search,
    globalSearch,
    setSearch,
    setCurrentPage,
    currentPage,
    sort,
    setSort,
  }
}
