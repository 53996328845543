import { durationIntervals } from '../util/duration'
import React, { useState, useEffect } from 'react'
import { Slider, Box } from '@mui/material'
import { useWindowSize } from 'react-use'

import { useTheme } from '@mui/styles'
import { FilterLabel } from './FilterLabel'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { animateProps } from '../util/filterAnimateProps'
import { DropdownTitleWrapper, DropdownWrapper } from 'common/components/FilterSearchDropdown'
import { Chip } from 'common/components/Chip'

const marks = durationIntervals
  .map((di, index) => ({ value: index, label: di.label }))
  .filter((di) => di.value % 4 === 0 || di.value === 0 || di.value === durationIntervals.length - 1)
const smallMarks = [marks[0], marks[marks.length - 1]]

/**
 * This component presents a widget for adding Filter Values to a Duration field
 */
export default ({ filter, value = { min: 0, max: 20 }, onChange }) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [curValue, setCurValue] = useState([value.min, value.max])
  const { width } = useWindowSize()

  const currentMarks = width >= 1330 ? marks : smallMarks

  const handleChange = (_, newValue) => {
    setCurValue([value.min, value.max])
    onChange(filter, {
      min: newValue[0],
      max: newValue[1],
    })
  }

  useEffect(() => {
    if (value.min !== curValue[0] || value.max !== curValue[1]) {
      setCurValue([value.min, value.max])
    }
  }, [value])

  const valueText = (index) => {
    return durationIntervals[index]?.label
  }

  return (
    <DropdownWrapper is-open={isOpen ? 'true' : undefined}>
      <DropdownTitleWrapper
        is-collapsible="true"
        is-open={isOpen ? 'true' : undefined}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FilterLabel label={filter.label} />

          <DropdownIcon isOpen={isOpen} />
        </Box>
      </DropdownTitleWrapper>
      {/* Show selected tags only when dropdown is closed, and only show first 8 with a message stating the total number of tags selected */}
      {value?.min && value?.max && !isOpen ? (
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}
          {...animateProps}
        >
          <Chip label={`min:${valueText(value?.min)}`} size="small" autoTruncate={false} />
          <Chip label={`max:${valueText(value?.max)}`} size="small" autoTruncate={false} />
        </Box>
      ) : null}
      {isOpen && (
        <Box
          sx={{
            overflow: 'hidden',
            padding: '15px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          {...animateProps}
        >
          <Slider
            value={value.custom ? [0, 20] : curValue}
            onChangeCommitted={handleChange}
            onChange={(_, newValue, activeThumb) => {
              if (!Array.isArray(newValue)) {
                return
              }

              if (activeThumb === 0) {
                const minValue = newValue[0] >= newValue[1] ? newValue[1] - 1 : newValue[0]
                setCurValue([minValue, value.max])
              } else {
                const maxValue = newValue[1] <= newValue[0] ? newValue[0] + 1 : newValue[1]
                setCurValue([value.min, maxValue])
              }
            }}
            valueLabelDisplay="on"
            // disableSwap
            getAriaValueText={valueText}
            valueLabelFormat={valueText}
            aria-labelledby="range-slider"
            min={0}
            max={20}
            marks={currentMarks}
            sx={{
              width: '78%',
              transform: 'translateX(-5px)',
              margin: '40px 0',
              '& .MuiSlider-markLabel': {
                color: theme.palette.text.secondary,
              },
              '& .MuiSlider-markLabel.MuiSlider-markLabelActive': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
      )}
    </DropdownWrapper>
  )
}
