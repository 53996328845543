import { Box, Typography } from '@mui/material'

export const FilterLabel = ({ label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: 'auto',

        boxSizing: 'border-box',
      }}
    >
      <Typography variant="textPrimary">{label}</Typography>
    </Box>
  )
}
