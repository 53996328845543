import React from 'react'
import { ClickAwayListener } from '@mui/material'
import { FiltersWidget } from 'filter/components/FiltersWidget'
import { NavBarItemDropdown, NavBarItemDropdownInner } from '../../../common/components/NavBarItem'

export const FiltersDropdown = ({ onSave = () => {}, onCancel = () => {} }) => {
  return (
    <ClickAwayListener onClickAway={onCancel}>
      <NavBarItemDropdown
        initial={{ height: '0' }}
        animate={{ height: 'auto', transition: { duration: 0.2, ease: 'easeOut' } }}
      >
        <NavBarItemDropdownInner>
          <FiltersWidget
            onSave={(newFilters) => {
              onSave(newFilters)
            }}
            onCancel={onCancel}
          />
        </NavBarItemDropdownInner>
      </NavBarItemDropdown>
    </ClickAwayListener>
  )
}
