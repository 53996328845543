import { Dialog, DialogContent, DialogTitle, Slide, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { AnimatePresence, motion } from 'framer-motion'
import { loadStripe } from '@stripe/stripe-js'
import React, { useContext, useState } from 'react'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import { AppContext } from 'app/context/AppContext'

import config from 'config'
import { PaymentCheckoutForm } from './PaymentCheckoutForm'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { SuccessfulMessage } from '../../../common/components/SuccessfulMessage'

const stripePromise = loadStripe(config.stripe.apiKey)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpgradePlanDialog = () => {
  const { activeOrg } = useContext(AppContext)
  const { orgName, orgId } = activeOrg || {}
  const [query, setQuery] = useQueryParams({
    upgradePlan: BooleanParam,
    isUpdatePayment: BooleanParam,
    discountCode: StringParam,
  })
  const [isDone, setIsDone] = useState(false)
  const { isFreePlan, loadingSubscription } = useOrgUsage({ orgId })

  const { upgradePlan: openPaymentDialog, isUpdatePayment, discountCode } = query
  const upgradePlanName = discountCode ? 'Business' : 'Team'
  const closeDialog = () => {
    setQuery({
      upgradePlan: undefined,
      isUpdatePayment: undefined,
      discountCode: undefined,
    })
    setIsDone(false)
  }

  if (!openPaymentDialog || loadingSubscription || (!isFreePlan && !isDone && !isUpdatePayment)) {
    return null
  }
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={200}
      open={!!openPaymentDialog}
      onClose={closeDialog}
      aria-labelledby="billing-dialog-title"
      aria-describedby="billing-dialog-description"
      maxWidth="md"
    >
      {!isDone && (
        <>
          {isUpdatePayment ? (
            <DialogTitle>Update payment method</DialogTitle>
          ) : (
            <DialogTitle>
              Upgrade {orgName} to {upgradePlanName} plan
            </DialogTitle>
          )}
        </>
      )}
      <DialogContent mt={2}>
        <AnimatePresence>
          {!isDone && (
            <motion.div
              key="child"
              initial={{ x: 0 }}
              exit={{ x: -300, opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <Elements stripe={stripePromise}>
                <PaymentCheckoutForm
                  closeDialog={closeDialog}
                  setIsDone={setIsDone}
                  isUpdatePayment={isUpdatePayment}
                  discountCode={discountCode}
                />
              </Elements>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isDone && (
            <motion.div key="child" initial={{ x: 100 }} animate={{ x: 0 }} exit={{ x: -50 }}>
              <SuccessfulMessage
                closeDialog={closeDialog}
                isDone={isDone}
                message={
                  isUpdatePayment ? (
                    <Typography color="text.secondary">
                      Updated payment method successfully
                    </Typography>
                  ) : (
                    <Typography color="text.secondary">
                      Upgraded to {upgradePlanName} plan successfully
                    </Typography>
                  )
                }
              />
            </motion.div>
          )}
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  )
}
