import { differenceInDays, differenceInHours, format, isValid } from 'date-fns'
import { typography } from 'theme/typography'

const CustomizedTicks = (props) => {
  const { x, y, payload, textAnchor, labelColor, currentTimeFrame, dy } = props

  let chartDateFormat = 'h aaa'
  // Days
  if (
    differenceInDays(new Date(currentTimeFrame.stopTime), new Date(currentTimeFrame.startTime)) > 1
  ) {
    chartDateFormat = 'M/d'
  } else if (
    // Hours
    differenceInHours(new Date(currentTimeFrame.stopTime), new Date(currentTimeFrame.startTime)) <=
    1
  ) {
    chartDateFormat = 'hh:mm aaa'
  }

  let value = ''
  if (isValid(new Date(payload.value))) {
    value = format(new Date(payload.value), chartDateFormat)
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        textAnchor={textAnchor}
        fill={labelColor}
        fontSize={typography.textTertiary.fontSize}
        strokeWidth={0}
      >
        {value}
      </text>
    </g>
  )
}

export default CustomizedTicks
