import { subDays, startOfDay } from 'date-fns'
import { toNumber } from 'lodash'

export const SPAN_BAR_SIZE = 10
export const SPAN_XAXIS_HEIGHT = 20
export const durationUnits = 'μs'
export const LAMBDA_TITLE = 'aws.lambda'

export const getName = (span) => {
  if (span.name === 'aws.lambda') {
    return LAMBDA_TITLE
  }
  return span.name
}

export const formatDuration = (duration = 0) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(duration)

const microSecondDuration = (span) => {
  const startTime = span?.startTime || span?.timestamp
  const endTime = span?.endTime || span?.timestamp

  const minimize = startOfDay(subDays(new Date(startTime || 0), 2)).getTime()

  const minStart = new Date(startTime || 0).getTime() - minimize
  const startTimeChars = startTime?.split('.')[1].slice(3).replace('Z', '')

  const minEnd = new Date(endTime || 0).getTime() - minimize
  const endTimeChars = endTime?.split('.')[1].slice(3).replace('Z', '')

  const startMicro = (minStart + parseFloat(`0.${startTimeChars}`)) * 1000
  const endMicro = (minEnd + parseFloat(`0.${endTimeChars}`)) * 1000

  return {
    duration: endMicro - startMicro,
    formattedDuration: `${formatDuration(endMicro - startMicro)} ${durationUnits}`,
    startMicro,
    endMicro,
  }
}

export const getFormattedDuration = (item = {}) => {
  let duration = toNumber(item?.duration) || 0
  if (!duration) {
    duration = new Date(item?.end_time).getTime() - new Date(item?.start_time).getTime()
    duration = duration === 0 ? 1 : duration
  }
  let totalDuration = duration || 0
  const rawDuration = duration || 0
  let durationUnits = 'ms'

  if (totalDuration >= 60000) {
    // 1 minute
    durationUnits = 'm'
    totalDuration = (totalDuration / 60000)?.toFixed(2)
  } else if (totalDuration >= 1000) {
    // 1 second
    durationUnits = 's'
    totalDuration = (totalDuration / 1000)?.toFixed(2)
  } else {
    // milliseconds
    totalDuration = totalDuration?.toFixed(0)
  }
  return {
    rawDuration,
    duration: totalDuration,
    units: durationUnits,
    formatted: `${totalDuration}${durationUnits}`,
  }
}

/**
 * Format Trace for Timeline
 * @param {*} traceToFormat
 * @returns
 */
export const formatTraceForTimeline = (traceToFormat, traceEvents) => {
  if (!traceToFormat) return []

  const outputSpans = []
  const offset = 0
  const indent = 0

  const { startMicro: firstSpanStart } = microSecondDuration(traceToFormat)

  const formatAndFlattenSpan = (span, indent) => {
    const { startMicro, endMicro } = microSecondDuration(span)
    const chart = [startMicro - firstSpanStart + offset, endMicro - firstSpanStart + offset]
    /**
     * Get current span events
     */
    const currentSpanEvents =
      traceEvents
        ?.filter((event) => event.spanId === span.spanId)
        ?.map((event) => {
          const { startMicro: eventStart, endMicro: eventEnd } = microSecondDuration(event)
          const eventChart = [
            eventStart - firstSpanStart + offset,
            eventEnd - firstSpanStart + offset,
          ]
          return {
            ...event,
            indent: indent + 1,
            parentSpanId: event.spanId,
            childCount: 0,
            chart: [eventChart[0], eventChart[1]],
          }
        }) || []

    // Get Child Span Count and add events count
    let childCount = 0
    const countNestedChildSpans = (childSpans = [], count) => {
      count = count + childSpans.length
      for (const childSpan of childSpans) {
        const events = traceEvents?.filter((event) => event.spanId === childSpan.spanId)
        if (childSpan.spans || events?.length) {
          return countNestedChildSpans(childSpan.spans, count + events?.length)
        }
      }
      return count
    }
    if (span.spans || currentSpanEvents) {
      childCount = countNestedChildSpans(span.spans, childCount + currentSpanEvents?.length)
    }
    // Add events as child spans
    if (!span?.eventId && !span?.spans?.some((span) => span.eventId)) {
      span.spans = [...(span?.spans || []), ...currentSpanEvents]
    }

    if (!Array.isArray(span.spans)) {
      return [
        {
          chart,
          indent,
          label: getName(span),
          childCount,
          ...span,
        },
      ]
    }

    const newArray = [
      {
        chart,
        indent,
        label: getName(span),
        childCount,
        ...span,
      },
    ]

    for (const childSpan of span.spans) {
      const spans = formatAndFlattenSpan(childSpan, indent + 1)
      newArray.push(...spans)
    }

    return newArray
  }

  const flatSpans = formatAndFlattenSpan(traceToFormat, indent)
  const responseSpans = [flatSpans[0], ...outputSpans, ...flatSpans.slice(1)]

  const maxEnd = responseSpans.reduce((max, span) => {
    if (span.chart[1] > max) return span.chart[1]
    return max
  }, 0)

  responseSpans[0].chart[1] = maxEnd
  responseSpans[0].chart[0] = 0
  responseSpans[0].duration = (maxEnd - responseSpans[0].chart[0]) * 1000
  responseSpans.sort((a, b) => a.chart[0] - b.chart[0])

  return responseSpans
}
