import { Alert } from '@mui/material'
import { useTheme } from '@mui/styles'
import { Collapsible } from './Collapsible'

/**
 * Displays error messages for any failed action mainly used for forms
 */
export const ErrorMessage = ({ message }) => {
  const theme = useTheme()
  return (
    <Collapsible collapsed={!message}>
      {message && (
        <Alert
          severity="error"
          variant="filled"
          style={{ background: theme.palette.error.main, borderRadius: 6, marginTop: 10 }}
        >
          {message}
        </Alert>
      )}
    </Collapsible>
  )
}
