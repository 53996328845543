import { Box, LinearProgress, Skeleton, Tooltip, Typography } from '@mui/material'
import { useContext } from 'react'
import { AppContext } from 'app/context/AppContext'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { motion } from 'framer-motion'
import { animateProps, SectionTitle } from './billingUtils'
import { HelpCircle } from 'lucide-react'
import { useTheme } from '@mui/styles'

export const Usage = () => {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg
  /**
   * Fetch org usage
   */
  const {
    orgUsage,
    limit,
    tracesUsagePercent,
    activeResourcesPercent,
    loadingUsage,
    isTracesOverLimit,
    isActiveResourcesOverLimit,
    isBusinessPlan,
    isTracesApproachingLimit,
  } = useOrgUsage({
    orgId,
  })

  return (
    <Box component={motion.div} {...animateProps()}>
      <SectionTitle title="Usage" />
      {loadingUsage ? (
        <Box>
          <Skeleton variant="rect" width="100%" height="30px" />
          <Skeleton variant="rect" width="100%" height="30px" sx={{ marginTop: 5 }} />
        </Box>
      ) : (
        <>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="textPrimary" color="text.secondary" sx={{ marginRight: 1 }}>
                  Traces
                </Typography>
                <Tooltip
                  title="A trace is a payload which includes the request/response details, logs, and detailed spans, for each AWS Lambda invocation."
                  placement="top"
                >
                  <HelpCircle size={14} color={theme.palette.text.secondary} />
                </Tooltip>
              </Box>
              <Typography variant="textSecondary" color="text.secondary" gutterBottom>
                {orgUsage?.traces?.toLocaleString()} /{' '}
                {isBusinessPlan ? 'Unlimited' : limit?.traces?.toLocaleString()}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              color={isTracesOverLimit || isTracesApproachingLimit ? 'error' : 'primary'}
              value={Number(tracesUsagePercent)}
            />
          </Box>
          <Box mt={5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="textPrimary" color="text.secondary" sx={{ marginRight: 1 }}>
                  Active Resource Days
                </Typography>
                <Tooltip
                  title="An Active Resource Day is counted when an individual AWS Lambda Function reports metrics within a 24 hour period."
                  placement="top"
                >
                  <HelpCircle size={14} color={theme.palette.text.secondary} />
                </Tooltip>
              </Box>

              <Typography variant="textSecondary" color="text.secondary" gutterBottom>
                {orgUsage?.activeResources?.toLocaleString()} /{' '}
                {isBusinessPlan ? 'Unlimited' : limit?.activeResources?.toLocaleString()}
              </Typography>
            </Box>
            <LinearProgress
              color={isActiveResourcesOverLimit ? 'error' : 'primary'}
              variant="determinate"
              value={Number(activeResourcesPercent)}
            />
          </Box>
        </>
      )}
    </Box>
  )
}
