import { Tooltip, Chip as MuiChip } from '@mui/material'
import { merge } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

export const Chip = ({ label = '', tooltipProps, autoTruncate = true, sx = {}, ...props }) => {
  const ref = useRef()
  const [isTruncated, setIsTruncated] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    const chipWidth = ref?.current?.offsetWidth
    const parentWidth = ref?.current?.parentElement?.offsetWidth

    setIsTruncated(autoTruncate && chipWidth >= parentWidth)
  }, [ref, ref?.current?.parentElement?.clientWidth, width])
  /**
   * Either truncate or wrap overflow text based on 'autoTruncate'
   */
  const labelStyles = isTruncated
    ? {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
      }
    : {
        whiteSpace: 'pre-wrap',
        overflowX: 'hidden',
        wordBreak: 'break-all',
        lineHeight: '14px',
      }

  return (
    <Tooltip title={isTruncated ? label : ''} {...tooltipProps}>
      <MuiChip
        sx={merge(
          {
            '& .MuiChip-label': {
              ...labelStyles,
            },
          },
          sx
        )}
        label={label}
        ref={ref}
        {...props}
      />
    </Tooltip>
  )
}
