import React, { memo, useContext, useMemo, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import numeral from 'numeral'
import CustomizedTicks from './CustomizedTicks'
import CustomTooltip from './CustomTooltip'
import { useTheme } from '@mui/styles'
import { typography } from 'theme/typography'
import { motion } from 'framer-motion'
import { isNumber, random, range } from 'lodash'
import { useQuery } from 'metrics/hooks/useQuery'
import { FilterContext, eventsOptions } from 'filter/context/FilterContext'
import { Box, Skeleton } from '@mui/material'
import ChartHeader from './ChartHeader'
import { formatNumber, getRectanglePath } from '../helpers/graph-helpers'
import InvocationsLegend from './InvocationsLegend'
import ErrorState from 'common/components/ErrorState'
import { useMetricsCount } from 'metrics/hooks/useMetricsCount'
import { useLocation, useNavigate } from 'react-router-dom'
import { stringifyUrl } from 'query-string'
import { AppContext } from 'app/context/AppContext'
import CustomResponsiveContainer from './CustomResponsiveContainer'

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 10, height, maxBarSize = 10 } = {}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      margin: '20px 20px 20px 50px',
      height: height - 20,
    }}
  >
    {range(size)?.map((num) => (
      <Box
        key={`metrics-request-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton variant="rect" width={maxBarSize} height={height - random(10, 80)} />
      </Box>
    ))}
  </Box>
)
const minPointSize = 5
const tooltipWidth = 340
const tooltipHeight = 56
const height = 180
const maxBarSize = 10

const enrichData = (data) => {
  return data?.results?.map((item) => {
    const ok = item.invocations - item.uncaught_errors || 0
    const events =
      item.caught_errors + item.warnings + item.sdk_internal_errors + item.sdk_user_errors || 0
    return {
      ...item,
      ok,
      events,
    }
  })
}

const renderShape =
  ({ valueKey, oppositeKey, hoverColor, focusedBarIndex, separatorColor }) =>
  (props) => {
    let { x, y, width, height, className, fill, radius, index } = props

    const isFocusedOnOthers = isNumber(focusedBarIndex) && focusedBarIndex !== index

    fill = isFocusedOnOthers ? hoverColor : fill

    radius = props[oppositeKey] === 0 ? [10, 10, 10, 10] : radius

    if (props[valueKey] === 0) {
      height = 0
    }

    if (valueKey === 'ok' && props[valueKey] && props[oppositeKey]) {
      y =
        props[oppositeKey] > props[valueKey] + props[oppositeKey] // Just in case if uncaught errors count is more than invocations count we should hide the invocations bar all together
          ? 0
          : props[oppositeKey] > props[valueKey]
          ? y - 5.5
          : y - 2
    }

    return (
      <>
        {/* Invisible bar to make clickable area bigger and easier */}
        {valueKey !== 'events' && (
          <path
            style={{
              cursor: 'pointer',
            }}
            fillOpacity="0"
            className={`recharts-rectangle-${className}`}
            d={getRectanglePath(x - 10, y, maxBarSize + 20, height, radius)}
          />
        )}
        {/* Bar */}
        <path
          style={{
            cursor: valueKey !== 'events' && 'pointer',
          }}
          fill={fill}
          className={`recharts-rectangle-${className}`}
          d={getRectanglePath(x, y, width, height, radius)}
        />
        {/* Separator line */}
        {valueKey === 'ok' && props[oppositeKey] && (
          <path
            fill={separatorColor}
            className={`recharts-rectangle-${className}`}
            d={getRectanglePath(x, y + height, width, 2, 0)}
          />
        )}
      </>
    )
  }

function InvocationsGraph({ showRefreshButton, useCustomContainer }) {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgName } = activeOrg
  const { currentTimeFrame, getFilterValue } = useContext(FilterContext)
  const [focusedBarIndex, setFocusedBarIndex] = useState(null)

  const ChartContainer = useCustomContainer ? CustomResponsiveContainer : ResponsiveContainer

  const { data, isValidating, refresh, error, loading } = useQuery({
    query: 'aws_lambda_invocations',
  })

  const location = useLocation()
  const navigate = useNavigate()

  const { successRateFormatted, invocations, uncaughtErrors } = useMetricsCount(data?.results)

  const invocationsData = useMemo(() => enrichData(data), [data])

  const errorBarColor = theme.palette.error.main
  const okBarColor = theme.palette.border.light
  const eventsBarColor = theme.palette.grey.main
  const eventsBarHoverColor = theme.palette.grey.dark
  const labelColor = theme.palette.text.secondary
  const separatorColor = theme.palette.secondary.main
  let interval = invocationsData?.length >= 10 ? 1 : 0

  const getOkLink = (globalTimeFrame) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        globalTimeFrame: globalTimeFrame || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })
  const getErrorLink = (globalTimeFrame) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        awsLambdaEvents: eventsOptions.reduce((acc, option) => {
          if (option.isUncaughtError) return [...acc, option.id]
          return acc
        }, []),
        globalTimeFrame: globalTimeFrame || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })
  const headingStats = [
    {
      value: formatNumber(invocations),
      description: 'Total invocations',
      link: getOkLink(),
    },
    {
      value: formatNumber(uncaughtErrors),
      description: 'Total Uncaught errors',
      isError: true,
      link: getErrorLink(),
    },
    {
      value: successRateFormatted,
      description: 'Success rate',
    },
  ]

  const onBarClick = (props) => {
    let startDate
    let endDate
    // if last item
    if (props.index === invocationsData.length - 1) {
      startDate = new Date(props.data.time)
      endDate = new Date()
    } else {
      startDate = new Date(props.data.time)
      endDate = new Date(invocationsData[props.index + 1]?.time)
    }
    const okLink = getOkLink(`${startDate?.getTime()},${endDate?.getTime()}`)
    const errorLink = getErrorLink(`${startDate?.getTime()},${endDate?.getTime()}`)

    navigate(!props.isError ? okLink : errorLink)
  }
  return (
    <Box
      sx={{
        minHeight: height + 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ChartHeader
        title="Invocations & Events"
        description={'AWS Lambda invocations count within time frame.'}
        stats={headingStats}
        onRefresh={showRefreshButton && refresh}
        loading={loading}
        isValidating={isValidating}
        error={error}
      />

      {loading || (!invocationsData && isValidating) ? (
        <SkeletonLoading size={30} maxBarSize={maxBarSize} height={height} />
      ) : error ? (
        <ErrorState onReload={refresh} isColumnDirection fullWidth />
      ) : (
        <ChartContainer width="99.9%" height={height}>
          <BarChart
            data={invocationsData}
            barGap={-30}
            onMouseMove={(state) => {
              if (state.activeTooltipIndex >= 0) {
                setFocusedBarIndex(state.activeTooltipIndex)
              } else {
                setFocusedBarIndex(null)
              }
            }}
            onMouseLeave={() => setFocusedBarIndex(null)}
          >
            <Tooltip
              content={<CustomTooltipComponent />}
              cursor={{ fill: theme.palette.secondary.main }}
              wrapperStyle={{
                outline: 'none',
                top: -(tooltipHeight * 2 - 22),
              }}
            />
            <XAxis
              dataKey="time"
              axisLine={false}
              tickLine={false}
              allowDataOverflow={false}
              tickMargin={5}
              height={45}
              interval={interval}
              xAxisId={0}
              tick={
                <CustomizedTicks
                  labelColor={labelColor}
                  currentTimeFrame={currentTimeFrame}
                  dy={15}
                />
              }
            />
            {/* We need two xAxis to make invocations and events bars overlap */}
            <XAxis dataKey="time" xAxisId={1} hide />
            <YAxis
              width={40}
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              tick={{ fontSize: typography.textTertiary.fontSize, fill: labelColor }}
              tickFormatter={(value) => numeral(value || 0).format('0a')}
              yAxisId="left"
              orientation="left"
              dataKey="invocations"
            />
            <YAxis
              width={40}
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              tick={{ fontSize: typography.textTertiary.fontSize, fill: labelColor }}
              tickFormatter={(value) => numeral(value || 0).format('0a')}
              yAxisId="right"
              orientation="right"
              dataKey="events"
            />
            <Bar
              dataKey="events"
              fill={eventsBarColor}
              stackId="a"
              radius={[4, 4, 4, 4]}
              maxBarSize={maxBarSize + 20}
              yAxisId="right"
              xAxisId={1}
              shape={renderShape({
                valueKey: 'events',
                hoverColor: eventsBarHoverColor,
                focusedBarIndex,
                separatorColor,
              })}
            />
            <Bar
              dataKey="uncaught_errors"
              onClick={(data, index) => onBarClick({ data, index, isError: true })}
              stackId="a"
              fill={errorBarColor}
              radius={[0, 0, 10, 10]}
              maxBarSize={maxBarSize}
              minPointSize={minPointSize}
              yAxisId="left"
              xAxisId={0}
              shape={renderShape({
                valueKey: 'uncaught_errors',
                oppositeKey: 'ok',
                focusedBarIndex,
                hoverColor: eventsBarColor,
                separatorColor,
              })}
            />
            <Bar
              dataKey="ok"
              onClick={(data, index) => onBarClick({ data, index, isError: false })}
              stackId="a"
              fill={okBarColor}
              radius={[10, 10, 0, 0]}
              maxBarSize={maxBarSize}
              minPointSize={minPointSize}
              yAxisId="left"
              xAxisId={0}
              shape={renderShape({
                valueKey: 'ok',
                oppositeKey: 'uncaught_errors',
                focusedBarIndex,
                hoverColor: eventsBarColor,
                separatorColor,
              })}
            />
          </BarChart>
        </ChartContainer>
      )}
      <InvocationsLegend />
    </Box>
  )
}

const CustomTooltipComponent = ({ active, payload }) => {
  const invocationsPayload = payload?.find((i) => i.dataKey === 'ok')?.payload

  const { invocations, okInvocations, uncaughtErrors, caughtErrors, warnings, sdkErrors } =
    useMetricsCount([invocationsPayload])

  if (!active || !payload?.length) return null

  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  })

  const items = [
    {
      title: 'Invocations',
      value: formatter.format(invocations),
      isError: !okInvocations,
    },
    {
      title: 'Successful',
      value: formatter.format(okInvocations),
    },
    {
      title: 'Errors - Uncaught',
      value: formatter.format(uncaughtErrors),
      isError: true,
    },

    {
      title: 'Errors - Caught',
      value: formatter.format(caughtErrors),
      isError: true,
    },
    {
      title: 'Warnings',
      value: formatter.format(warnings),
    },
    {
      title: 'SDK Errors',
      value: formatter.format(sdkErrors),
    },
  ]
  return <CustomTooltip width={tooltipWidth} itemDate={invocationsPayload?.time} items={items} />
}

export default memo(InvocationsGraph)
