import { isBefore } from 'date-fns'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { useMemo } from 'react'
import { baseDiscounts } from '../plans'
import { Box, Typography } from '@mui/material'
import { Title } from 'settings/components/billing/billingUtils'
import numeral from 'numeral'

export const updateBaseDiscount = (baseDiscount, discount) => {
  const tracePercentNumber =
    Math.round(((discount.discountPricing.traces.overageUnitPrice - 8) / 8) * 100) * -1
  const traceNewPrice = Math.floor(discount?.discountPricing?.traces?.overageUnitPrice)
  const traceUnits = Math.floor(discount?.discountPricing?.traces?.unitSize)

  const resourcePercentNumber =
    Math.round(((discount.discountPricing.activeResources.overageUnitPrice - 0.02) / 0.02) * 100) *
    -1
  const resourceNewPrice = discount?.discountPricing?.activeResources?.overageUnitPrice.toFixed(2)

  return {
    ...baseDiscount,
    price: `$${Math.floor(discount?.discountPricing?.traces?.overageUnitPrice)}.00 /mo`,
    resourcePrice: `$${resourceNewPrice} /resource/day`,
    // original total is hard coded for now :)
    percent: `${tracePercentNumber}%`,
    resourcePercent: `${resourcePercentNumber}%`,
    tracePercentNumber: tracePercentNumber,
    resourcePercentNumber: resourcePercentNumber,
    replacedDetails: [
      ...(tracePercentNumber !== 0
        ? [
            {
              name: 'discount-available',
              title: `You have a ${tracePercentNumber}% discount on Traces!`,
              render: (
                <Typography variant="h4">You have a {tracePercentNumber}% discount!</Typography>
              ),
            },
            {
              name: 'price',
              title: '$8.00 /mo',
              ...(tracePercentNumber !== 0
                ? {
                    render: (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                          <Title title="$8.00 /mo" sx={{ textDecoration: 'line-through' }} />
                          <Title title={`$${traceNewPrice}.00 /mo`} />
                        </Box>
                        <Typography color="text.secondary">{`Per ${numeral(traceUnits).format(
                          '0a'
                        )} traces (with logs) per month. Unlimited traces (with logs).`}</Typography>
                      </>
                    ),
                  }
                : {}),
              subtitle: `Per ${numeral(traceUnits).format(
                '0a'
              )} traces (with logs) per month. Unlimited traces (with logs).`,
            },
          ]
        : [
            {
              name: 'price',
              title: '$8.00 /mo',
              subtitle: `Per ${numeral(traceUnits).format(
                '0a'
              )} traces (with logs) per month. Unlimited traces (with logs).`,
            },
          ]),
      ...(resourcePercentNumber !== 0
        ? [
            {
              name: 'metrics-discount-available',
              title: `You have a ${resourcePercentNumber}% discount on resources!`,
              render: (
                <Typography variant="h4">
                  You have a {resourcePercentNumber}% discount on resources!
                </Typography>
              ),
            },
            {
              name: 'metrics',
              title: '$0.02 /resource/day',
              ...(resourcePercentNumber !== 0
                ? {
                    render: (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                          <Title
                            title="$0.02 /resource/day"
                            sx={{ textDecoration: 'line-through' }}
                          />
                          <Title title={`$${resourceNewPrice} /resource/day`} />
                        </Box>
                        <Typography color="text.secondary">
                          Unlimited daily active resource days
                        </Typography>
                      </>
                    ),
                  }
                : {}),
              subtitle: 'Unlimited daily active resource days',
            },
          ]
        : [
            {
              name: 'metrics',
              title: '$0.02 /resource/day',
              subtitle: 'Unlimited daily active resource days',
            },
          ]),
      {
        name: 'logs',
        title: 'Trace & Log sampling available',
        subtitle: '30% min',
        render: <Typography variant="h3">Trace & Log sampling available</Typography>,
      },
    ],
  }
}

export const useDiscounts = () => {
  /**
   * Since we have a fixed number of discount codes we are just hard coding those discount codes here
   */
  const { data: welcome22Discount } = useSWR(
    baseDiscounts[0].code &&
      isBefore(new Date(), baseDiscounts[0].expiration) &&
      `/billing/discounts/${baseDiscounts[0].code}`,
    coreApiClient,
    { refreshInterval: 0 }
  )
  const discounts = useMemo(
    () => [
      welcome22Discount
        ? updateBaseDiscount(baseDiscounts[0], welcome22Discount)
        : baseDiscounts[0],
      baseDiscounts[1],
    ],
    [welcome22Discount]
  )
  return discounts
}
