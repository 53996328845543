import { useContext } from 'react'
import { MetricsProvider, MetricsContext } from 'metrics/context/MetricsContext'
import { MetricsNav } from 'metrics/components/Nav/MetricsNav'
import { FilterProvider } from 'filter/context/FilterContext'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { motion } from 'framer-motion'
import { MetricsSummary } from './MetricsSummary'
import InvocationsGraph from 'widgets/components/InvocationsGraph'
import LatencyGraph from 'widgets/components/LatencyGraph'
import { FunctionsList } from 'widgets/components/FunctionsList'
import { AppContext } from 'app/context/AppContext'
import { EnableBulkInstrumentation } from 'common/components/EnableBulkInstrumentation'
import { Box } from '@mui/material'
import { useQuery } from '../hooks/useQuery'

const MetricsInternal = () => {
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const { data: hasData } = useQuery({ query: 'metrics_exist' })
  const { metricsView } = useContext(MetricsContext)

  usePageTitle(metricsView?.name ? `${metricsView.name || ''} - Metrics` : 'Metrics')

  return (
    <EnableBulkInstrumentation
      bypassKey="sls-prod-mode-prompt"
      hasExistingData={hasData}
      orgId={orgId}
      instrumentationType="prod"
    >
      <MetricsNav />
      <Box
        component={motion.div}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '45px 45px 55px 45px',
          height: 'calc(100vh - 75px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          gap: 5,
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: [0, 1],
          y: [20, 0],
          transition: { duration: 0.35, ease: 'easeOut' },
        }}
      >
        <MetricsSummary />
        <InvocationsGraph useCustomContainer />
        <LatencyGraph useCustomContainer />
        <FunctionsList />
      </Box>
    </EnableBulkInstrumentation>
  )
}

const Metrics = () => {
  return (
    <ErrorBoundary>
      <FilterProvider page="metric">
        <MetricsProvider>
          <MetricsInternal />
        </MetricsProvider>
      </FilterProvider>
    </ErrorBoundary>
  )
}

export default Metrics

/**
 * Widgets
 */
