import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useContext } from 'react'
import { motion } from 'framer-motion'
import { IntegrationContext } from '../../context/integration.context'
import Button from 'common/components/Button'
import pluralize from 'pluralize'
import { keys } from 'lodash'
import { ErrorMessage } from 'common/components/ErrorMessage'

export const ConfirmResourcesChangesDialog = () => {
  const {
    openConfirmDialog,
    setOpenConfirmDialog,
    changedResources,
    saveChanges,
    loadingSave,
    saveError,
  } = useContext(IntegrationContext)
  const closeDeleteDialog = () => {
    setOpenConfirmDialog(false)
  }
  return (
    <Dialog
      keepMounted
      transitionDuration={150}
      open={!!openConfirmDialog}
      onClose={closeDeleteDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Confirm changes</DialogTitle>

      <DialogContent mt={2}>
        <motion.div
          key="child"
          initial={{ x: 0 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <Typography variant="textPrimary" color="text.secondary">
            Confirm instrumentation updates across {keys(changedResources)?.length} AWS Lambda{' '}
            {pluralize('functions', keys(changedResources)?.length)}. These changes will happen in
            the background over 1-2 minutes.
          </Typography>
        </motion.div>
        <ErrorMessage message={saveError} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDeleteDialog} color="secondary" disabled={loadingSave}>
          Cancel
        </Button>
        <Button onClick={saveChanges} variant="contained" loading={loadingSave}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
