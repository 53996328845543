import { useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useEffectOnce } from 'react-use'
import { useSnackbar } from 'notistack'
import { acceptOrgInvitation, getOrgDetailsWithInviteToken } from 'util/core.api'
import LoadingPage from 'app/components/LoadingPage'
import { AppContext } from 'app/context/AppContext'

const AcceptInvitation = () => {
  const [inviteToken] = useQueryParam('token', StringParam)
  const { enqueueSnackbar } = useSnackbar()
  const { changeOrg, refreshTokenNow, userOrgs } = useContext(AppContext)

  useEffectOnce(async () => {
    if (!inviteToken) {
      changeOrg(userOrgs[0], true)
    }
    try {
      // We need to get org details before we accept the invite :shrug:
      const { orgId, orgName } = await getOrgDetailsWithInviteToken({ inviteToken })
      const { inviteeRole } = await acceptOrgInvitation({ inviteToken })

      enqueueSnackbar(`🎉 Successfully joined ${orgName}!`)
      await refreshTokenNow({
        defaultOrgName: orgName,
      })
      changeOrg(
        {
          orgId,
          orgName,
          memberRole: inviteeRole,
          isOwner: inviteeRole === 'owner',
        },
        true
      )
    } catch (error) {
      console.error(error)
      enqueueSnackbar(`Invitation token is invalid`, {
        variant: 'error',
        autoHideDuration: 5000,
      })
      changeOrg(userOrgs[0], true)
    }
  })

  return <LoadingPage type="spin3D" />
}

export default AcceptInvitation
