import { format } from 'date-fns'
import { formatDay, formatTime } from 'util/date'
import { IconAWSDynamodb } from '../icons/IconAWSDynamodb'
import { IconAWSSNS } from '../icons/IconAWSSNS'
import { IconAWSSQS } from '../icons/IconAWSSQS'
import { IconAWSS3 } from '../icons/IconAWSS3'
import { IconAWSSSM } from '../icons/IconAWSSSM'
import { IconAWSecretsManager } from '../icons/IconAWSSSecretsManager'
import { IconAWSKinesis } from '../icons/IconAWSKinesis'
import { IconAWSEventBridge } from '../icons/IconAWSEventBridge'
import { IconHTTP } from '../icons/IconHttp'

export const MAX_LOGS = 500
export const MAX_BUFFER = 300
export const MAX_META = 300

export const createInitialLog = () => [
  {
    type: 'local',
    timestamp: new Date().getTime(),
    includeDots: true,
    fullHeight: true,
    message: 'Streaming Active - Awaiting new activity',
  },
]

export function formatLogDate(date) {
  const d = date instanceof Date ? date : new Date(date)
  const res = format(d, 'h:mm:ss:SSS aa - MMM dd y')
  return res
}

export const formatDuration = (milliseconds) => {
  let seconds = milliseconds / 1000
  let minutes = seconds / 60

  if (milliseconds <= 999) {
    return `${Math.round(milliseconds)}ms`
  } else if (seconds <= 59) {
    return `${Math.floor(seconds * 100) / 100}s`
  } else if (minutes >= 1) {
    return `${Math.floor(minutes * 100) / 100}min`
  }
}

export const formatMessages = (activity) => {
  switch (activity.type) {
    case 'span': {
      if (activity.startTime && activity.endTime && !activity.duration) {
        activity.duration =
          new Date(activity.endTime).getTime() - new Date(activity.startTime).getTime()
        activity.durationFormatted = activity.duration ? formatDuration(activity.duration) : null
      }
      break
    }
    default:
  }
  return activity
}

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const formatAWSSDKName = ({ activity, includeDuration = false }) => {
  const { name } = activity
  const [, , service, operation] = name.split('.')
  let finalName = `AWS SDK • ${capitalizeFirstLetter(service)} • ${operation.toUpperCase()}`
  if (name.includes('aws.sdk.dynamodb')) {
    finalName = `AWS SDK • DynamoDB • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.eventbridge')) {
    finalName = `AWS SDK • Event Bridge • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.secretsmanager')) {
    finalName = `AWS SDK • Secrets Manager • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.kinesis')) {
    finalName = `AWS SDK • Kinesis • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.elastictranscoder')) {
    finalName = `AWS SDK • Elastic Transcoder • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.iotdata')) {
    finalName = `AWS SDK • IOT Data • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.kinesisvideo')) {
    finalName = `AWS SDK • Kinesis Video • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.sns')) {
    finalName = `AWS SDK • SNS • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.sqs')) {
    finalName = `AWS SDK • SQS • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.ssm')) {
    finalName = `AWS SDK • SSM • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.s3')) {
    finalName = `AWS SDK • S3 • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.elb')) {
    finalName = `AWS SDK • ELB • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.emr')) {
    finalName = `AWS SDK • EMR • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.es')) {
    finalName = `AWS SDK • ES • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.fms')) {
    finalName = `AWS SDK • FMS • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.ecs')) {
    finalName = `AWS SDK • ECS • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.ec2')) {
    finalName = `AWS SDK • EC2 • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.eks')) {
    finalName = `AWS SDK • EKS • ${operation.toUpperCase()}`
  } else if (name.includes('aws.sdk.ebs')) {
    finalName = `AWS SDK • EBS • ${operation.toUpperCase()}`
  }
  return {
    name: `${finalName}${
      includeDuration && activity.durationFormatted ? ` - ${activity.durationFormatted}` : ''
    }`,
  }
}

export const formatHTTPName = ({ activity, includeDuration = false }) => {
  return {
    name: `HTTP • ${activity?.tags?.http?.method} • ${activity?.tags?.http?.path} ${
      includeDuration && activity.durationFormatted ? ` - ${activity.durationFormatted}` : ''
    }`,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultSDKTags = ({ activity }) => {
  const tags = []

  if (activity?.tags?.sdk?.name) {
    tags.push({
      exists: true,
      title: 'SDK Name',
      label: 'SDK Name',
      value: activity?.tags?.sdk?.name,
      description: 'The name of the Serverless Inc. SDK that was used to collect this information.',
    })
  }
  if (activity?.tags?.sdk?.version) {
    tags.push({
      exists: true,
      title: 'SDK Version',
      label: 'SDK Version',
      value: activity?.tags?.sdk?.version,
      description:
        'The version of the Serverless Inc. SDK that was used to collect this information.',
    })
  }

  return {
    tags,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultDynamoTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSDynamodb {...iconParams} />
  const tags = []
  if (activity?.tags?.aws?.sdk?.region) {
    tags.push({
      exists: true,
      title: 'Region',
      label: 'Region',
      value: activity.tags.aws.sdk.region,
      description: 'The region of the AWS Service that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.dynamodb?.tableName) {
    tags.push({
      exists: true,
      title: 'Table',
      label: 'Table',
      value: activity?.tags?.aws?.sdk?.dynamodb?.tableName,
      description: 'The DynamoDB Table Name that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.dynamodb?.indexName) {
    tags.push({
      exists: true,
      title: 'Index',
      label: 'Index',
      value: activity.tags.aws.sdk.dynamodb.indexName,
      description: 'The DynamoDB Index Name that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.dynamodb?.count) {
    tags.push({
      exists: true,
      title: 'Count',
      label: 'Count',
      value: activity.tags.aws.sdk.dynamodb.count,
      description: 'The number of DynamoDB documents that this SDK request received.',
    })
  }
  if (tags.length < 2 || includeDefaultTags) {
    const { tags: t } = defaultAWSSDKTags({ activity, includeRegion: false })
    tags.push(...t)
  }

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultSNSTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSSNS {...iconParams} />
  const tags = []
  if (activity?.tags?.aws?.sdk?.region) {
    tags.push({
      exists: true,
      title: 'Region',
      label: 'Region',
      value: activity.tags.aws.sdk.region,
      description: 'The region of the AWS Service that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.sns?.topicName) {
    tags.push({
      exists: true,
      title: 'Topic Name',
      label: 'Topic Name',
      value: activity?.tags?.aws?.sdk?.sns?.topicName,
      description: 'The SNS Topic Name this SDK request interacted with.',
    })
  }
  if (
    activity?.tags?.aws?.sdk?.sns?.messageIds &&
    Array.isArray(activity?.tags?.aws?.sdk?.sns?.messageIds)
  ) {
    tags.push({
      exists: true,
      title: 'Message Ids',
      label: 'Message Ids',
      value: activity?.tags?.aws?.sdk?.sns?.messageIds?.join(', '),
      description: 'The SNS Message Ids this SDK request received.',
    })
  }
  if (tags.length < 2 || includeDefaultTags) {
    const { tags: t } = defaultAWSSDKTags({ activity, includeRegion: false })
    tags.push(...t)
  }

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultSQSTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSSQS {...iconParams} />
  const tags = []
  if (activity?.tags?.aws?.sdk?.region) {
    tags.push({
      exists: true,
      title: 'Region',
      label: 'Region',
      value: activity.tags.aws.sdk.region,
      description: 'The region of the AWS Service that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.sqs?.queueName) {
    tags.push({
      exists: true,
      title: 'Queue Name',
      label: 'Queue Name',
      value: activity?.tags?.aws?.sdk?.sqs?.queueName,
      description: 'The SQS queue name that this SDK request interacted with.',
    })
  }
  if (
    activity?.tags?.aws?.sdk?.sqs?.messageIds &&
    Array.isArray(activity?.tags?.aws?.sdk?.sqs?.messageIds)
  ) {
    tags.push({
      exists: true,
      title: 'Message Ids',
      label: 'Message Ids',
      value: activity?.tags?.aws?.sdk?.sqs?.messageIds?.join(', '),
      description: 'The SQS queue messageId that this SDK request received.',
    })
  }
  if (tags.length < 2 || includeDefaultTags) {
    const { tags: t } = defaultAWSSDKTags({ activity, includeRegion: false })
    tags.push(...t)
  }

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultS3Tags = ({
  activity,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSS3 {...iconParams} />
  // We for some reason don't get much info from S3 in our SDK 🤔
  const { tags } = defaultAWSSDKTags({ activity })

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultSSMTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSSSM {...iconParams} />
  // We for some reason don't get much info from SSM in our SDK 🤔
  const { tags } = defaultAWSSDKTags({ activity })

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultSecretsManagerTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSecretsManager {...iconParams} />
  // We for some reason don't get much info from SSM in our SDK 🤔
  const { tags } = defaultAWSSDKTags({ activity })

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultKinesisTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSKinesis {...iconParams} />
  // We for some reason don't get much info from SSM in our SDK 🤔
  const { tags } = defaultAWSSDKTags({ activity })

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultEventBridgeTags = ({
  activity,
  includeDefaultTags = false,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconAWSEventBridge {...iconParams} />
  // We for some reason don't get much info from SSM in our SDK 🤔
  const { tags } = defaultAWSSDKTags({ activity })

  return {
    tags,
    icon,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultAWSSDKTags = ({ activity, includeRegion = true }) => {
  const tags = []
  if (activity?.tags?.aws?.sdk?.region && includeRegion) {
    tags.push({
      exists: true,
      title: 'Region',
      label: 'Region',
      value: activity.tags.aws.sdk.region,
      description: 'The region of the AWS Service that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.service) {
    tags.push({
      exists: true,
      title: 'Service',
      label: 'Service',
      value: activity.tags.aws.sdk.service,
      description: 'The AWS Service that this SDK request interacted with.',
    })
  }
  if (activity?.tags?.aws?.sdk?.operation) {
    tags.push({
      exists: true,
      title: 'Operation',
      label: 'Operation',
      value: activity.tags.aws.sdk.operation,
      description: 'The SDK operation that was performed',
    })
  }
  if (activity?.tags?.aws?.sdk?.error) {
    tags.push({
      exists: true,
      title: 'Error',
      label: 'Error',
      value: `${!!activity.tags.aws.sdk.error}`,
      description: 'The SDK encountered an error with the request.',
    })
  }
  return {
    tags,
  }
}

/**
 * Returns the icon and tags that should be displayed in the inspector details
 *
 * @param {activity} activity The log or activity item being displayed
 * @returns { icon, tags }
 */
export const defaultHttpTags = ({
  activity,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
}) => {
  const icon = <IconHTTP {...iconParams} />
  const tags = []
  if (activity.tags?.http?.host) {
    tags.push({
      exists: true,
      title: 'Host',
      label: 'Host',
      value: activity.tags?.http?.host,
      description: 'The http host this request was made to.',
    })
  }
  if (activity.tags?.http?.method) {
    tags.push({
      exists: true,
      title: 'Method',
      label: 'Method',
      value: activity.tags?.http?.method,
      description: 'The http method this request was made with.',
    })
  }
  if (activity.tags?.http?.path) {
    tags.push({
      exists: true,
      title: 'Path',
      label: 'Path',
      value: activity.tags?.http?.path,
      description: 'The path that was applied to the host.',
    })
  }
  if (activity.tags?.http?.protocol) {
    tags.push({
      exists: true,
      title: 'Protocol',
      label: 'Protocol',
      value: activity.tags?.http?.protocol,
      description: 'The http protocol used when making this request.',
    })
  }
  return {
    icon,
    tags,
  }
}

export const getSelectedSpanTags = ({
  activity,
  iconParams = { style: { fill: 'var(--activityPrimary)', width: '24px' } },
  includeDefaultTags = false,
  includeDuration = false,
  errorColor = 'var(--activityRed)',
}) => {
  const { name } = activity
  let res = {
    icon: null,
    name: '',
    tags: [],
    errorMessage: '',
    hasError: false,
  }
  if (/aws\.sdk/.test(name)) {
    res.hasError = !!activity?.tags?.aws?.sdk?.error
    res.errorMessage = activity?.tags?.aws?.sdk?.error
    iconParams = {
      ...iconParams,
      style: {
        ...(iconParams?.style || {}),
        fill: res.hasError ? errorColor : iconParams?.style?.fill || 'var(--activityPrimary)',
      },
    }
    if (name.includes('aws.sdk.dynamodb')) {
      res = {
        ...res,
        ...defaultDynamoTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.sns')) {
      res = {
        ...res,
        ...defaultSNSTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.sqs')) {
      res = {
        ...res,
        ...defaultSQSTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.s3')) {
      res = {
        ...res,
        ...defaultS3Tags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.ssm')) {
      res = {
        ...res,
        ...defaultSSMTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.secretsmanager')) {
      res = {
        ...res,
        ...defaultSecretsManagerTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.kinesis')) {
      res = {
        ...res,
        ...defaultKinesisTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else if (name.includes('aws.sdk.eventbridge')) {
      res = {
        ...res,
        ...defaultEventBridgeTags({ activity, iconParams, includeDefaultTags }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    } else {
      res = {
        ...res,
        ...defaultAWSSDKTags({ activity, iconParams }),
        ...formatAWSSDKName({ activity, includeDuration }),
      }
    }
  } else if (name && (name.includes('node.http.request') || name.includes('node.https.request'))) {
    res = {
      ...res,
      ...defaultHttpTags({ activity, iconParams }),
      ...formatHTTPName({ activity, includeDuration }),
    }
  }
  return res
}

export const getSpanTimeValue = ({ timestamp, startTime, endTime }) => {
  if (!timestamp && (!startTime || !endTime)) {
    return null
  }

  let day
  let timestampFormatted
  if (timestamp) {
    day = formatDay(timestamp)
    timestampFormatted = formatTime(timestamp)
  }
  if (startTime) {
    day = formatDay(startTime)
    startTime = formatTime(startTime)
  }
  if (endTime) {
    endTime = formatTime(endTime)
  }

  return startTime ? `${startTime} - ${endTime}` : `${timestampFormatted} • ${day}`
}

/**
 * We want to aggregate specific tags from root spans so we can use
 * those tags to display additional info attached to activity messages
 *
 * @param {Array} messages
 * @returns null or tags we want from root span
 */
export const collectRootSpanTags = (messages = []) => {
  return messages.reduce((obj, message) => {
    if (message.type === 'span' && message.name === 'aws.lambda') {
      // Intentionally limited the amount of data we retain to save memory :)
      return {
        [message?.tags?.aws?.requestId]: {
          timestamp: message?.timestamp,
          customTags: message.customTags,
          aws: {
            lambda: message?.tags?.aws?.lambda,
          },
        },
      }
    }
    return obj
  }, null)
}

/**
 * Prevent invalid data from being processed
 * TODO: return null
 */
export const omitNonWhitelistedData = (data = {}) => {
  // Filter out all non aws.sdk Spans
  if (data?.type === 'span') {
    if (
      !data.name.startsWith('aws.sdk') &&
      !data.name.startsWith('node.http.request') &&
      !data.name.startsWith('node.https.request')
    ) {
      return null
    }
  } else if (typeof data?.message === 'string') {
    return null
  }
  return data
}

/**
 * Prevent invalid data from being processed
 */
export const omitInvalidData = (data = {}) => {
  // Data must have a "timestamp"
  if (!data?.timestamp || data?.timestamp === '') {
    console.error('Data is missing "timestamp":', data)
    return null
  } else if (
    !['span', 'log', 'aws-lambda-request', 'aws-lambda-response', 'event'].includes(data.type)
  ) {
    return null
  }
  return data
}

/**
 * Formats error.stack in a pretty way
 * @param {*} err
 */
export const formatErrorStack = (err) => {
  if (!err) {
    return null
  }
  if (err.includes('Uncaught Exception ')) {
    err = err.split('Uncaught Exception ')
    err[1] = err[1].trim()
    err = err.join('Uncaught Exception\n\n')
  }
  if (err.includes('    at')) {
    err = err.split('    at')
    err[0] += '\n'
    err = err.join('\n   at')
  }
  return err
}

export const limitActivity = (items) => {
  const size = new TextEncoder().encode(JSON.stringify(items)).length
  const kiloBytes = size / 1024
  if (kiloBytes >= 2000 || items.length > MAX_LOGS) {
    items.splice(0, 100)
  }
  return items
}

export const limitBufferActivity = (items) => {
  const size = new TextEncoder().encode(JSON.stringify(items)).length
  const kiloBytes = size / 1024
  if (kiloBytes >= 500 || items.length > MAX_BUFFER) {
    items.splice(0, 100)
  }
  return items
}

export const sortActivityItems = ({ items, isBuffer = false, noLimit = false }) => {
  const sorted = items.sort((a, b) => {
    // If times are the same we should fall back to the sequenceId
    if (
      new Date(b.timestamp).getTime() === new Date(a.timestamp || 0).getTime() &&
      a.type === 'log' &&
      b.type === 'log'
    ) {
      return a?.tags?.aws?.sequenceId - b?.tags?.aws?.sequenceId
    } else if (new Date(b.timestamp).getTime() === new Date(a.timestamp || 0).getTime()) {
      return a.sequenceId - b.sequenceId
    }
    return new Date(a.timestamp || 0).getTime() - new Date(b.timestamp || 0).getTime()
  })
  if (noLimit) return sorted
  if (isBuffer) {
    return limitBufferActivity(sorted)
  }
  return limitActivity(sorted)
}

export const attachMetaTags = (activity, metaTags) => {
  if (metaTags) {
    activity.tags = {
      ...(activity?.tags || {}),
      aws: {
        ...(activity?.tags?.aws || {}),
        lambda: {
          ...(activity?.tags?.lambda || {}),
          ...metaTags?.aws?.lambda,
        },
      },
    }
  }

  // Attach input output to lambda request/responses
  if (activity.type === 'aws-lambda-request') {
    activity.customTags = metaTags?.customTags
    activity.input = activity.body
  } else if (activity.type === 'aws-lambda-response') {
    activity.customTags = metaTags?.customTags
    activity.output = activity.body || activity?.tags?.error?.message
  }
  return activity
}
