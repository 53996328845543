import { useContext, useEffect, useRef, useMemo, useState } from 'react'
import { AppContext } from 'app/context/AppContext'
import { debounce, isEqual } from 'lodash'

export const useQuery = (url, filters, method = 'POST', staticFilters = {}) => {
  const { token } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState()
  const [callRefresh, setCallRefresh] = useState()
  const currentFilters = useRef(filters)
  const currentSignal = useRef()

  const urlContainsNull = /\/null\//gi.test(url)
  const methodNorm = `${method}`.toUpperCase()

  const getData = async (overwriteStaticFilters) => {
    if (currentSignal.current) {
      currentSignal.current.abort()
    }

    const controller = new AbortController()
    const { signal } = controller

    currentSignal.current = controller
    setLoading(true)
    setError(null)

    try {
      const res = await fetch(url, {
        method: methodNorm,
        signal,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        ...(['GET', 'HEAD'].indexOf(methodNorm) < 0
          ? {
              body: JSON.stringify(
                currentFilters.current
                  ? { ...currentFilters.current, ...(overwriteStaticFilters || staticFilters) }
                  : overwriteStaticFilters || staticFilters
              ),
            }
          : {}),
      })

      if (!res.ok) {
        const text = await res.text()
        setError(text)
      } else {
        const data = await res.json()
        setData(data?.data ? data?.data : data)
      }
      setLoading(false)
    } catch (err) {
      if (err.name !== 'AbortError' && err.name !== 'DOMException: The user aborted a request.') {
        setLoading(false)
        setError(err.message)
      }
    } finally {
      currentSignal.current = null
    }
  }

  /**
   * We may have multiple filter changes from different places and dependent changes,
   * so wait 10ms for all chained changes to be resolved before fetching the data
   */
  const setFiltersDebounced = useMemo(
    () =>
      debounce(() => {
        setCallRefresh(Date.now())
      }, 10),
    []
  )

  useEffect(() => {
    if (currentFilters.current && !urlContainsNull) {
      getData()
    }
  }, [callRefresh])

  useEffect(() => {
    const sameFilters = isEqual(filters, currentFilters.current)
    if (!urlContainsNull && !sameFilters) {
      setFiltersDebounced()
    }
    currentFilters.current = filters
  }, [urlContainsNull, filters])

  return [{ data, error, loading }, getData]
}
