import { Box, Typography, Tooltip, Fade } from '@mui/material'
import { Chip } from './Chip'

export const TagBox = ({ tag, sx }) => {
  if (!tag.exists || !tag.value || tag.value === 'null') {
    return null
  }
  return (
    <Tooltip
      title={tag.description ? tag.description : ''}
      placement="top"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
    >
      <Box sx={sx}>
        <Typography variant="textSecondary" color="text.secondary" sx={{ marginBottom: '4px' }}>
          {tag.title}
        </Typography>
        <Chip
          label={tag.value}
          autoTruncate={false}
          size="small"
          color={tag.isError ? 'error' : undefined}
        />
      </Box>
    </Tooltip>
  )
}
