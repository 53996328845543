import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Fade, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { AppContext } from 'app/context/AppContext'
import { SidebarAccount } from 'app/components/SidebarAccount'
import { IconServerlessConsole } from 'common/icons/IconServerlessConsole'
import { IconDevMode } from 'common/icons/IconDevMode'
import { IconMetrics } from 'common/icons/IconMetrics'
import { IconExplorer } from 'common/icons/IconExplorer'
import { IconSettings } from 'common/icons/IconSettings'
import { UsageMeter } from './UsageMeter'
import { Link } from 'common/components/Link'
import { NotificationTopBanner } from './NotificationTopBanner'
import { SidebarHelp } from './SidebarHelp'
import {
  defaultExplorerFilterString,
  defaultMetricsFilterString,
} from 'filter/context/FilterContext'

export const LayoutSidebar = ({ children }) => {
  const { pathname } = useLocation()
  const { activeOrg, isConfigMissing } = useContext(AppContext)

  const section = pathname.includes('/metrics')
    ? 'metrics'
    : pathname.includes('/dev-mode')
    ? 'dev-mode'
    : pathname.includes('/explorer')
    ? 'explorer'
    : pathname.includes('/settings')
    ? 'settings'
    : 'other'

  const sidebarLinks = [
    {
      title: 'Metrics',
      name: 'metricsLink',
      to: `/${activeOrg?.orgName}/metrics/awsLambda?${defaultMetricsFilterString}`,
      isVisible: !isConfigMissing,
      className: section === 'metrics' ? 'active' : '',
      icon: <IconMetrics size={24} />,
    },
    {
      title: 'Dev Mode',
      name: 'devModeLink',
      to: `/${activeOrg?.orgName}/dev-mode`,
      isVisible: !isConfigMissing,
      className: section === 'dev-mode' ? 'active' : '',
      icon: <IconDevMode size={24} />,
    },
    {
      title: 'Explorer',
      name: 'explorerLink',
      to: `/${activeOrg?.orgName}/explorer?${defaultExplorerFilterString}`,
      isVisible: !isConfigMissing,
      className: section === 'explorer' ? 'active' : '',
      icon: <IconExplorer size={24} />,
    },
    {
      title: 'Settings',
      name: 'settingsLink',
      to: `/${activeOrg?.orgName}/settings/general`,
      isVisible: true,
      className: section === 'settings' ? 'active' : '',
      icon: <IconSettings size={24} />,
      end: true,
    },
  ]
  const MOTION_DELAY_START = 0.1
  const animateProps = ({ delay = 0 } = {}) => ({
    initial: { x: -20, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
      transition: { delay, duration: 0.25, ease: 'easeOut' },
    },
  })
  const consoleIconSize = 27
  return (
    <ContainerPage section={section}>
      <SectionSidebar section={section}>
        <ConsoleLink
          to={isConfigMissing ? `/${activeOrg?.orgName}/connect` : `/${activeOrg?.orgName}/metrics`}
        >
          <motion.div
            style={{ height: `${consoleIconSize}px` }}
            {...animateProps({ delay: MOTION_DELAY_START })}
          >
            <IconServerlessConsole size={32} />
          </motion.div>
        </ConsoleLink>
        {sidebarLinks
          .filter(({ isVisible }) => isVisible)
          .map(({ to, className, icon, title, isVisible, end, ...rest }, idx) => (
            <Tooltip
              title={title}
              placement="right"
              key={`sidebar-links-${idx}`}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <Box width="100%">
                <SidebarLink className={className} to={to} {...rest}>
                  <SidebarLinkInner {...animateProps({ delay: Number(`0.${idx + 2}`) })}>
                    {icon}
                  </SidebarLinkInner>
                </SidebarLink>
              </Box>
            </Tooltip>
          ))}

        <SidebarBottomSection
          {...animateProps({
            delay:
              MOTION_DELAY_START +
              (sidebarLinks.filter(({ isVisible }) => isVisible).length + 1) / 10,
          })}
        >
          <SidebarHelp />
          <UsageMeter sx={{ marginBottom: '35px' }} />

          <AccountContainer>
            <SidebarAccount />
          </AccountContainer>
        </SidebarBottomSection>
      </SectionSidebar>

      <SectionContent section={section} no-scroll={/dev-mode/.test(pathname) ? 'true' : 'false'}>
        <NotificationTopBanner />
        <Box position="relative" height="100%">
          {children}
        </Box>
      </SectionContent>
    </ContainerPage>
  )
}

export default LayoutSidebar

/**
 * Styles
 */

const ContainerPage = styled('div')(({ ...props }) => ({
  display: 'flex',
  height: `100vh`,
  width: '100vw',
  overflow: 'hidden',
  ...(props['section'] === 'dev-mode' ? {} : { minWidth: '1000px' }),
  boxSizing: 'border-box',
  border: `1px solid ${props['theme'].palette.border.main}`,
}))

const SectionSidebar = styled('div')(({ theme, ...props }) => {
  const topPadding = 18
  const bottomPadding = 40 // Don't put content at bottom of screen or it triggers users' OSX dock
  return {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: `100vh`,
    width: '80px',
    minWidth: '80px',
    padding: `${topPadding}px 0 ${bottomPadding}px 0`,
    transition: 'all 0.2s ease',
    borderRight: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      ...(props['section'] === 'dev-mode' ? { display: 'none' } : {}),
    },
  }
})

const SectionContent = styled('div')(({ theme, ...props }) => ({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  ...(props['no-scroll'] === 'true'
    ? {}
    : {
        [theme.breakpoints.down('lg')]: {
          overflowX: 'scroll',
          overflowY: 'hidden',
        },
      }),
  // ...(props['section'] === 'metrics' ? { overflowY: 'scroll' } : {}),
}))

const ConsoleNavLink = ({ children, ...props }) => <Link {...props}>{children}</Link>

const ConsoleLink = styled(ConsoleNavLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '70px',
  width: '100%',
  color: theme.palette.primary.main,
  marginBottom: '40px',
  padding: '2px 0 10px 0px',
  opacity: 1,
  transition: 'all 0.2s ease',
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.22)',
    opacity: '1',
  },
  '&:active': {
    cursor: 'pointer',
    transform: 'scale(1.1)',
    opacity: '1',
  },
}))

export const sidebarLinkStyles = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '100px',
  width: '100%',
  padding: '25px 0',
  transition: 'all 0.2s ease',
  color: theme.palette.grey.light,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey.dark,
    color: theme.palette.primary.main,
  },
  '&.active': {
    color: theme.palette.primary.main,
  },
})

const SidebarLink = styled(ConsoleNavLink)(({ theme }) => ({
  ...sidebarLinkStyles(theme),
}))

const SidebarLinkInner = styled(motion.div)(({ theme }) => ({
  display: 'flex',
}))

const AccountContainer = styled(motion.div)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  maxHeight: '100px',
  minHeight: '45px',
  width: '100%',
  margin: '0',
}))

const SidebarBottomSection = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'auto',
  width: '100%',
  padding: '0',
  margin: 'auto 0 0 0',
}))
