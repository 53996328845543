import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Button } from 'common/components/Button'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from 'app/context/AppContext'

import { HelpCircle } from 'lucide-react'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import useSWR, { mutate } from 'swr'
import { useTheme } from '@mui/material/styles'
import { coreApiClient } from 'util/coreApiClient'
import { useDebounce } from 'react-use'
import { ExpandMore } from '@mui/icons-material'
import { sleep } from 'common/utils/sleep'
import { updateBaseDiscount } from 'common/hooks/useDiscounts'

export const PaymentCheckoutForm = ({ closeDialog, setIsDone, isUpdatePayment, discountCode }) => {
  const theme = useTheme()
  const [validatingCoupon, setValidatingCoupon] = useState(false)
  const [enteredDiscountCode, setEnteredDiscountCode] = useState('')
  const [appliedDiscount, setAppliedDiscount] = useState(discountCode)
  const [discountExpanded, setDiscountExpanded] = useState(false)
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const { data: backendDiscount } = useSWR(
    appliedDiscount && `/billing/discounts/${appliedDiscount}`,
    coreApiClient,
    {
      refreshInterval: 0,
      onError() {
        setAppliedDiscount(discountCode)
        setValidatingCoupon(false)
      },
      onSuccess() {
        setValidatingCoupon(false)
      },
    }
  )

  /**
   * Discount code from url params changes we
   * should update the applied code
   */
  useEffect(() => {
    setAppliedDiscount(discountCode)
  }, [discountCode])

  /**
   * Update applied discount code after debounce
   */
  useDebounce(
    () => {
      setAppliedDiscount(enteredDiscountCode || discountCode)
      setValidatingCoupon(enteredDiscountCode.trim() !== '' && enteredDiscountCode !== discountCode)
    },
    500,
    [enteredDiscountCode]
  )

  /**
   * Load Stripe
   */
  const stripe = useStripe()
  const elements = useElements()

  /**
   * Card inputs are complete and correct
   */
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  /**
   * Get current subscription
   */
  const { hadSubscriptionBefore, refresh, plans, discounts } = useOrgUsage({ orgId })

  const handleUpgrade = async () => {
    setLoading(true)

    try {
      /**
       * Get setup intent client secret
       */

      const { clientSecret } = await coreApiClient({
        url: `/billing/orgs/${orgId}`,
        method: 'post',
        data: {
          discountCode: appliedDiscount,
        },
      })

      /**
       * If no status returned it means that we don't have user card stored in Stripe
       */
      if (clientSecret) {
        const result = await stripe?.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements?.getElement(CardElement),
          },
        })

        if (result?.error) {
          throw new Error(result?.error?.message)
        }
      }

      /**
       * Set 2 seconds timeout to get updated response
       */
      await sleep(2000)

      /**
       * Refresh org plans.
       */
      refresh()

      /**
       * Refresh payment method section
       */
      mutate(`/billing/orgs/${orgId}/details`)

      setIsDone(true)
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.response?.data?.message || err?.message)
    }
    setLoading(false)
  }

  const isUpgradeButtonDisabled =
    !hadSubscriptionBefore && (!stripe || !elements || !isCardComplete)
  const teamPlan = plans.find((plan) => plan.title === 'team')
  const discount = useMemo(() => {
    if (backendDiscount) {
      return updateBaseDiscount(backendDiscount, backendDiscount)
    }
    return discounts.find((discount) => discount.code === discountCode)
  }, [backendDiscount, discounts, discountCode])
  const border = `1px solid ${theme.palette.border.main}`
  return (
    <>
      {!isUpdatePayment && (
        <Box>
          {appliedDiscount && discount ? (
            <Box border={border} borderRadius="6px">
              <Box display="flex" mb={0.5} py={1} px={2} borderBottom={border}>
                {discount.tracePercentNumber !== 0 ? (
                  <>
                    <Box minWidth="160px">
                      <Typography
                        variant="textPrimary"
                        color="text.secondary"
                        sx={{ textDecoration: 'line-through' }}
                      >
                        {discount?.replacedDetails?.find((detail) => detail.name === 'price')
                          ?.title || teamPlan.details[0]?.title}
                      </Typography>
                      <Typography variant="h1" fontSize="1.8rem">
                        {discount?.price}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                      }}
                    >
                      {discount?.tracePercentNumber !== 0 ? (
                        <Typography variant="h3" gutterBottom>
                          You have a {discount?.percent} discount!
                        </Typography>
                      ) : (
                        <></>
                      )}
                      <Typography variant="textSecondary" color="text.secondary">
                        {discount?.replacedDetails?.find((detail) => detail.name === 'price')
                          ?.subtitle || teamPlan.details[0]?.subtitle}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box display="flex" alignItems="center" borderBottom="none">
                    <Typography variant="h3">{teamPlan.details[0]?.title}</Typography>
                    <Typography
                      variant="textSecondary"
                      color="text.secondary"
                      sx={{ marginLeft: 2 }}
                    >
                      {teamPlan?.details?.[0]?.subtitle}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box display="flex" mb={0.5} borderRadius="6px" py={1} px={2}>
                {discount.resourcePercentNumber !== 0 ? (
                  <>
                    <Box minWidth="160px">
                      <Typography
                        variant="textPrimary"
                        color="text.secondary"
                        sx={{ textDecoration: 'line-through' }}
                      >
                        {discount?.replacedDetails?.find((detail) => detail.name === 'metrics')
                          ?.title || teamPlan.details[1]?.title}
                      </Typography>
                      <Typography variant="h1" fontSize="1.8rem">
                        {discount?.resourcePrice}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                      }}
                    >
                      {discount?.resourcePercentNumber !== 0 ? (
                        <Typography variant="h3" gutterBottom>
                          You have a {discount?.resourcePercent} discount!
                        </Typography>
                      ) : (
                        <></>
                      )}
                      <Typography variant="textSecondary" color="text.secondary">
                        {discount?.replacedDetails?.find((detail) => detail.name === 'metrics')
                          ?.subtitle || teamPlan.details[1]?.subtitle}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box display="flex" alignItems="center" borderBottom="none">
                    <Typography variant="h3">{teamPlan.details[1]?.title}</Typography>
                    <Typography
                      variant="textSecondary"
                      color="text.secondary"
                      sx={{ marginLeft: 2 }}
                    >
                      {teamPlan?.details?.[1]?.subtitle}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box mb={0.5} border={border} borderRadius="6px">
              {teamPlan.details.map((detail, idx) => (
                <Box
                  key={`upgrade-plan-${detail.name}`}
                  display="flex"
                  alignItems="center"
                  py={1}
                  px={2}
                  borderBottom={idx === 0 ? border : 'none'}
                >
                  <Typography variant="h3">{detail?.title}</Typography>
                  <Typography variant="textSecondary" color="text.secondary" sx={{ marginLeft: 2 }}>
                    {detail?.subtitle}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
      {(!hadSubscriptionBefore || isUpdatePayment) && (
        <Box mt={2}>
          <Typography variant="subtitle2" color="text.secondary">
            Add a Card
          </Typography>
          <Box width="100%" p={2} my={0.5} border={border} borderRadius="6px">
            <CardElement
              onReady={(el) => el.focus()}
              onChange={(changeObject) => {
                setErrorMessage('')
                setIsCardComplete(changeObject?.complete)
              }}
              options={{
                style: {
                  base: {
                    fontFamily: 'sans-serif',
                    fontSize: 14,
                    color: theme.palette.text.primary,
                  },
                  invalid: {
                    color: theme.palette.error.main,
                    iconColor: theme.palette.error.main,
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}
      {!isUpdatePayment && (
        <Accordion
          sx={{ padding: '0 1px' }}
          expanded={discountExpanded}
          onChange={(_, isExpanded) => setDiscountExpanded(isExpanded)}
        >
          <AccordionSummary
            sx={{ p: 0 }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              Discount Code
              <br />
              <Typography color="text.secondary" variant="subtitle2" component="span">
                {appliedDiscount && discount && !validatingCoupon ? `${appliedDiscount}` : ''}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 2 }}>
            <FormControl sx={{ width: '100%' }} variant="standard">
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: '10px' }}>
                      {validatingCoupon && <CircularProgress size={18} />}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setValidatingCoupon(true)
                  setEnteredDiscountCode(e.target.value)
                }}
                placeholder="Discount Code"
                id="discount-code-input"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
      {!isUpdatePayment && (
        <Box sx={{ padding: '0 1px' }} display="flex" alignItems="center">
          <Typography variant="textSecondary" color="text.secondary">
            Your card won't be charged yet. You can cancel anytime.
          </Typography>

          <Tooltip
            placement="top"
            arrow
            title="Your card won't be charged until the end of the month based on your usage."
          >
            <HelpCircle style={{ stroke: '#C7C7C7', marginLeft: 2 }} size={15} />
          </Tooltip>
        </Box>
      )}

      <ErrorMessage message={errorMessage} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: 16 }}
          onClick={handleUpgrade}
          variant="contained"
          disabled={isUpgradeButtonDisabled}
          loading={loading}
        >
          {isUpdatePayment ? 'Add' : 'Upgrade'}
        </Button>
      </Box>
    </>
  )
}
