import { useContext, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { getBuckets } from 'util/core.api'
import { AppContext } from 'app/context/AppContext'

export const useHasBuckets = () => {
  const { activeOrg } = useContext(AppContext)
  const [refreshInterval, setRefreshInterval] = useState(2000)

  const orgId = useMemo(() => activeOrg?.orgId, [activeOrg])

  const { data } = useSWR(['buckets', orgId], () => getBuckets({ orgId }), {
    refreshInterval,
  })

  const hasBuckets = data?.data?.exists
  const loading = !data

  useEffect(() => {
    if (!loading && hasBuckets) {
      setRefreshInterval(0)
    }
  }, [loading, hasBuckets])

  return {
    loading,
    hasBuckets,
  }
}
