import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'
import Button from 'common/components/Button'
import { AppContext } from 'app/context/AppContext'
import { defaultMetricsFilterString } from 'filter/context/FilterContext'

/**
 * TODO: Enable mass enable dev mode here :)
 */
export const Complete = ({ handleNext }) => {
  const { activeOrg } = useContext(AppContext)
  const navigate = useNavigate()

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: [0, 1] }}
      animate={{
        scale: [0.8, 1],
        opacity: [0, 1],
        transition: { duration: 1.25, ease: 'easeOut' },
      }}
    >
      <Box
        margin="-5% auto 0 auto"
        width="100%"
        padding="0 20px"
        maxWidth="600px"
        minHeight="calc(var(--app-height) - 118px)"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap="30px"
      >
        <Typography variant="h1">Integration Complete!</Typography>
        <Typography variant="textPrimary">
          Now, would you like to enable logging and tracing for specific resources, or go straight
          to your Metrics views?
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: '5px' }} style={{ gap: '10px' }}>
          <Button
            size="large"
            fullWidth
            onClick={() => {
              handleNext({ endMe: true })
              navigate(`/${activeOrg?.orgName}/metrics/awsLambda?${defaultMetricsFilterString}`)
            }}
          >
            Go to Metrics <ArrowRight size={16} style={{ margin: '1px 0 0 8px' }} />
          </Button>
        </Box>
      </Box>
    </motion.div>
  )
}
