import { useContext } from 'react'
import { RadialBar, RadialBarChart } from 'recharts'
import { Fade, Tooltip, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { Box } from '@mui/system'
import { BooleanParam, useQueryParam } from 'use-query-params'
import { Button } from 'common/components/Button'
import { AppContext } from '../context/AppContext'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { usePrevious } from 'react-use'
import { motion } from 'framer-motion'
import { colors } from '../../theme/colors'
import { Link } from 'common/components/Link'

const METER_HEIGHT = 70
const CORNER_RADIUS = 10
/**
 * This is used because if usage value is less, then it will cause an issue
 * with the curved corner of the arch chart
 */
const MIN_CHART_VALUE = 7000
const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    left: 10,
    maxWidth: 400,
    borderRadius: 6,
    boxShadow:
      '0px 3px 20px -2px rgba(0,0,0,0.2), 0px 3px 20px 0px rgba(0,0,0,0.14), 0px 1px 20px 0px rgba(0,0,0,0.12)',
  },
  arrow: {
    fontSize: 25,
    color: '#fff',
  },
}))

export const UsageMeter = (props) => {
  const theme = useTheme()
  const { activeOrg, isConfigMissing } = useContext(AppContext)
  const { orgName, orgId } = activeOrg || {}
  const classes = useTooltipStyles()

  const [, setOpenPaymentDialog] = useQueryParam('upgradePlan', BooleanParam)
  const {
    isFreePlan,
    loadingUsage,
    loadingSubscription,
    limit,
    tracesUsagePercent,
    isTracesOverLimit,
    orgUsage,
    isTracesApproachingLimit,
  } = useOrgUsage({ orgId })

  const usage = orgUsage?.traces
  const previousTracesUsagePercent = usePrevious(tracesUsagePercent)

  const fillColor =
    isTracesOverLimit || isTracesApproachingLimit ? colors.redPrimary : colors.blackDark
  const chartData = [
    {
      // This is just to show the full arch chart, without it it won't behave in expected way.
      name: 'remaining',
      value: limit?.traces,
      fill: 'none',
      cornerRadius: CORNER_RADIUS,
    },
    {
      name: 'usage',
      value: usage > MIN_CHART_VALUE ? usage : MIN_CHART_VALUE,
      fill: fillColor,
      cornerRadius: CORNER_RADIUS,
    },
  ]

  if (
    loadingUsage ||
    loadingSubscription ||
    !isFreePlan ||
    (isConfigMissing && orgUsage?.traces === 0)
  ) {
    return null
  }

  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      placement="right"
      arrow
      title={
        <Box px={1} py={2} textAlign="center">
          <Typography
            variant="h4"
            fontSize={15}
            color={isTracesOverLimit ? 'error.main' : 'text.secondary'}
          >
            {isTracesOverLimit
              ? 'You exceeded the free limit'
              : isTracesApproachingLimit
              ? 'Approaching Traces Usage Limit'
              : `Traces usage`}
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: 'relative',
              'path[name="remaining"]': {
                // Hide the other (name: remaining) arch chart that determines the total
                display: 'none',
              },
            }}
          >
            <RadialBarChart
              width={120}
              height={70}
              cy={70}
              innerRadius={40}
              outerRadius={70}
              data={chartData}
              startAngle={180}
              endAngle={0}
            >
              <RadialBar
                isAnimationActive={usage > MIN_CHART_VALUE}
                background={{ fill: theme.palette.colors.greyDark }}
                clockWise={true}
                dataKey="value"
              />
            </RadialBarChart>
            <Typography
              variant="textPrimary"
              style={{
                textAnchor: 'middle',
                fontSize: 16,
                color: fillColor,
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {`${tracesUsagePercent || 0}%`}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="textSecondary" color="text.secondary" gutterBottom>
              You have used {usage?.toLocaleString()}/{limit?.traces?.toLocaleString()}.
            </Typography>
            <Box>
              <Typography variant="textSecondary" color="text.secondary">
                Increase your limit for $8/month.
              </Typography>
              <Button
                sx={{
                  marginTop: '10px',
                  background: theme.palette.colors.white,
                  color: theme.palette.colors.blackMedium,
                  borderColor: theme.palette.colors.blackMedium,
                  '&:hover': {
                    borderColor: theme.palette.colors.blackUltraLight,
                  },
                }}
                variant="outlined"
                onClick={() => setOpenPaymentDialog(true)}
                size="small"
                fullWidth
              >
                Upgrade
              </Button>
            </Box>
          </Box>
        </Box>
      }
      classes={classes}
    >
      <Box width="100%" {...props}>
        <Box component={Link} to={`/${orgName}/settings/billing/plans`}>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={motion.div}
            animate={{
              scale: previousTracesUsagePercent !== tracesUsagePercent ? [1, 1.1, 1] : undefined,
              transition: { duration: 0.25, ease: 'easeOut' },
            }}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.25, ease: 'easeOut' },
            }}
          >
            <Box
              bgcolor={theme.palette.grey.light}
              width={8}
              height={METER_HEIGHT}
              transition="all 0.2s ease"
              borderRadius="6px"
              display="flex"
              justifyContent="flex-end"
              overflow="hidden"
            >
              <Box
                bgcolor={
                  isTracesOverLimit || isTracesApproachingLimit
                    ? colors.redPrimary
                    : theme.palette.primary.main
                }
                width="100%"
                height={`${(METER_HEIGHT / 100) * tracesUsagePercent}px`}
                borderRadius="6px"
                marginTop="auto"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  )
}
