import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material'
import { FilterContext } from 'filter/context/FilterContext'
import { Trace } from './Trace'
import { useKeyPress } from 'react-use'

export const ModalTrace = () => {
  const { setAllFilterValues, getFilterValue, scrollParam } = useContext(FilterContext)
  const traceId = getFilterValue('explorerTraceId', false)
  const scrollParams = useRef(scrollParam)

  // If there is a TraceID, show Modal
  const open = Boolean(traceId)

  // This is to push the modal few pixels from the top if notification bar is visible.
  const [topdistance, setTopdistance] = useState(0)
  const notiBar = document.getElementById('notification-bar')
  const notiBarHeight = notiBar?.offsetHeight
  // Timeout 3 seconds is the time for the notification bar to animate in.
  useEffect(() => {
    const delay = notiBarHeight ? 0 : 3000
    let timer
    timer = setTimeout(() => setTopdistance(!!notiBar ? 37 : 0), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [notiBar])

  const handleModalClose = () => {
    setAllFilterValues([
      {
        filter: 'explorerTraceId',
        value: null,
      },
      {
        filter: 'explorerTraceSpanId',
        value: null,
      },

      {
        filter: 'explorerHighlightLogLine',
        value: null,
      },
      {
        filter: 'globalScroll',
        value: scrollParams.current?.globalScroll || null,
      },
    ])
  }

  useKeyPress((e) => e.key === 'Escape' && handleModalClose())

  return (
    <CustomModal
      open={open}
      hideBackdrop
      disableEnforceFocus
      aria-labelledby="modal-trace"
      aria-describedby="modal-trace-description"
      topdistance={topdistance}
    >
      <ModalHolder>
        <Trace close={handleModalClose} />
      </ModalHolder>
    </CustomModal>
  )
}

const CustomModal = styled(Modal)(({ topdistance, theme }) => ({
  margin: 0,
  transition: 'all 0.2s ease',
  marginTop: topdistance || 0,
  marginLeft: 81,
  outline: '0!important',
  border: 'none!important',
  background: theme.palette.secondary.main,
}))

const ModalHolder = styled('div')(() => ({
  outline: '0!important',
}))
