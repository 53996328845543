import { ChevronDown } from 'lucide-react'

export const DropdownIcon = ({ isOpen, style = {}, ...rest }) => {
  return (
    <ChevronDown
      style={{
        marginLeft: 10,
        transition: 'all .1s ease-in-out',
        transform: isOpen ? 'rotateX(180deg)' : 'none',
        ...style,
      }}
      size={21}
      {...rest}
    />
  )
}
