import Typography from '@mui/material/Typography'
import { SettingLayout } from 'settings/components/SettingLayout'
import { useContext, useEffect } from 'react'
import { Box } from '@mui/system'
import { AppContext } from 'app/context/AppContext'
import { useTheme } from '@mui/styles'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { BillingDetails } from './BillingDetails'
import { BusinessPlanInfo } from './BusinessPlanInfo'
import { Plans } from './Plans'
import { Usage } from './Usage'
import { useParams, useNavigate } from 'react-router-dom'
import { upperFirst } from 'lodash'
import { Link } from 'common/components/Link'

export const Billing = () => {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgId, orgName } = activeOrg
  const { subPageName } = useParams()
  const navigate = useNavigate()

  /**
   * Fetch current subscription and usage
   */
  const { isFreePlan, isBusinessPlan, currentPlan, loadingSubscription } = useOrgUsage({
    orgId,
  })

  const sections = [
    {
      name: 'businessPlan',
      title: 'Business Plan',
      exists: isBusinessPlan,
      component: <BusinessPlanInfo />,
    },
    {
      name: 'plans',
      title: `Plans ${!loadingSubscription ? `( ${upperFirst(currentPlan)} )` : ''}`,
      exists: true,
      component: <Plans />,
    },
    {
      name: 'usage',
      title: 'Usage',
      exists: true,
      component: <Usage />,
    },

    {
      name: 'billingDetails',
      title: 'Billing Details',
      exists: !isFreePlan,
      component: <BillingDetails />,
    },
  ]

  useEffect(() => {
    if (!subPageName)
      navigate(`/${orgName}/settings/billing/${sections.filter((i) => i.exists)[0]?.name}`)
  }, [])

  const sidebarWidth = '25%'

  return (
    <SettingLayout style={{ padding: 0, height: `calc(100% - 100px)` }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderRight: `1px solid ${theme.palette.border.main}`,
            width: sidebarWidth,
            height: '100%',
          }}
        >
          {sections
            .filter(({ exists }) => exists)
            .map((section) => (
              <Link
                to={`/${orgName}/settings/billing/${section.name}`}
                key={`billing-section-${section?.name}`}
                underline="none"
              >
                <Box
                  sx={{
                    width: '100%',
                    padding: '20px 30px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color:
                      subPageName === section.name
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    transition: 'all .2s ease-in-out',
                    backgroundColor:
                      subPageName === section.name ? theme.palette.grey.dark : 'none',
                    '&:hover': {
                      backgroundColor: theme.palette.grey.dark,
                      color: theme.palette.text.primary,
                    },
                  }}
                >
                  <Typography variant="h4" sx={{ color: 'currentColor' }}>
                    {section.title}
                  </Typography>
                </Box>
              </Link>
            ))}
        </Box>
        <Box
          sx={{
            width: `calc(100% - ${sidebarWidth})`,
            padding: '25px 25px',
          }}
        >
          {
            sections.filter((i) => i.exists).find((section) => section.name === subPageName)
              ?.component
          }
        </Box>
      </Box>
    </SettingLayout>
  )
}
