import { useContext, useEffect } from 'react'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { AppContext } from 'app/context/AppContext'
import { FilterContext } from 'filter/context/FilterContext'
import { useState } from 'react'
import { includes, merge, set, some } from 'lodash'
import { useDebounce, useFirstMountState } from 'react-use'

export const useQuery = ({
  query = 'aws_lambda_invocations',
  size,
  currentPage,
  sort,
  debounceDelay = 800,
}) => {
  const [data, setData] = useState()
  const { activeOrg } = useContext(AppContext)
  const isFirstMount = useFirstMountState()

  const { currentTimeFrame, queryApiTags } = useContext(FilterContext)

  const initialFilters = {
    time: {
      from: currentTimeFrame?.startTime,
      to: currentTimeFrame?.stopTime,
      interval: currentTimeFrame?.interval,
      zone: queryApiTags?.timezone,
    },
    sort,
  }

  const [filters, setFilters] = useState(initialFilters)
  const { orgId } = activeOrg || {}

  const url = `/v3/query/orgs/${orgId}/queries/${query}`
  const updateFilters = () => {
    const newFilters = { ...initialFilters }

    if (queryApiTags?.filters?.functionName) {
      set(newFilters, 'aws.resourceName', queryApiTags?.filters?.functionName)
    }
    if (queryApiTags?.filters?.coldStart) {
      set(newFilters, 'aws.lambda.isColdstart', queryApiTags?.filters?.coldStart)
    }
    if (queryApiTags?.filters?.duration) {
      set(newFilters, 'aws.lambda.duration', {
        from: queryApiTags?.filters?.duration.min,
        to: queryApiTags?.filters?.duration.max,
      })
    }

    if (queryApiTags?.filters?.region) {
      set(newFilters, 'aws.region', queryApiTags?.filters?.region)
    }

    if (queryApiTags?.filters?.accountId) {
      set(newFilters, 'aws.accountId', queryApiTags?.filters?.accountId)
    }
    if (includes(queryApiTags?.filters?.events, 'WARNING_TYPE_USER')) {
      set(
        newFilters,
        'warning.type',
        queryApiTags?.filters?.events.filter((i) => i === 'WARNING_TYPE_USER')
      )
    }
    if (some(queryApiTags?.filters?.events, (n) => n !== 'WARNING_TYPE_USER')) {
      set(
        newFilters,
        'error.type',
        queryApiTags?.filters?.events.filter((i) => i !== 'WARNING_TYPE_USER')
      )
    }

    if (queryApiTags?.filters?.environment) {
      set(newFilters, 'environment', queryApiTags?.filters?.environment)
    }
    if (queryApiTags?.filters?.namespace) {
      set(newFilters, 'namespace', queryApiTags?.filters?.namespace)
    }
    if (queryApiTags?.filters?.customTags) {
      set(newFilters, 'custom', queryApiTags?.filters?.customTags)
    }
    return newFilters
  }
  useEffect(() => {
    if (isFirstMount) {
      const newFilters = updateFilters()
      setFilters(newFilters)
    }
  }, [])
  useDebounce(
    () => {
      const newFilters = updateFilters()
      setFilters(newFilters)
    },
    debounceDelay,
    [currentTimeFrame, queryApiTags, sort]
  )

  const payload = merge(filters, {
    page: {
      from: (currentPage - 1) * size || 0, // 'from' is zero indexed
      size,
    },
  })
  const fetchItems = async () => {
    return coreApiClient({
      url,
      method: 'post',
      data: payload,
    })
  }

  const swrKey = JSON.stringify({ url, query, payload, sort })
  const {
    data: fetchedData,
    error,
    mutate,
    isValidating,
  } = useSWR(swrKey, fetchItems, { shouldRetryOnError: false })
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData])
  const loading = !data && !error
  return {
    data,
    loading,
    error,
    isValidating,
    refresh: mutate,
  }
}
