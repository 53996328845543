import { forwardRef, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import {
  styled,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from '@mui/material'
import { motion } from 'framer-motion'
import { BooleanParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import { SettingLayout } from 'settings/components/SettingLayout'
import { IntegrationRow } from './IntegrationRow'
import { AppContext } from 'app/context/AppContext'
import { listIntegrationsByOrgId, removeIntegrationById } from 'util/core.api'
import { useSnackbar } from 'notistack'
import { values } from 'lodash'
import Button from 'common/components/Button'
import { Checkbox } from 'common/components/Checkbox'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Integrations = () => {
  const { activeOrg, setIsConfigMissing } = useContext(AppContext)

  const { orgId } = activeOrg || {}

  const [checked, setChecked] = useState(false)
  const [integrationToRemove, setIntegrationToRemove] = useState(null)
  const [removeStateLoading, setRemoveStateLoading] = useState({})

  const { enqueueSnackbar } = useSnackbar()
  const [, setOpenCreateIntegration] = useQueryParam('createIntegration', BooleanParam)
  const [query, setQuery] = useQueryParams({
    integrationId: StringParam,
    integrationType: StringParam,
    editIntegration: BooleanParam,
    deployRoleView: StringParam,
  })
  /**
   * If integration is being removed refresh every 2 seconds
   */
  const refreshInterval =
    integrationToRemove || values(removeStateLoading).some((loadingState) => loadingState)
      ? 2000
      : null

  const { data, mutate } = useSWR(
    ['integrations', orgId],
    () => listIntegrationsByOrgId({ orgId }),
    {
      refreshInterval,
    }
  )
  const integrations = data?.integrations
  /**
   * Refresh integrations list on integration dialog close
   */
  useEffect(() => {
    if (!query.editIntegration) {
      mutate()
    }
  }, [query])
  /**
   * Update remove loading state on mount
   */
  useEffect(() => {
    // We just removed the last integration so we want to update app context
    // so that we let the reset of the app know we are missing the config
    if (integrations && !integrations?.length) {
      setIsConfigMissing(true)
    }
  }, [integrations])

  const openDialog = () => setOpenCreateIntegration(true)

  const closeDeleteDialog = () => {
    setChecked(false)
    setIntegrationToRemove(null)
  }

  const removeIntegration = async () => {
    const { integrationId } = integrationToRemove || {}
    setRemoveStateLoading((prev) => ({
      ...prev,
      [integrationId]: true,
    }))

    try {
      await removeIntegrationById({ integrationId })
      // We just removed the last integration so we want to update app context
      // so that we let the reset of the app know we are missing the config
      if (integrations?.length === 1) {
        setIsConfigMissing(true)
      }
      mutate()
    } catch (error) {
      enqueueSnackbar(
        error?.message || 'Something went wrong removing this integration please try again.',
        {
          variant: 'error',
          autoHideDuration: 5000,
        }
      )
      setRemoveStateLoading((prev) => ({
        ...prev,
        [integrationId]: false,
      }))
    } finally {
      closeDeleteDialog()
    }
  }

  const openEditDialog = ({ integrationId, integrationType }) =>
    setQuery({
      integrationId,
      integrationType,
      editIntegration: true,
    })

  return (
    <SettingLayout sx={{ flexDirection: 'column' }}>
      <StyledHeader>
        <Typography variant="h2">Integrations</Typography>
        <Button sx={{ minWidth: '164px' }} onClick={openDialog} variant="contained">
          Add Integration
        </Button>
      </StyledHeader>
      <Box sx={{ margin: '20px 0 0 0' }}>
        {!integrations ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(var(--app-height) - 250px)"
            width="100%"
          >
            <CircularProgress size={40} thickness={5} />
          </Box>
        ) : (integrations || []).length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1], transition: { duration: 0.6, ease: 'easeOut' } }}
          >
            <NoDataMessage component="div" variant="textPrimary">
              No Integrations found. <span onClick={openDialog}>Click here to create one</span>.
            </NoDataMessage>
          </motion.div>
        ) : (
          <Box width="100%" padding="10px 0 20px 0">
            {integrations.map((integration) => {
              return (
                <IntegrationRow
                  key={integration?.integrationId}
                  integration={integration}
                  removeStateLoading={removeStateLoading}
                  setIntegrationToRemove={setIntegrationToRemove}
                  openEditDialog={openEditDialog}
                />
              )
            })}
            {integrationToRemove && (
              <Dialog
                TransitionComponent={Transition}
                keepMounted
                transitionDuration={150}
                open={!!integrationToRemove}
                onClose={closeDeleteDialog}
                aria-labelledby="billing-dialog-title"
                aria-describedby="billing-dialog-description"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>Remove Integration</DialogTitle>

                <DialogContent mt={2}>
                  <motion.div
                    key="child"
                    initial={{ x: 0 }}
                    exit={{ x: -300, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <Typography
                      variant="textPrimary"
                      color="text.secondary"
                      fontWeight="bold"
                      mb={2}
                    >
                      Please confirm you understand the following:
                    </Typography>

                    <IntegrationRow integration={integrationToRemove} isDialog />
                    <Checkbox
                      label={
                        'I understand this will disable Metrics, Traces, Logs & Alerts, plus all resources in my account necessary to collect them.'
                      }
                      checked={checked}
                      onChange={(e) => setChecked((prev) => !prev)}
                    />
                  </motion.div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDeleteDialog} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={removeIntegration}
                    variant="contained"
                    color="error"
                    loading={removeStateLoading?.[integrationToRemove?.integrationId]}
                    disabled={!checked}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Box>
        )}
      </Box>
    </SettingLayout>
  )
}

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const NoDataMessage = styled(Typography)({
  fontSize: '13px',
  marginTop: '10px',

  '& span': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})
