import { keyframes } from '@emotion/react'
import { styled } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { useTheme } from '@mui/styles'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import { Loading } from 'common/components/Loading'
import config from 'config'

const { WebElementClickedV1Event } = WebEvents

export const Button = ({
  children,
  loading,
  disabled,
  publishEventProps = {},
  onClick = () => {},
  ...props
}) => {
  const { palette } = useTheme()

  const sendClickEvent = async () => {
    try {
      const localData = window.localStorage.getItem(config.localStorageKey)
      const storageData = JSON.parse(localData)
      if (
        storageData?.idToken?.trim() === '' ||
        storageData?.user?.userId?.trim() === '' ||
        storageData?.activeOrg?.orgId?.trim() === ''
      ) {
        return
      }
      const token = storageData?.idToken
      const userId = storageData?.user?.userId
      const orgUid = storageData?.activeOrg?.orgId
      const slsCoreEventsClient = new SlsCoreEventsClient(config.platform.eventsUrl, token)
      const clickEvent = new WebElementClickedV1Event({
        orgUid,
        userId,
        elementId: publishEventProps.elementId,
        action: publishEventProps.action,
        actionDetails: publishEventProps.actionDetails,
      })
      await slsCoreEventsClient.publish(clickEvent)
    } catch (error) {
      console.error(error)
    }
  }

  const click = async (e) => {
    onClick(e)
    if (publishEventProps?.elementId && publishEventProps?.action) {
      await sendClickEvent()
    }
  }

  return (
    <MuiButton onClick={click} disabled={disabled || !!loading} {...props}>
      {loading ? (
        <Loading size={16} thickness={4} color={palette.secondary.contrastText} />
      ) : (
        !loading && children
      )}
    </MuiButton>
  )
}

const wave = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-7px);
  }
`

export const Wave = styled('div')({
  position: 'relative',
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '24px',

  '& .dot:nth-of-type(2)': {
    animationDelay: '-1.1s',
  },
  '& .dot:nth-of-type(3)': {
    animationDelay: '-0.9s',
  },
})

export const Dot = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  marginRight: '3px',

  background: 'currentColor',
  animation: `${wave} 1.3s linear infinite`,
}))

export default Button
