import { Box, Divider, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/styles'
import { animateProps, SectionTitle, Title } from './billingUtils'
import { useContext } from 'react'
import { AppContext } from 'app/context/AppContext'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { LoadingSpinner } from 'common/components/LoadingSpinner'

const InfoBox = ({ title, value, unit }) => {
  const isPrice = title.includes('Price')
  return (
    <Box
      sx={{
        width: 240,
        height: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography color="text.secondary" variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Title title={`${isPrice ? `$${value?.toFixed(2)}` : value}`} />
        {unit && unit !== '0/mo' && (
          <Typography color="text.secondary" style={{ marginLeft: 5 }}>
            {unit}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
export const BusinessPlanInfo = () => {
  const theme = useTheme()
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg
  /**
   * Fetch current subscription and usage
   */
  const { orgSubscription, loadingSubscription } = useOrgUsage({
    orgId,
  })
  const { pricing } = orgSubscription || {}
  if (loadingSubscription) {
    return <LoadingSpinner />
  }
  return (
    <Box component={motion.div} {...animateProps()}>
      <SectionTitle title="Business Plan" />

      <Box
        sx={{
          mb: 2,
          justifyContent: 'space-between',
          display: 'flex',
          padding: 2,
          borderRadius: '6px',
          border: `1px solid ${theme.palette.border.main}`,
        }}
      >
        <InfoBox
          animateProps={animateProps}
          idx={0}
          title={`Traces Reserved Price`}
          value={pricing?.traces?.reservedUnitPrice || 0}
          unit={`/${formatter.format(pricing?.traces?.unitSize || 0)}/mo`}
        />
        <Divider orientation="vertical" flexItem />
        <InfoBox
          animateProps={animateProps}
          idx={1}
          title={`Reserved Traces`}
          value={pricing?.traces?.reservedUnits || 0}
          unit={`x ${formatter.format(pricing?.traces?.unitSize || 0)}/mo`}
        />
        <Divider orientation="vertical" flexItem />
        <InfoBox
          animateProps={animateProps}
          idx={1}
          title={`Traces Overage Price`}
          value={pricing?.traces?.overageUnitPrice || 0}
          unit={`/${formatter.format(pricing?.traces?.unitSize || 0)}/mo`}
        />
      </Box>
      <Box
        sx={{
          mb: 2,
          justifyContent: 'space-between',
          display: 'flex',
          padding: 2,
          borderRadius: '6px',
          border: `1px solid ${theme.palette.border.main}`,
        }}
      >
        <InfoBox
          animateProps={animateProps}
          idx={0}
          title={`Active Resource Days Reserved Price`}
          value={pricing?.activeResources?.reservedUnitPrice || 0}
          unit={`/resource/day`}
        />
        <Divider orientation="vertical" flexItem />
        <InfoBox
          animateProps={animateProps}
          idx={1}
          title={`Reserved Active Resource Days`}
          value={pricing?.activeResources?.reservedUnits || 0}
          unit={'/mo'}
        />
        <Divider orientation="vertical" flexItem />
        <InfoBox
          animateProps={animateProps}
          idx={1}
          title={`Active Resource Days Overage Price`}
          value={pricing?.activeResources?.overageUnitPrice || 0}
          unit={`/resource/day`}
        />
      </Box>
    </Box>
  )
}
