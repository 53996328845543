import { useContext, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material'
import { Button } from 'common/components/Button'
import { useSnackbar } from 'notistack'
import { SettingLayout } from 'settings/components/SettingLayout'
import { AppContext } from 'app/context/AppContext'
import { useQuery } from 'common/hooks/useQuery'
import config from 'config'
import { updateOrgName } from 'util/core.api'
import { useNavigate } from 'react-router-dom'
import { handleApiError } from 'common/utils/handleApiError'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { Copy } from 'lucide-react'

const identity = config.platform.identityUrl

export const General = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    activeOrg: { orgId, orgName, isOwner },
    user,
    refreshTokenNow,
  } = useContext(AppContext)
  const superAdmin = user?.superAdmin
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmInput, setConfirmInput] = useState('')
  const [confirmError, setConfirmError] = useState({
    isError: false,
    helperText: '',
  })
  const [submittingNewName, setSubmittingNewName] = useState(false)
  const [formData, setFormData] = useState({
    orgName: '',
  })

  const [{ data, loading }] = useQuery(`${identity}/orgs/${orgId}/token`, {}, 'GET')

  const handleClose = async ({ confirm }) => {
    if (confirm) {
      setSubmittingNewName(true)
      setConfirmError({
        isError: false,
        helperText: '',
      })
      try {
        const res = await updateOrgName({ orgId, orgName: formData.orgName })

        if (res.statusCode) {
          throw res
        }

        // Refresh token so that the org is in our org list
        await refreshTokenNow({ defaultOrgName: formData.orgName })

        setOpenConfirm(false)
        setConfirmInput('')
        navigate(`/${formData.orgName}/settings/general`)
      } catch (error) {
        const helperText = handleApiError({
          error,
          defaultMessage: 'Org name is invalid',
        })
        setConfirmError({
          isError: true,
          helperText,
        })
      } finally {
        setSubmittingNewName(false)
      }
    } else {
      setOpenConfirm(false)
      setConfirmInput('')
      setConfirmError({
        isError: false,
        helperText: '',
      })
    }
  }

  useEffect(() => {
    setFormData({
      ...formData,
      orgName,
    })
  }, [orgName])

  const formChanged = formData.orgName !== orgName

  return (
    <SettingLayout style={{ height: '75%' }}>
      <Box height="fit-content" width="100%">
        <Typography variant="h2">General</Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} mt={2}>
            <FormControl sx={{ width: '100%' }} variant="standard">
              <TextField
                label="Org Name"
                disabled={!isOwner}
                readOnly={!isOwner}
                value={formData.orgName}
                onChange={(e) => setFormData({ ...formData, orgName: e.target.value })}
                placeholder="Org Name"
                id="org-name-input"
              />
            </FormControl>
            {/* Org ID section */}
            {(isOwner || superAdmin) && (
              <FormControl sx={{ marginTop: '20px', width: '100%' }}>
                <>
                  <TextField
                    label="Org ID"
                    id="org-id"
                    type="text"
                    disabled
                    readOnly
                    value={orgId}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                          <Tooltip title="Copy to Clipboard">
                            <IconButton
                              onClick={() => {
                                navigator.clipboard.writeText(orgId)
                                enqueueSnackbar('Copied org ID to clipboard')
                              }}
                            >
                              <Copy size={18} />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography variant="textSecondary" color="text.secondary" mt={1}>
                    This is your org unique identifier. It will never change.
                  </Typography>
                </>
              </FormControl>
            )}
            {/* Org token section */}
            {(isOwner || superAdmin) && (
              <FormControl sx={{ marginTop: '20px', width: '100%' }}>
                {loading ? (
                  <Skeleton width={'100%'} height={`74px`} variant="text" />
                ) : (
                  <>
                    <TextField
                      id="org-token"
                      label="Org Token"
                      type="text"
                      disabled
                      readOnly
                      value={data?.token}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                            <Tooltip title="Copy to Clipboard">
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(data?.token)
                                  enqueueSnackbar('Copied org token to clipboard')
                                }}
                              >
                                <Copy size={18} />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography variant="textSecondary" color="text.secondary" mt={1}>
                      Last Used: {data?.lastUsed}
                    </Typography>
                  </>
                )}
              </FormControl>
            )}

            <Button
              color="primary"
              size="medium"
              onClick={() => setOpenConfirm(true)}
              disabled={!isOwner || !formChanged || formData.orgName.trim() === ''}
              sx={{ marginTop: '30px' }}
            >
              Save
            </Button>
          </Grid>
          <Grid item md={6} xs={12} />
        </Grid>
      </Box>

      <Dialog
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="confirm-dialog"
        aria-describedby="confirm-save-details"
      >
        <DialogTitle id="confirm-dialog-title">
          Are you sure you want to change your organization name?
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '10px' }} variant="textPrimary">
            Changing your organization name will require you to update the org attribute in any
            service you have deployed via a serverless.yml file with console enabled.
          </DialogContentText>
          {confirmError?.isError && (
            <FormControl sx={{ width: '100%', marginTop: '15px' }} variant="standard">
              <TextField
                label="Try a different org name?"
                disabled={!isOwner}
                readOnly={!isOwner}
                value={formData.orgName}
                onChange={(e) => setFormData({ ...formData, orgName: e.target.value })}
                placeholder="Org Name"
                color="primary"
                id="org-name-input"
              />
            </FormControl>
          )}
          <FormControl
            sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}
            variant="standard"
          >
            <TextField
              label={`Type the new org name to confirm (${formData.orgName})`}
              placeholder={formData.orgName}
              value={confirmInput}
              onChange={(e) => setConfirmInput(e.target.value)}
              id="confirm-org-name-input"
              onKeyUp={(event) => {
                if (event.code === 'Enter' && confirmInput === formData.orgName) {
                  handleClose({ confirm: true })
                }
              }}
            />
          </FormControl>
          <ErrorMessage message={confirmError?.isError ? confirmError?.helperText : ''} />
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={() => handleClose({ confirm: false })}>
            Discard
          </Button>
          <Button
            loading={submittingNewName}
            variant="contained"
            disabled={confirmInput !== formData.orgName}
            onClick={() => handleClose({ confirm: true })}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </SettingLayout>
  )
}
