import { useContext, useState } from 'react'

import { Box, Divider, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

import { FilterContext, globalScopes } from 'filter/context/FilterContext'
import { MetricsContext } from 'metrics/context/MetricsContext'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { Button } from 'common/components/Button'

export const MetricsViewDropdownCreateOrClone = ({
  onClose,
  setDropdown,
  metricsViewToClone = null,
}) => {
  const { getScopeAndPageFilterList } = useContext(FilterContext)
  const { createMetricsView, navigateToMetricsView } = useContext(MetricsContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const [scopePickerOpen, setScopePickerOpen] = useState(false)

  // Indicates name passed to the API
  const [metricViewScope] = useState(globalScopes[0])

  // Indicates name passed to the API
  const [metricViewName, setMetricViewName] = useState(null)

  // Indicates description passed to the API
  const [metricViewDescription, setMetricViewDescription] = useState('')

  /**
   * Creates a Metrics View in the API
   * @returns
   */
  const createMetricsViewWrapper = async (e) => {
    e?.stopPropagation()
    setIsLoading(true)
    setErrorMessage(null)
    // setScopePickerOpen(false)

    // Validate
    if (!metricViewName || metricViewName === '') {
      setErrorMessage('A "Name" is required')
      setIsLoading(false)
      return
    }
    if (metricViewName.length < 3) {
      setErrorMessage('A "Name" must be at least 3 characters')
      setIsLoading(false)
      return
    }

    const newMetricsView = {}
    newMetricsView.name = metricViewName
    newMetricsView.description = metricViewDescription
    newMetricsView.version = '1'

    // If cloning, copy the filters of the cloned view.
    if (metricsViewToClone) {
      newMetricsView.filters = metricsViewToClone.filters
    } else {
      newMetricsView.filters = getScopeAndPageFilterList(metricViewScope.name, 'metric')
      const scopeFilter = newMetricsView.filters.find((f) => f.filter === 'globalScope')
      scopeFilter.value = metricViewScope.name
    }

    let res
    try {
      res = await createMetricsView(newMetricsView)
      res = res?.data
    } catch (error) {
      console.error(error)
      setErrorMessage('Sorry, something went wrong.  Please try again.')
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setErrorMessage(null)
    navigateToMetricsView(res.id)
    setDropdown(null)
  }

  return (
    <CreateDialogue
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.2, ease: 'easeOut' } }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '13px',
        }}
      >
        <Typography variant="h4">{metricsViewToClone ? 'Clone' : 'Create'} Metrics View</Typography>
        <DialogueControls>
          <Button size={'small'} color={'secondary'} onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            size={'small'}
            color={'primary'}
            onClick={createMetricsViewWrapper}
            sx={{ marginLeft: 1 }}
          >
            {metricsViewToClone ? 'Clone' : 'Create'}
          </Button>
        </DialogueControls>
      </Box>
      <Divider sx={{ margin: '10px 0' }} />
      <ErrorMessage message={errorMessage} />

      {metricsViewToClone && (
        <Box>
          <Typography variant="textPrimary">Cloning From</Typography>
          <StyledValue>
            {metricsViewToClone.icon}
            <Typography variant="textPrimary">{metricsViewToClone.name}</Typography>
          </StyledValue>
        </Box>
      )}
      {/* 
      {!metricsViewToClone && (
        <StyledPair>
          <StyledKey>Scope</StyledKey>
          <StyledDropdown is-open={scopePickerOpen}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setScopePickerOpen(!scopePickerOpen)}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                {metricViewScope.icon}
                <Typography variant="textPrimary" ml={1}>
                  {metricViewScope.name === 'api'
                    ? metricViewScope.name.toUpperCase()
                    : metricViewScope.name}
                </Typography>
              </Box>
              {scopePickerOpen ? (
                <ChevronUp size={21} style={{ marginRight: 10 }} />
              ) : (
                <ChevronDown size={21} style={{ marginRight: 10 }} />
              )}
            </Box>
            <StyledDropdownOptions open={scopePickerOpen}>
              {globalScopes.map((scope, i) => {
                return (
                  <StyledDropdownOption
                    key={scope.name}
                    padextra={(i > 0).toString()}
                    onClick={() => {
                      setMetricViewScope(scope)
                      setScopePickerOpen(false)
                    }}
                  >
                    {scope.icon}{' '}
                    <Typography variant="textPrimary" ml={1}>
                      {scope.displayName}
                    </Typography>
                  </StyledDropdownOption>
                )
              })}
            </StyledDropdownOptions>
          </StyledDropdown>
        </StyledPair>
      )} */}

      <TextField
        label="Name"
        autoFocus={true}
        placeholder={'New Metrics View'}
        onChange={(e) => {
          setMetricViewName(e.target.value)
          setErrorMessage('')
        }}
        sx={{ mb: 2 }}
        onKeyUp={(event) => {
          if (event.code === 'Enter') {
            createMetricsViewWrapper(event)
          }
        }}
      />

      <TextField
        label="Description"
        placeholder="Optional description"
        onChange={(e) => {
          setMetricViewDescription(e.target.value)
          setErrorMessage('')
        }}
        onKeyUp={(event) => {
          if (event.code === 'Enter') {
            createMetricsViewWrapper(event)
          }
        }}
      />
    </CreateDialogue>
  )
}

const CreateDialogue = styled(motion.div)(({ theme }) => ({
  padding: '20px',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.grey.light}`,
  borderRadius: '4px',
}))

const DialogueControls = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '& > * + *': {
    marginLeft: '5px',
  },
}))

const StyledValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  marginTop: '10px',
  boxSizing: 'border-box',
  marginBottom: 20,
  color: theme.palette.text.secondary,
  '& svg': {
    fill: `${theme.palette.text.secondary} !important`,
    color: theme.palette.text.secondary,
    marginRight: '5px !important',
  },
}))
