import {
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material'
import { ChevronRight } from 'lucide-react'
import { keys } from 'lodash'
import { TagBox } from 'common/components/TagBox'

/**
 * Section Details
 */
export const CustomTagsSection = ({ customTags, ...props }) => {
  const uiData = {}
  uiData.tags = []

  try {
    const parsedTags = JSON.parse(customTags)

    for (const key of keys(parsedTags)) {
      uiData.tags.push({
        exists: true,
        title: key,
        value: parsedTags[key],
        description: 'Custom tags are tags that are defined by using the Serverless SDK.',
      })
    }
  } catch (err) {}

  if (!customTags || !uiData.tags?.length) {
    return null
  }

  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Custom Tags</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <SectionTagsContainer>
          {uiData.tags.map((t, i) => (
            <TagBox tag={t} key={`serverless-sdk-tags-${i}`} />
          ))}
        </SectionTagsContainer>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',
  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '500px',
  width: '100%',
  padding: '0 40px 30px 23px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}))

const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: '0 0 0px 18px',
}))

const SectionTagsContainer = styled(SectionContainer)(({ theme }) => ({
  display: 'inline-block',
  padding: '15px 0 0 0',
  '& > div': {
    float: 'left',
    margin: '0 30px 15px 0',
  },
}))
