import { styled, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useTheme } from '@mui/styles'
import { ChevronRight } from 'lucide-react'
import { EventMessage } from 'common/packages/ActivityStream/components/EventMessage/EventMessage'

/**
 * Section Details
 */
export const EventSection = ({ event, ...props }) => {
  const theme = useTheme()
  const label = event?.tags?.error ? 'Error Details' : 'Warning Details'
  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>{label}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <EventMessage activity={event} theme={theme} />
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',
  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '500px',
  width: '100%',
  padding: '15px 40px 30px 23px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}))
