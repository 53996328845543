import React, { useState, useRef, useEffect, useContext } from 'react'
import { styled, TextField } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Avatar, Box, ClickAwayListener, Popper, Divider } from '@mui/material'
import {
  ArrowRight,
  ArrowLeft,
  RefreshCcw,
  Plus,
  Users,
  Sun,
  Moon,
  Monitor,
  User,
} from 'lucide-react'
import { useKey } from 'react-use'
import { motion } from 'framer-motion'
import { AppContext } from 'app/context/AppContext'
import { createOrganization } from 'util/core.api'
import { formatOrgAndUsername } from 'util/format'
import { Button } from 'common/components/Button'
import { handleApiError } from 'common/utils/handleApiError'

import { upperFirst } from 'lodash'
import { useTheme } from '@mui/styles'
import { colors } from 'theme/colors'

const SidebarAccount = () => {
  const theme = useTheme()
  const createOrgInputRef = useRef(null)
  const { user, userOrgs, activeOrg, useSignOut, refreshTokenNow, changeOrg } =
    useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [view, setView] = useState('menu')
  const anchorRef = useRef(null)
  const [arrowRef, setArrowRef] = useState()
  const location = useLocation()
  useEffect(() => {
    if (view === 'create-org') {
      createOrgInputRef?.current?.focus()
    }
  }, [view])
  /**
   * Handle Closing Account Widget
   */

  const handleClose = () => {
    setOpen(false)
    setView('menu')
  }

  useEffect(() => handleClose(), [location.pathname])
  useKey('Escape', handleClose)

  const handleOrgChange = (org) => {
    changeOrg(org)
    setOpen(false)
  }

  /**
   * Handle Org Creation
   */

  const [orgName, setOrgName] = useState('')
  const [isCreating, setIsCreating] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const handleOrgNameInput = (event) => {
    const value = formatOrgAndUsername(event.currentTarget.value)
    setOrgName(value)
  }

  const createOrg = async (e) => {
    e.preventDefault()

    if (!orgName) {
      setErrorMsg('Please enter an Org name')
      return
    }

    // Always clear error message
    setErrorMsg('')

    // Set loading
    setIsCreating(true)

    /**
     * Create the Org
     */
    try {
      const res = await createOrganization({
        orgName,
      })

      if (res.statusCode) {
        throw res
      }

      // Refresh token so that we have the org we just created
      // in our token's org list
      await refreshTokenNow()

      /**
       * Save the org name you're navigating to to localStorage,
       * so if you land back on the '/' path in the future we can
       * try to put you back here
       */
      changeOrg({
        orgId: res.orgId,
        orgName: res.orgName,
        isOwner: true,
      })
    } catch (error) {
      const errorMessage = handleApiError({
        error,
        defaultMessage: 'Org name is invalid',
      })
      setErrorMsg(errorMessage)
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <Box display="flex" flexDirection="column" mt="auto" alignItems="center">
      <Avatar
        alt={user?.userEmail}
        src={user?.profilePictureUrl}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        ref={anchorRef}
        imgProps={{
          draggable: false,
        }}
        sx={{
          background: 'none',
          color: open ? theme.palette.primary.main : theme.palette.grey.light,
          filter: open ? 'none' : 'grayscale(100%)',
          border: `1px solid ${open ? theme.palette.colors.blackDark : theme.palette.border}`,
          transform: open ? 'scale(1.1)' : '',
          cursor: 'pointer',
          transition: 'all 0.25s ease',
          '&:hover': {
            transform: 'scale(1.1)',
            border: `1px solid ${theme.palette.colors.blackDark}!important`,
            filter: 'grayscale(0%)',
          },
          '&:active': {
            transform: 'scale(0.92)',
            border: `1px solid ${theme.palette.colors.blackDark}!important`,
          },
        }}
      >
        <User style={{ strokeWidth: 1 }} />
      </Avatar>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="right-end"
        disablePortal={false}
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        style={{ zIndex: 10000 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <ContainerPopper
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
          >
            <PopperArrow ref={setArrowRef} />

            {view === 'menu' && (
              <PopperElement>
                <PopperCurrentOrg>
                  <PopperCurrentOrgLabel>Current Org</PopperCurrentOrgLabel>
                  <PopperCurrentOrgName>{activeOrg?.orgName}</PopperCurrentOrgName>
                </PopperCurrentOrg>

                <CustomDivider />

                <PopperList>
                  <PopperListItem onClick={() => setView('switch-org')}>
                    <PopperListItemIcon>
                      <RefreshCcw size="16" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Switch Org</PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />

                  <PopperListItem onClick={() => setView('create-org')}>
                    <PopperListItemIcon>
                      <Plus size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Create New Org</PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />
                  <PopperListItem onClick={() => setView('switch-theme')}>
                    <PopperListItemIcon>
                      <Sun size="16" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>
                      Switch theme ( {upperFirst(theme.palette.savedMode)} )
                    </PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />
                  <PopperListItem onClick={useSignOut}>
                    <PopperListItemIcon>
                      <ArrowRight size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Sign Out</PopperListItemTitle>
                  </PopperListItem>
                </PopperList>
              </PopperElement>
            )}

            {view === 'switch-org' && (
              <PopperElement>
                <PopperBackHeader
                  onClick={() => {
                    setView('menu')
                  }}
                >
                  <PopperBackHeaderArrow>
                    <ArrowLeft size="16" style={{ color: '#000000' }} />
                  </PopperBackHeaderArrow>
                  <PopperBackHeaderText>Back</PopperBackHeaderText>
                </PopperBackHeader>

                <CustomDivider />

                <PopperList style={{ maxHeight: '500px' }}>
                  {(userOrgs || []).map((org) => {
                    return (
                      <PopperListItem
                        key={org.orgName}
                        onClick={() => {
                          handleOrgChange(org)
                        }}
                      >
                        <PopperListItemIcon>
                          <Users size="16" style={{ color: '#000000' }} />
                        </PopperListItemIcon>
                        <PopperListItemTitle>{org.orgName}</PopperListItemTitle>
                      </PopperListItem>
                    )
                  })}
                </PopperList>
              </PopperElement>
            )}
            {view === 'switch-theme' && (
              <PopperElement>
                <PopperBackHeader
                  onClick={() => {
                    setView('menu')
                  }}
                >
                  <PopperBackHeaderArrow>
                    <ArrowLeft size="16" style={{ color: '#000000' }} />
                  </PopperBackHeaderArrow>
                  <PopperBackHeaderText>Back</PopperBackHeaderText>
                </PopperBackHeader>

                <CustomDivider />

                <PopperList style={{ maxHeight: '500px' }}>
                  <PopperListItem
                    onClick={() => {
                      theme.palette.setSavedMode('system')
                      handleClose()
                    }}
                  >
                    <PopperListItemIcon>
                      <Monitor size="16" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>System</PopperListItemTitle>
                  </PopperListItem>
                  <CustomDivider />
                  <PopperListItem
                    onClick={() => {
                      theme.palette.setSavedMode('dark')
                      handleClose()
                    }}
                  >
                    <PopperListItemIcon>
                      <Moon size="16" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Dark</PopperListItemTitle>
                  </PopperListItem>
                  <CustomDivider />
                  <PopperListItem
                    onClick={() => {
                      theme.palette.setSavedMode('light')
                      handleClose()
                    }}
                  >
                    <PopperListItemIcon>
                      <Sun size="16" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Light</PopperListItemTitle>
                  </PopperListItem>
                </PopperList>
              </PopperElement>
            )}

            {view === 'create-org' && (
              <PopperElement>
                <PopperBackHeader
                  onClick={() => {
                    setView('menu')
                  }}
                >
                  <PopperBackHeaderArrow>
                    <ArrowLeft size="16" style={{ color: '#000000' }} />
                  </PopperBackHeaderArrow>
                  <PopperBackHeaderText>Back</PopperBackHeaderText>
                </PopperBackHeader>

                <CustomDivider />

                <PopperCreateOrgForm>
                  <PopperCreateOrgFormLabel>Enter A New Org Name</PopperCreateOrgFormLabel>

                  <form onSubmit={createOrg}>
                    <TextField
                      sx={{
                        marginBottom: 2,
                        '& .MuiInputBase-root.MuiOutlinedInput-root': {
                          borderColor: `${theme.palette.colors.blackDark} !important`,
                          color: theme.palette.colors.blackDark,
                        },
                      }}
                      color="secondary"
                      inputRef={createOrgInputRef}
                      type="text"
                      onChange={handleOrgNameInput}
                      value={orgName}
                    />
                    <Button
                      loading={isCreating}
                      onClick={createOrg}
                      sx={{ width: '100%' }}
                      color={theme.palette.isDarkMode ? 'secondary' : 'primary'}
                      variant="contained"
                    >
                      Create
                    </Button>
                  </form>

                  {errorMsg && <PopperCreateOrgError>{errorMsg}</PopperCreateOrgError>}
                </PopperCreateOrgForm>
              </PopperElement>
            )}
          </ContainerPopper>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}

export { SidebarAccount }

/**
 * Styles
 */
const ContainerPopper = styled(motion.div)(() => ({
  position: 'relative',
  top: '8px',
  left: 0,
  zIndex: 103,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
  height: 'fit-content',
  width: '320px',
  padding: '0 0 0 16px',
}))

const PopperElement = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 'fit-content',
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 20px -2px rgba(0,0,0,0.2), 0px 3px 20px 0px rgba(0,0,0,0.14), 0px 1px 20px 0px rgba(0,0,0,0.12)',
}))

const PopperCurrentOrg = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 'auto',
  width: '100%',
  padding: '18px 0 14px 30px',
  userSelect: 'none',
}))

const PopperCurrentOrgLabel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: '#000000',
  justifyContent: 'flex-start',
  height: 'auto',
  width: '100%',
  fontSize: '12px',
  padding: '0 0 2px 0',
}))

const PopperCurrentOrgName = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#000000',
  height: 'auto',
  width: '100%',
  fontSize: '18px',
}))

const PopperBackHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  width: '100%',
  padding: '10px 0 8px 30px',
  userSelect: 'none',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    background: colors.greyLight,
  },
}))

const PopperBackHeaderArrow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 'auto',
  width: '8%',
}))

const PopperBackHeaderText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  color: '#000000',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 'auto',
  width: '92%',
  fontSize: '12px',
}))

const PopperList = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
}))

const PopperListItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 0 0 30px',
  overflow: 'hidden',
  minHeight: '50px',
  width: '100%',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    background: colors.greyLight,
  },
}))

const PopperListItemIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  width: '13%',
}))

const PopperListItemTitle = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#000000',
  height: '100%',
  width: '87%',
}))

const PopperArrow = styled('div')(() => ({
  position: 'absolute',
  fontSize: 14,
  top: '-15px !important',
  zIndex: 100,
  left: 7,
  height: '8px',
  width: '3px',
  marginBottom: '42px',

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '1em 1em 1em 0',
    borderColor: `transparent #FFFFFF transparent transparent`,
  },
}))

const PopperCreateOrgForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  width: '100%',
  padding: '30px',
  userSelect: 'none',
  fontSize: '16px',
  transition: 'all 0.2s ease',
  boxSizing: 'border-box',
}))

const PopperCreateOrgFormLabel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  width: '100%',
  color: '#000000',
  padding: '0 0 5px 0',
  userSelect: 'none',
  fontSize: '15px',
  boxSizing: 'border-box',
  transition: 'all 0.2s ease',
}))

const PopperCreateOrgError = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  width: '100%',
  padding: '0',
  margin: '10px 0 0 0',
  fontSize: '14px',
  color: '#FD5750',
}))

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.colors.greyMedium,
}))
