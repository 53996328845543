import { createContext, useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { useSnackbar } from 'notistack'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import decode from 'jwt-decode'
import config from 'config'
import {
  listIntegrationsByOrgId,
  getIdentityToken,
  getOrgByName,
  refreshAuthToken,
  sendLoginSessionSuccess,
} from 'util/core.api'
import LoadingPage from '../components/LoadingPage'
import { addMinutes, isAfter, subMinutes } from 'date-fns'
import { helperExtractInitialParams } from 'login/util/helper'
import { useTheme } from '@mui/styles'
import { stringify } from 'query-string'
import { sleep } from 'common/utils/sleep'
import { hasConfiguredIntegration } from '../util/hasConfiguredIntegration'
import {
  defaultExplorerFilterString,
  defaultMetricsFilterString,
} from 'filter/context/FilterContext'

const defaultAuthData = {
  loggedIn: false,
  idToken: '',
  refreshToken: '',
  selectedOrgName: '',
  user: null,
  expiresIn: -1,
  activeOrg: null,
  userOrgs: [],
}

const mappedConnectionTypes = {
  'google-oauth2': 'google',
  'Username-Password-Authentication': 'email',
  github: 'github',
}

export const AppContext = createContext({
  loggedIn: false,
  mode: 'dark',
  isConfigMissing: true,
  login: () => {},
  changeOrg: () => {},
  useSignOut: () => {},
  refreshTokenNow: () => {},
})

export const AppProvider = ({ children }) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const navigate = useNavigate()

  const [firstLoad, setFirstLoad] = useState(true)
  const [supportVisible, showSupport] = useState(false)
  const [supportUnreadMessages, setUnreadSupportMessages] = useState(0)
  const [loadingOrgConfiguration, setLoadingOrgConfiguration] = useState(true)
  const [loadingAuthData, setLoadingAuthData] = useState(true)
  const [authDataValue, setAuthData] = useLocalStorage(config.localStorageKey, defaultAuthData)
  const [isConfigMissing, setIsConfigMissing] = useState(true)
  const authData = authDataValue || defaultAuthData
  const match = matchPath({ path: '/:orgName/*' }, location.pathname)
  const params = match?.params

  /**
   *
   * Set auth context data
   *
   * @param {res} {IdentityTokenResponse}
   * @returns AuthData
   */
  const setAuthContext = useCallback(
    async ({ res, defaultOrgName, isInvite }) => {
      // parse identity token
      const decodedToken = decode(res.idToken)

      // Create structured auth data
      const currentUser = {
        userId: decodedToken.sub,
        userEmail: decodedToken.email,
        profilePictureUrl: decodedToken.profilePictureUrl,
        orgs: decodedToken.orgs,
        superAdmin: !!decodedToken.superAdmin,
      }
      const userOrgs = Object.keys(decodedToken.orgs).reduce(
        (arr, key) => [
          ...arr,
          {
            orgId: key,
            ...decodedToken.orgs[key],
          },
        ],
        []
      )

      // Set the selected org
      let activeOrg =
        authData.activeOrg ||
        userOrgs.find(({ orgName }) => orgName === defaultOrgName) ||
        userOrgs?.[0]
      let selectedOrgName = defaultOrgName || activeOrg?.orgName
      let foundOrg = activeOrg
      // Use org name from path or org name from localstorage
      if ((params?.orgName || defaultOrgName) && params?.orgName !== 'accept-invitation') {
        // Set the selected org
        const defaultName = defaultOrgName || params?.orgName
        foundOrg = userOrgs.find(({ orgName }) => orgName === defaultName)
        if (!foundOrg && isInvite) {
          foundOrg = {
            orgName: defaultName,
            // fake so we can accept an invite without strange errors
            orgId: 'xxxx',
          }
        }
        if (!foundOrg && currentUser?.superAdmin) {
          // Look up org
          const res = await getOrgByName({
            orgName: params.orgName,
          }).catch(() => null)
          if (!res) {
            activeOrg = authData.activeOrg || userOrgs?.[0]
            selectedOrgName = activeOrg?.orgName
          } else {
            activeOrg = res
            foundOrg = res
            selectedOrgName = activeOrg.orgName
          }
        }
        if (foundOrg) {
          activeOrg = foundOrg
          selectedOrgName = foundOrg.orgName
        } else {
          activeOrg = userOrgs[0]
          selectedOrgName = activeOrg.orgName
          foundOrg = activeOrg
        }
      } else {
        // Check that user has access to the selected org
        foundOrg = userOrgs.find(({ orgName }) => orgName === selectedOrgName)
        if (foundOrg) {
          activeOrg = foundOrg
          selectedOrgName = foundOrg.orgName
        } else if (!foundOrg && !currentUser.superAdmin) {
          activeOrg = userOrgs[0]
          selectedOrgName = activeOrg.orgName
        }
      }

      // Save auth data to state
      const newAuthData = {
        loggedIn: true,
        idToken: res.idToken,
        expiresIn: decodedToken?.exp * 1000,
        refreshToken: res.refreshToken,
        selectedOrgName,
        user: currentUser,
        activeOrg: {
          ...activeOrg,
          memberRole: foundOrg?.memberRole || 'contributor',
          isOwner: foundOrg?.memberRole === 'owner',
        },
        userOrgs,
      }
      setAuthData(newAuthData)

      // Return new auth data
      return newAuthData
    },
    [params, authData]
  )
  const getIntegrations = async ({ orgId }) => {
    const res = await listIntegrationsByOrgId({ orgId }).catch(async () => {
      // Retry once incase we are refetching a token
      await sleep(500)
      return listIntegrationsByOrgId({ orgId })
    })
    return res?.integrations || []
  }
  /**
   * Check if org has data in our system
   * If the org does not have data in our system we want to
   * redirect them to the connect page so they can get instructions
   * on how to instrument their apps
   */
  const checkIfOrgIsConfigured = useCallback(
    async ({
      orgId,
      orgName,
      redirectLocation,
      isInvite = false,
      clientQueryParam,
      emailSourceQueryParam,
      clientOriginCommand,
      ignoreInvitation,
    }) => {
      try {
        const isAtAcceptingInvitePath = ignoreInvitation
          ? false
          : ['accept-invitation', ''].includes(match?.params?.orgName)
        // Ignore integration checks if we are logging in with an invite link
        if (isInvite) {
          return navigate(redirectLocation)
        } else if (orgId === 'xxxx') return
        else if (isAtAcceptingInvitePath) return

        const integrations = await getIntegrations({ orgId })
        const isIntegrationReady = hasConfiguredIntegration(integrations)
        const urlOrgName = match?.params?.orgName

        const link =
          redirectLocation ||
          match?.pathname.replace(match?.pathnameBase, `/${orgName}`) + location?.search
        if (isIntegrationReady) {
          const redirectToMetrics =
            !urlOrgName ||
            ['callback', 'password', ''].includes(urlOrgName) ||
            match?.params?.['*'] === 'connect' ||
            (urlOrgName !== orgName && !isAtAcceptingInvitePath)
          const redirectToExplorer = link.includes(`${orgName}/explorer`) && !location?.search

          setIsConfigMissing(false)
          const navigateTo =
            !redirectLocation?.includes(`/${orgName}/`) && redirectToMetrics
              ? `/${orgName}/metrics/awsLambda?${defaultMetricsFilterString}`
              : redirectToExplorer
              ? `/${orgName}/explorer?${defaultExplorerFilterString}`
              : link
          navigate(navigateTo)
        } else {
          const redirectToSettings =
            /\/settings\//.test(redirectLocation) || match?.params?.['*'].startsWith('settings/')

          setIsConfigMissing(true)

          const navigateTo = redirectToSettings
            ? link
            : `/${orgName}/connect?${stringify({
                client: clientQueryParam,
                clientOriginCommand,
                email_source: emailSourceQueryParam,
              })}`
          navigate(navigateTo)
        }
      } catch (error) {
        setIsConfigMissing(true)
        console.error(error)
      } finally {
        setLoadingAuthData(false)
        setLoadingOrgConfiguration(false)
      }
    },
    [setIsConfigMissing, navigate]
  )

  /**
   * Check that the auth context data is set from
   * local storage when the page is reloaded
   */
  useEffect(() => {
    setFirstLoad(false)
    const check = async () => {
      if (loadingAuthData && authData?.loggedIn) {
        const context = await setAuthContext({
          res: {
            idToken: authData.idToken,
            expiresIn: authData.expiresIn,
            refreshToken: authData.refreshToken,
          },
        })
        setLoadingAuthData(false)

        // If we are already logged in we still want to send login success
        // back to the CLI
        const queryParameters = helperExtractInitialParams()

        if (
          queryParameters?.transactionID &&
          queryParameters?.transactionID?.[0] &&
          queryParameters?.client &&
          queryParameters?.client[0] &&
          queryParameters?.client[0].includes('cli')
        ) {
          const transactionId = queryParameters?.transactionID?.[0]
          await sendLoginSessionSuccess({
            transactionId,
          }).catch(() =>
            enqueueSnackbar('Could not login to CLI. Please try again', {
              variant: 'error',
              autoHideDuration: 5000,
            })
          )
        }

        if (context.activeOrg?.orgId && context.activeOrg?.orgName && loadingOrgConfiguration) {
          checkIfOrgIsConfigured({
            orgId: context.activeOrg?.orgId,
            orgName: context.activeOrg?.orgName,
            clientQueryParam: queryParameters?.client,
            emailSourceQueryParam: queryParameters?.email_source,
            clientOriginCommand: queryParameters?.ref?.value,
          })
        }
        return
      }
      setLoadingAuthData(false)
      setLoadingOrgConfiguration(false)
    }
    if (firstLoad) {
      check()
      // Load Hubspot Chat
      if (window.HubSpotConversations) {
        window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
          setUnreadSupportMessages(payload.unreadCount)
        })
        window.HubSpotConversations.widget.load()
      } else {
        window.hsConversationsOnReady = [
          () => {
            window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
              setUnreadSupportMessages(payload.unreadCount)
            })
            window.HubSpotConversations.widget.load({ widgetOpen: false })
          },
        ]
      }
    }
  }, [
    firstLoad,
    loadingAuthData,
    loadingOrgConfiguration,
    authData,
    setAuthContext,
    checkIfOrgIsConfigured,
  ])

  /**
   * Refresh jwt immediately and handle authData
   * updates from other browser tabs
   */
  const refreshTokenNow = useCallback(
    async (params) => {
      const { defaultOrgName } = params || {}
      try {
        // Ensure we still have a token in localStorage
        // If we do not this might mean that another browser
        // tab initiated a logout and we need to do the same here :)
        const localStorageData = localStorage.getItem(config.localStorageKey)
        const authInfo = JSON.parse(localStorageData || '{}')
        if (!authInfo?.idToken) {
          setAuthData(defaultAuthData)
          navigate('/')
          return
        }
        const res = await refreshAuthToken({
          refreshToken: authData?.refreshToken || '',
        })
        setAuthContext({ res, defaultOrgName })
      } catch (error) {
        const localStorageData = localStorage.getItem(config.localStorageKey)
        const authInfo = JSON.parse(localStorageData || '{}')
        if (authInfo?.idToken && authInfo?.refreshToken === authData?.refreshToken) {
          // It is possible the token was updated in another tab but if
          // we tried to update the token and the refresh token hasn't changed then
          // something truly hasn't gone right and we should log them out
          console.error('refreshTokenNow: Unable to refresh token', error)
          setAuthData(defaultAuthData)
          enqueueSnackbar('Your session was expired. Please login again.', {
            variant: 'error',
            autoHideDuration: 5000,
          })
          navigate('/')
        } else if (authInfo?.idToken && authInfo?.refreshToken) {
          // Set the auth in context that may or may not have been set from
          setAuthContext({
            res: {
              idToken: authInfo?.idToken,
              refreshToken: authInfo?.refreshToken,
              expiresIn: authInfo?.expiresIn,
            },
          })
        }
      }
    },
    [authData, setAuthContext]
  )

  /**
   * Check if we need to refresh the token or not
   */
  const tokenCheck = useCallback(
    ({ checkVisibility = false }) => {
      if (checkVisibility && document.visibilityState !== 'visible') return
      if (
        authData.expiresIn !== -1 &&
        isAfter(addMinutes(new Date(), 1), new Date(authData.expiresIn))
      ) {
        refreshTokenNow()
      }
    },
    [authData, params, refreshTokenNow]
  )

  /**
   * Force the app to validate the token expiration when we re-visit the browser tab
   */
  useEffect(() => {
    const boundTokenCheck = tokenCheck.bind(this, { checkVisibility: true })
    window.addEventListener('online', boundTokenCheck)
    document.addEventListener('visibilitychange', boundTokenCheck)

    return () => {
      window.removeEventListener('online', boundTokenCheck)
      document.removeEventListener('visibilitychange', boundTokenCheck)
    }
  }, [authData, tokenCheck])

  /**
   * Timer to refresh the token 1 minute before it expires.
   * Note: This does not fire if the computer goes to sleep or
   *       if the window is not active so this is why the useEffect
   *       above is useful.
   */
  useEffect(() => {
    let timeout
    if (authData?.expiresIn && authData?.expiresIn !== -1) {
      timeout = setTimeout(() => {
        refreshTokenNow()
      }, subMinutes(new Date(authData?.expiresIn), 3).getTime() - new Date().getTime())
    }
    return () => clearTimeout(timeout)
  }, [authData?.expiresIn, authData?.refreshToken, refreshTokenNow, setAuthContext])

  /**
   * Sign out of app
   */
  const useSignOut = useCallback(() => {
    setAuthData(defaultAuthData)
    navigate('/')
  }, [setAuthData, navigate])

  /**
   * Login to app and set auth context data
   */
  const login = useCallback(
    async ({ code, state }) => {
      try {
        if (code.trim() === '' || state.trim() === '') {
          throw new Error('Missing query params')
        }
        // get identity token
        const stateData = JSON.parse(state)
        const method = mappedConnectionTypes[stateData?.connection] || 'email'

        const res = await getIdentityToken({
          code,
          redirectUri: window.location.origin,
          queryParameters: {
            ...stateData.queryParameters,
            client: stateData?.queryParameters?.client
              ? [stateData?.queryParameters?.client]
              : ['web'],
            method: [method],
            ref: stateData?.queryParameters?.ref,
          },
        })
        let redirectLocation
        let defaultOrgName
        let isInvite = false
        if (stateData?.from) {
          redirectLocation = `${stateData?.from?.pathname}${stateData?.from?.search}`
          const nameFromPath = (stateData?.from?.pathname?.split('/') || [])[1]
          if (nameFromPath && nameFromPath !== 'accept-invitation') {
            defaultOrgName = nameFromPath
          }
          isInvite = redirectLocation?.includes('/accept-invitation')
        }
        const context = await setAuthContext({
          res,
          defaultOrgName,
          isInvite,
        })

        // Post login success to CLI if we have a transactionID in the query params
        if (
          stateData?.queryParameters?.transactionID &&
          stateData?.queryParameters?.transactionID?.[0]
        ) {
          const transactionId = stateData?.queryParameters?.transactionID?.[0]
          await sendLoginSessionSuccess({
            transactionId,
          }).catch(() =>
            enqueueSnackbar('Could not login to CLI. Please try again', {
              variant: 'error',
              autoHideDuration: 5000,
            })
          )
        }

        await checkIfOrgIsConfigured({
          orgId: context.activeOrg.orgId,
          orgName: context.activeOrg.orgName,
          redirectLocation,
          isInvite,
          clientQueryParam: stateData?.queryParameters?.client,
          emailSourceQueryParam: stateData?.queryParameters?.email_source,
          clientOriginCommand: stateData?.queryParameters?.clientOriginCommand,
        })
      } catch (error) {
        // Show snackbar, reset auth data, and move to login page
        setAuthData(defaultAuthData)
        enqueueSnackbar('Failed to login. Please try again', {
          variant: 'error',
          autoHideDuration: 5000,
        })
        setTimeout(() => {
          navigate('/', { replace: true })
        }, 6000)
      }
    },
    [navigate, setAuthContext, setAuthData, checkIfOrgIsConfigured]
  )

  /**
   * Switch to a different org
   * @param org Org
   */
  const changeOrg = async (org, ignoreInvitation) => {
    setLoadingOrgConfiguration(true)
    const strData = localStorage.getItem(config.localStorageKey)
    const data = JSON.parse(strData || '{}')
    // Set the selected org
    let activeOrg = org
    let selectedOrgName = org?.orgName
    let foundOrg
    // Check that user has access to the selected org
    if (!data?.user?.superAdmin) {
      foundOrg = data?.userOrgs.find(({ orgName }) => orgName === selectedOrgName)
      if (!foundOrg) {
        activeOrg = data?.userOrgs[0]
        selectedOrgName = activeOrg.orgName
      }
    }

    await setAuthData({
      ...data,
      selectedOrgName,
      activeOrg: {
        ...activeOrg,
        memberRole: foundOrg?.memberRole || 'contributor',
        isOwner: foundOrg?.memberRole === 'owner' || data?.user?.superAdmin,
      },
    })
    checkIfOrgIsConfigured({
      orgId: activeOrg.orgId,
      orgName: activeOrg.orgName,
      ignoreInvitation,
      clientQueryParam: 'web',
    })
  }

  const toggleSupport = () => {
    const el = document.getElementById('support')
    if (supportVisible) {
      showSupport(false)
      el.setAttribute('style', 'display:none')
    } else {
      window?.HubSpotConversations?.widget.open()
      showSupport(true)
      el.setAttribute('style', 'display:block')
    }
  }

  const appLoading = loadingAuthData || loadingOrgConfiguration

  return (
    <AppContext.Provider
      value={{
        mode,
        idToken: authData.idToken,
        token: authData.idToken,
        user: authData.user,
        activeOrg: authData.activeOrg,
        userOrgs: authData.userOrgs,
        isConfigMissing,
        loggedIn: authData.loggedIn,
        supportVisible,
        supportUnreadMessages,
        login,
        useSignOut,
        refreshTokenNow,
        changeOrg,
        toggleSupport,
        setIsConfigMissing,
      }}
    >
      {!appLoading ? children : <LoadingPage type="spin3D" />}
    </AppContext.Provider>
  )
}
