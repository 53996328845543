import { useState } from 'react'
import { Box, ClickAwayListener, Divider, Tooltip, Typography } from '@mui/material'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { NavBarItemDropdown, NavBarItemDropdownInner } from 'common/components/NavBarItem'
import { Button } from '../../../common/components/Button'
import { every, isEqual, isNil, isUndefined, omitBy, values } from 'lodash'
import { ItemsCounter } from 'common/components/ItemsCounter'
import { useTheme } from '@mui/styles'
import { useIntegrationFiltersQueries } from '../../hooks/useIntegrationFiltersQueries'
import { FilterSearchDropdown } from '../../../common/components/FilterSearchDropdown'
import { integrationFilters } from 'filter/context/FilterContext'

export const ResourcesFilterDropdown = () => {
  const theme = useTheme()
  const { globalFilters, setGlobalFilters } = useIntegrationFiltersQueries()
  const [isOpen, setIsOpen] = useState(false)
  const initialFilters = integrationFilters.reduce(
    (acc, { name }) => ({ ...acc, [name]: undefined }),
    {}
  )
  const [selectedFilters, setSelectedFilters] = useState(globalFilters)

  const saveFilters = () => {
    /**
     * Clear filter if all values are undefined
     */
    if (every(selectedFilters, (filter) => isUndefined(filter))) {
      setGlobalFilters(initialFilters)
    } else {
      setGlobalFilters(selectedFilters)
    }
    /**
     * Close filters menu
     */
    setIsOpen(false)
  }
  /**
   * Saved filters count
   */
  const count = values(omitBy(globalFilters, isNil))?.length

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Tooltip title="Filter Resources">
        <Box>
          <Button
            variant="outlined"
            endIcon={count ? <ItemsCounter count={count} /> : <DropdownIcon isOpen={isOpen} />}
            onClick={() => setIsOpen(true)}
          >
            Filter
          </Button>
        </Box>
      </Tooltip>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <NavBarItemDropdown
            width="380px"
            top={70}
            initial={{ height: '0' }}
            animate={{ height: 'auto', transition: { duration: 0.35, ease: 'easeOut' } }}
          >
            <NavBarItemDropdownInner sx={{ padding: '0 30px 30px 30px' }}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: theme.palette.secondary.main,
                  paddingTop: '30px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h3">Filters</Typography>
                  <Box>
                    {!isEqual(initialFilters, selectedFilters) ? (
                      <Button
                        color="secondary"
                        size="small"
                        sx={{ margin: '0' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedFilters(initialFilters)
                        }}
                      >
                        Clear Filters
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ margin: '0 0 0 0' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpen(false)
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="primary"
                      onClick={saveFilters}
                      sx={{ marginLeft: '16px ' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    margin: '15px 0',
                  }}
                />
              </Box>

              {integrationFilters.map((filter) => (
                <FilterSearchDropdown
                  key={`integration-resources-filter-${filter.name}`}
                  filter={filter}
                  selectedValues={
                    selectedFilters[filter.name] ? [selectedFilters[filter.name]] : []
                  }
                  allowMulti={false}
                  onChange={(option) => {
                    setSelectedFilters((prev) => ({ ...prev, [filter.name]: option?.value }))
                  }}
                />
              ))}
            </NavBarItemDropdownInner>
          </NavBarItemDropdown>
        </ClickAwayListener>
      )}
    </Box>
  )
}
