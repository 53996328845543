import { useState } from 'react'
import {
  listIntegrationsByOrgId,
  getIntegrationById,
  searchInventory,
  instrumentAWSFunctions,
  resourceCompatibility,
} from 'util/core.api'
import chunk from 'lodash.chunk'
import { useIsInstrumenting } from './useIsInstrumenting'

const DEFAULT_SIZE = 50

export const hasDevEnvironmentTag =
  (compatibility = ['nodejs14.x', 'nodejs16.x', 'nodejs18.x']) =>
  ({ accountId, from = 0, size = 50 }) => ({
    from,
    size,
    query: {
      bool: {
        must: [
          {
            match: { type: 'resource_aws_lambda' },
          },
          {
            match: { tag_account_id: accountId },
          },
          {
            match: { 'tag_environment.keyword': 'dev' },
          },
          {
            terms: {
              'aws_lambda_runtime.keyword': compatibility,
            },
          },
        ],
      },
    },
    sort: [
      {
        'aws_lambda_name.keyword': { order: 'asc' },
      },
    ],
  })

export const hasDevModeFunctionFilter = ({ accountId, from = 0, size = 50 }) => ({
  from,
  size,
  query: {
    bool: {
      must: [
        {
          match: { type: 'resource_aws_lambda' },
        },
        {
          match: { tag_account_id: accountId },
        },
        {
          match: {
            instrument_mode: 'dev',
          },
        },
      ],
    },
  },
  sort: [
    {
      'aws_lambda_name.keyword': { order: 'asc' },
    },
  ],
})

export const canEnableTraces =
  (compatibility = ['nodejs14.x', 'nodejs16.x', 'nodejs18.x', 'go1.x', 'python3.8', 'python3.9']) =>
  ({ accountId, from = 0, size = 50 }) => ({
    from,
    size,
    query: {
      bool: {
        must: [
          {
            match: {
              type: 'resource_aws_lambda',
            },
          },
          {
            match: {
              tag_account_id: accountId,
            },
          },
          {
            match: {
              instrument_mode: 'none',
            },
          },
          {
            terms: {
              'aws_lambda_runtime.keyword': compatibility,
            },
          },
        ],
      },
    },
    sort: [
      {
        'aws_lambda_name.keyword': {
          order: 'asc',
        },
      },
    ],
  })

export const hasTracesEnabled = ({ accountId, from = 0, size = 50, excludeDevMode = true }) => ({
  from,
  size,
  query: {
    bool: {
      must: [
        {
          match: {
            type: 'resource_aws_lambda',
          },
        },
        {
          match: {
            tag_account_id: accountId,
          },
        },
        {
          terms: {
            instrument_mode: excludeDevMode ? ['prod'] : ['prod', 'dev'],
          },
        },
      ],
    },
  },
  sort: [
    {
      'aws_lambda_name.keyword': {
        order: 'asc',
      },
    },
  ],
})

const instrumentationMap = {
  dev: {
    targetInstrumentations: {
      mode: 'dev',
    },
    checkForExistingInstrumentation: hasDevModeFunctionFilter,
    getAllRelatedInventory: hasDevEnvironmentTag,
  },
  prod: {
    targetInstrumentations: {
      mode: 'prod',
    },
    checkForExistingInstrumentation: hasTracesEnabled,
    getAllRelatedInventory: canEnableTraces,
  },
}

export const useEnableBulkInstrumentation = ({
  orgId,
  targetIntegration,
  enableInstrumentationType = 'dev',
}) => {
  const { instrumenting } = useIsInstrumenting({
    specificModes: [enableInstrumentationType],
  })
  const [loadingFunctionCount, setLoadingFunctionCount] = useState(true)
  const [compatibleFunctionCount, setCompatibleFunctionCount] = useState(0)
  const [canShowWelcome, setCanShowWelcome] = useState(false)
  const [resources, setResources] = useState([])
  const [doesNotHaveInstrumentation, setDoesNotHaveInstrumentation] = useState(false)
  const [waitingForInstrumentation, setWaitingForInstrumentation] = useState(false)
  const [instrumentationProgress, setInstrumentationProgress] = useState({
    instrumented: 0,
    total: 0,
  })

  /**
   * Fetches all compatible dev mode functions
   *
   * @param {integrations, fetchAllResources} param If fetchAllResources is set to true then we will paginate through the inventory to build the resources list
   * @returns { hits: [], total: 0 }
   */
  const discoverInventory = async ({
    integrations,
    fetchAllResources,
    inventoryFunction,
    excludeDevMode = true,
  } = {}) => {
    const integrationHitsResult = await Promise.all(
      integrations.map(({ vendorAccount: accountId }) => {
        const findInventory = async (functionList, page) => {
          const { hits, total } = await searchInventory({
            orgId,
            body: inventoryFunction({
              accountId,
              from: page * DEFAULT_SIZE,
              size: DEFAULT_SIZE,
              excludeDevMode,
            }),
          })
          const newFunctionList = {
            total: functionList.total === 0 ? total : functionList.total,
            hits: [
              ...functionList.hits,
              ...hits.map(({ id, tag_environment, tag_namespace, aws_lambda_name }) => ({
                functionName: aws_lambda_name,
                instrumentations: {
                  mode: instrumentationMap[enableInstrumentationType].targetInstrumentations?.mode,
                },
                resourceKey: id,
                updateFields: {
                  namespace: tag_namespace,
                  environment: tag_environment,
                },
                selected: true,
              })),
            ],
          }
          if (hits.length === 0 || !fetchAllResources) return newFunctionList
          return findInventory(newFunctionList, page + 1)
        }
        return findInventory({ total: 0, hits: [] }, 0)
      })
    )

    const combinedIntegrationResults = integrationHitsResult.reduce(
      (obj, account) => ({
        hits: [...obj.hits, ...(account?.hits || [])],
        total: obj.total + account.total,
      }),
      { hits: [], total: 0 }
    )

    if (fetchAllResources) {
      setResources(combinedIntegrationResults.hits)
    }

    return combinedIntegrationResults
  }

  const getIntegrationList = async () => {
    if (targetIntegration) {
      const integration = await getIntegrationById({
        integrationId: targetIntegration,
      })
      return [integration]
    } else {
      const { integrations } = await listIntegrationsByOrgId({
        orgId,
      })
      return integrations
    }
  }

  /**
   * Get alls the integrations for a given org
   *
   * @returns
   */
  const checkForInstrumentation = async () => {
    const integrations = await getIntegrationList()
    return {
      integrations,
      allIntegrationsResult: await discoverInventory({
        integrations,
        fetchAllResources: false,
        inventoryFunction:
          instrumentationMap[enableInstrumentationType].checkForExistingInstrumentation,
      }),
    }
  }

  /**
   * This enables logs, tracing, and dev mode on all specified resources
   *
   * @param {resources} param
   */
  const enableTargetFunctions = async ({ incomingResources } = {}) => {
    const focusedResources = (incomingResources || resources)
      .filter(({ selected }) => selected)
      .map(({ functionName, selected, ...rest }) => rest)
    setWaitingForInstrumentation(true)
    const chunks = chunk(focusedResources, 50)
    let success = true
    for (const chunkedResources of chunks) {
      const { success: s } = await instrumentAWSFunctions({
        orgId,
        resources: chunkedResources,
      })
      if (success) success = s
    }

    if (success) {
      const integrations = await getIntegrationList()
      let errorCount = 0
      const wait = () => new Promise((resolve) => setTimeout(resolve, 1000))
      const waitForInstrumentation = async () => {
        await wait()
        try {
          const { total: functionCount } = await discoverInventory({
            integrations,
            fetchAllResources: false,
            inventoryFunction:
              instrumentationMap[enableInstrumentationType].checkForExistingInstrumentation,
          })
          setInstrumentationProgress({
            instrumented: functionCount,
            total: focusedResources.length,
          })
          if (functionCount === focusedResources.length) return
          return waitForInstrumentation()
        } catch (error) {
          errorCount += 1
          if (errorCount === 10) {
            setInstrumentationProgress((c) => ({
              ...c,
              error: 'We are having trouble checking the stats of the functions.',
            }))
            return
          }
          return waitForInstrumentation()
        }
      }
      await waitForInstrumentation()
    }
    setWaitingForInstrumentation(false)
    setDoesNotHaveInstrumentation(false)
  }

  /**
   * Check if the customer has any functions with dev mode enabled.
   *
   * If we do not find any integrations with dev mode enabled and they do not have dev environment
   * tag we will redirect to the integration page
   *
   * If we do not find any integrations with dev mode enabled and they do have dev environment tag
   * then we will show a modal that enables dev mode on all functions.
   *
   * @param {forceDiscovery} param If forceDiscovery is set to true then we will paginate through the inventory to build the resources list
   */
  const beginCheckForFunctions = async (forceDiscovery = false) => {
    const {
      integrations,
      allIntegrationsResult: { total: functionCount },
    } = await checkForInstrumentation()

    const compatibility = await resourceCompatibility()

    const { total: envTotal } = await discoverInventory({
      integrations,
      fetchAllResources: false,
      inventoryFunction: instrumentationMap[enableInstrumentationType].getAllRelatedInventory(
        compatibility?.mode?.[enableInstrumentationType]?.runtimes
      ),
    })

    setCompatibleFunctionCount(envTotal)
    setInstrumentationProgress({
      total: envTotal,
      instrumented: 0,
    })

    const instrumentationCheck =
      enableInstrumentationType === 'prod'
        ? functionCount < envTotal || forceDiscovery
        : functionCount === 0 && envTotal > 0
    const doesNotHaveInstrumentation =
      enableInstrumentationType === 'prod' ? functionCount < envTotal : functionCount === 0

    if (instrumentationCheck) {
      setDoesNotHaveInstrumentation(true)

      const { hits: newResources } = await discoverInventory({
        integrations,
        fetchAllResources: true,
        inventoryFunction: instrumentationMap[enableInstrumentationType].getAllRelatedInventory(
          compatibility?.mode?.[enableInstrumentationType]?.runtimes
        ),
      })

      setLoadingFunctionCount(false)
      return {
        resources: newResources,
        compatibleFunctionCount: envTotal,
        doesNotHaveInstrumentation,
      }
    } else if (functionCount === 0 && envTotal === 0) {
      setDoesNotHaveInstrumentation(false)
      setCanShowWelcome(true)
    }
    setCompatibleFunctionCount(functionCount)
    setLoadingFunctionCount(false)
    return {
      resources: [],
      compatibleFunctionCount: functionCount,
      doesNotHaveInstrumentation: functionCount === 0,
    }
  }

  const hasTracesIncludingDevMode = async () => {
    const integrations = await getIntegrationList()
    const { total } = await discoverInventory({
      integrations,
      fetchAllResources: false,
      inventoryFunction: hasTracesEnabled,
      excludeDevMode: false,
    })
    return total === 0
  }

  return {
    currentlyInstrumenting: instrumenting,
    canShowWelcome,
    doesNotHaveInstrumentation,
    waitingForInstrumentation,
    instrumentationProgress,
    loadingFunctionCount,
    compatibleFunctionCount,

    // These are there so downstream components can set
    // the resources we want to toggle
    allResources: resources,
    selectedFunctionCount: resources.filter(({ selected }) => selected).length,
    setResources: (newResources) => {
      setResources(newResources)
      setInstrumentationProgress((c) => ({
        ...c,
        total: newResources.filter(({ selected }) => selected).length,
      }))
    },

    enableTargetFunctions,
    setDoesNotHaveInstrumentation,
    setCanShowWelcome,
    beginCheckForFunctions,
    hasTracesIncludingDevMode,
  }
}
