import { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { AppContext } from 'app/context/AppContext'
import LoadingPage from 'app/components/LoadingPage'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export default function Callback() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { login } = useContext(AppContext)
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    const state = urlParams.get('state')
    if (code && state) {
      login({
        code,
        state,
      })
    } else if (!code) {
      const description = urlParams.get('error_description') || 'Failed to login. Please Try agin.'
      enqueueSnackbar(description, { variant: 'error', autoHideDuration: 6000 })
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }, [])
  return (
    <Box width="100%" height="100%">
      <LoadingPage type="spin3D" />
    </Box>
  )
}
