import { styled, Box, lighten, Typography, Tooltip } from '@mui/material'
import { Button } from 'common/components/Button'
import { IconAws } from 'common/icons/IconAws'
import { format, isValid } from 'date-fns'
import { useTheme } from '@mui/styles'
import { upperFirst } from 'lodash'
import { isValidElement } from 'react'
import { Info } from 'lucide-react'

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.mode === 'light' ? '#000' : lighten('#fff', 0.3)}`,
  borderRadius: '4px',
  padding: '3px',
  height: '37px',
  width: '37px',
}))

export const IntegrationRow = ({
  integration = {},
  removeStateLoading,
  setIntegrationToRemove,
  openEditDialog,
  isDialog = false,
}) => {
  const { integrationId, vendor, alias, vendorAccount, timeUpdated } = integration
  const modifiedDate = new Date(timeUpdated)

  const theme = useTheme()

  return (
    <Box
      key={integrationId}
      display="grid"
      gridTemplateColumns={!isDialog ? '1fr 1fr repeat(4, 1fr)' : 'repeat(3, 1fr)'}
      gap="20px"
      width="100%"
      padding="15px"
      borderRadius="4px"
      border={`1px solid ${theme.palette.border.main}`}
      mb={2}
      sx={{
        transition: 'all ease 0.25s',
        '&:hover': {
          border: `1px solid ${theme.palette.border.light}`,
        },
      }}
    >
      {!isDialog && (
        <Box display="flex" gap="30px" alignItems="center">
          {vendorIconMap[vendor] || vendorIconMap.aws}
          <Title>{`${vendor.toUpperCase()}`}</Title>
        </Box>
      )}
      <TagSection title="Account Name" value={alias || '-'} />
      <TagSection title="Account" value={vendorAccount} />
      {!isDialog && (
        <TagSection
          title="Last Updated"
          value={isValid(modifiedDate) && format(modifiedDate, 'MMM dd, yyyy hh:mmaa')}
        />
      )}
      {!isDialog && (
        <TagSection
          title="Status"
          value={
            integration?.syncStatus === 'incomplete' ? (
              <Tooltip title={integration?.lastError}>
                <Typography
                  variant="textSecondary"
                  color="error.main"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Info size={14} style={{ marginRight: 5 }} />
                  {upperFirst(integration?.syncStatus)}
                </Typography>
              </Tooltip>
            ) : (
              upperFirst(integration?.syncStatus) || '-'
            )
          }
          valueColor={integration?.syncStatus === 'incomplete' && theme.palette.error.main}
        />
      )}

      {!isDialog && (
        <Box display="flex" gap="40px" alignItems="center" justifyContent="flex-end">
          <Button
            variant="text"
            size="small"
            sx={{
              minWidth: 70,
            }}
            disabled={
              integration?.status === 'settingup' ||
              integration?.syncStatus === 'running' ||
              integration?.syncStatus === 'pending'
            }
            loading={removeStateLoading?.[integrationId] || integration?.syncStatus === 'removing'}
            onClick={() =>
              setIntegrationToRemove({
                integrationId,
                alias,
                modified: modifiedDate,
                vendorAccount,
                vendor,
              })
            }
          >
            Remove
          </Button>
          <Button
            onClick={() => openEditDialog({ integrationId, integrationType: vendor })}
            sx={{ minWidth: '65px' }}
            color="secondary"
            disabled={removeStateLoading?.[integrationId] || integration?.syncStatus === 'removing'}
          >
            Edit
          </Button>
        </Box>
      )}
    </Box>
  )
}
const vendorIconMap = {
  aws: (
    <IconBox>
      <IconAws />
    </IconBox>
  ),
}

const TagSection = ({ title, value, valueColor }) => {
  return (
    <Box minWidth="120px">
      <TagTitle color="text.secondary">{title}</TagTitle>
      {isValidElement(value) ? value : <TagValue color={valueColor}>{value}</TagValue>}
    </Box>
  )
}

const TagTitle = styled(Typography)({
  fontSize: '11px',
})

const TagValue = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  height: '25px',
})

const Title = styled(Typography)({
  fontSize: '12px',
})
