import React from 'react'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import Animator from 'common/components/Animator'
import AnimationData from 'common/animations/data.json'
import AnimationDataLight from 'common/animations/data-white.json'
import { Loading } from 'common/components/Loading'
import { useTheme } from '@mui/styles'

export const LoadingPage = ({ type = 'spinCircle' }) => {
  const {
    palette: { mode },
  } = useTheme()
  if (type === 'spin3D') {
    return renderSpin3D({
      mode,
    })
  }

  return renderSpinCircle()
}

export default LoadingPage

const renderSpinCircle = () => {
  return (
    <ContainerPage>
      <Content>
        <Loading />
      </Content>
    </ContainerPage>
  )
}

/**
 * Renders a spinning element in 3d
 */
const renderSpin3D = ({ mode }) => {
  const animationScenes = {
    // Lottie animation
    width: '250px',
    height: 'auto',
    data: mode === 'dark' ? AnimationData : AnimationDataLight,
    scene: 'spin',
    scenes: {
      spin: { frames: [0, 52], loop: true },
    },
  }

  return (
    <ContainerPage mode={mode}>
      <Content
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 0.8, opacity: 1 }}
        transition={{ duration: 0.35 }}
      >
        <Animator
          height={animationScenes.height}
          width={animationScenes.width}
          data={animationScenes.data}
          scene={animationScenes.scene}
          scenes={animationScenes.scenes}
        />
      </Content>
    </ContainerPage>
  )
}

/**
 * Styles
 */
const ContainerPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  width: '100%',
  padding: '0',
  overflow: 'hidden',
  background: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '6px',
}))

const Content = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-4%',
  padding: '0',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
}))
