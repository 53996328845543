import { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  useQueryParams,
  BooleanParam,
  StringParam,
  ArrayParam,
  NumericObjectParam,
  NumberParam,
  JsonParam,
} from 'use-query-params'
import cloneDeep from 'lodash.clonedeep'
import { getTimeFrame } from 'filter/util/time'
import { AppContext } from 'app/context/AppContext'
import { listIntegrationsByOrgId } from 'util/core.api'
import { durationIntervals } from '../util/duration'
import { isEmpty, isEqual, keys } from 'lodash'
import { useLocation } from 'react-router-dom'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'

/**
 * Filter Context
 *
 * This Context controls all global and Scope-specific Filters throughout Console and does the following:
 * - Holds the list of all possible all global and Scope-specific Filters.
 * - Exposes only Filters relevant to the current Scope & Page the user is viewing.
 * - Exposes easy ways to get and set all Filter Values.
 * - Uses query params as state (exclusively).  It readys query params on every update and repopulates the state.  Setting the filters simply updates the query params to trigger an update.
 * - Offers a minimal, universal type system which maps Filters to UI elements and URL query param types.
 * - Auto-updates the query parameters in the URL upon changing the Filters.
 * - Auto-maps the Filters to OTEL Tags for the Query API.
 */

export const defaultMetricsFilterString = 'globalScope=awsLambda&globalTimeFrame=24h'
export const defaultExplorerFilterString =
  'explorerSubScope=invocations&globalScope=awsLambda&globalTimeFrame=24h'

/**
 * Defaults & utility functions
 */

export const timeFrameDefaults = [
  {
    value: '15m',
    cy: 'metrics-timeframe-select-15m',
    label: 'Last 15 Minutes',
  },
  {
    value: '1h',
    cy: 'metrics-timeframe-select-1h',
    label: 'Last 1 Hour',
  },
  {
    value: '24h',
    cy: 'metrics-timeframe-select-24h',
    label: 'Last 24 Hours',
  },
  {
    value: '7d',
    cy: 'metrics-timeframe-select-7d',
    label: 'Last 7 Days',
  },
  {
    value: '30d',
    cy: 'metrics-timeframe-select-30d',
    label: 'Last 30 Days',
  },
]

export const eventsOptions = [
  {
    id: 'ERROR_TYPE_UNCAUGHT',
    value: 'ERROR_TYPE_UNCAUGHT',
    label: 'Uncaught Error',
    isUncaughtError: true,
  },
  {
    id: 'ERROR_TYPE_CAUGHT_USER',
    value: 'ERROR_TYPE_CAUGHT_USER',
    label: 'Caught Error',
    isCaughtError: true,
  },
  { id: 'WARNING_TYPE_USER', value: 'WARNING_TYPE_USER', label: 'Warning' },
  {
    id: 'ERROR_TYPE_CAUGHT_SDK_USER',
    value: 'ERROR_TYPE_CAUGHT_SDK_USER',
    label: 'SDK User Error',
    isCaughtError: true,
  },
  {
    id: 'ERROR_TYPE_CAUGHT_SDK_INTERNAL',
    value: 'ERROR_TYPE_CAUGHT_SDK_INTERNAL',
    label: 'SDK Internal Error',
    isCaughtError: true,
  },
]

/**
 * Integration page filters.
 * - "label" is the way the Filter should be presented in the UI.
 * - "name" is the name of the Filter, which is used in the URL query parameters to store state.
 * - "type" is a minimal type-system we created to help present type-specific UI elements to handle the data.
 * - "queryKey" is the key returned from the search query. used to get the value.
 * - "optionLabel" a function that returns label value. In case we want to show something different.
 * - "searchable" determines if there should be a search input.
 */
export const integrationFilters = [
  {
    label: 'Environment',
    name: 'tag_environment',
    queryKey: 'environment',
    type: 'string',
    searchable: true,
  },
  {
    label: 'Namespace',
    name: 'tag_namespace',
    queryKey: 'namespace',
    type: 'string',
    searchable: true,
  },
  {
    label: 'Region',
    name: 'tag_region',
    queryKey: 'region',
    type: 'string',
    searchable: true,
  },
  {
    label: 'Mode',
    name: 'instrument_mode',
    optionLabelKey: 'instrument_mode',
    type: 'string',
    searchable: false,
    options: [
      { id: 'none', value: 'none', label: 'None' },
      { id: 'dev', value: 'dev', label: 'Dev' },
      { id: 'prod', value: 'prod', label: 'Prod' },
    ],
  },
]

export const globalScopes = [
  {
    name: 'awsLambda',
    displayName: 'AWS Lambda',
  },
]

/**
 * The Filter list.
 * This is a list of all global and Scope-specific Filters.
 * - "filter" is the name of the Filter, which is used in the URL query parameters to store state.
 * - "label" is the way the Filter should be presented in the UI.
 * - "type" is a minimal type-system we created to help present type-specific UI elements to handle the data.
 * - "queryApiTagName" is the OTEL Attribute Tag that the Filter corresponds to and must use in the Query API Request.
 */

const filterList = {}

// Global
filterList.globalScope = {
  filter: 'globalScope',
  label: 'Scope',
  type: 'string',
}
filterList.globalTimeFrame = {
  filter: 'globalTimeFrame',
  label: 'Timeframe',
  type: 'timeframe',
}

filterList.globalCloudAccountId = {
  filter: 'globalCloudAccountId',
  label: 'Accounts',
  name: 'tag_account_id',
  queryKey: 'account_id',
  type: 'array',
  queryApiTagName: 'accountId',
  searchable: true,
  fetchAlias: async ({ orgId }) => {
    try {
      const { integrations } = await listIntegrationsByOrgId({ orgId })

      return integrations.reduce(
        (obj, { alias, vendorAccount }) => ({
          ...obj,
          [vendorAccount]: alias || vendorAccount,
        }),
        {}
      )
    } catch (error) {
      console.error(error)
    }
    return {}
  },
}
filterList.globalRegions = {
  filter: 'globalRegions',
  name: 'tag_region',
  queryKey: 'region',
  label: 'Regions',
  type: 'array',
  queryApiTagName: 'region',
  searchable: true,
}
filterList.globalEnvironments = {
  filter: 'globalEnvironments',
  name: 'tag_environment',
  queryKey: 'environment',
  label: 'Environments',
  type: 'array',
  queryApiTagName: 'environment',
  searchable: true,
}
filterList.globalNamespace = {
  filter: 'globalNamespace',
  name: 'tag_namespace',
  queryKey: 'namespace',
  label: 'Namespaces',
  type: 'array',
  queryApiTagName: 'namespace',
  searchable: true,
}

// AWS Lambda
filterList.awsLambdaFunctionNames = {
  filter: 'awsLambdaFunctionNames',
  label: 'Resources',
  name: 'resource_aws_lambda', // To use in opensearch query
  queryKey: 'aws_lambda_name', // To get label and value and sort key
  type: 'array',
  queryApiTagName: 'functionName',
  icon: <IconAwsLambda size={10} />,
  searchable: true,
}
filterList.awsLambdaEvents = {
  filter: 'awsLambdaEvents',
  label: 'Events (Errors & Warnings)',
  name: 'events',
  queryKey: 'value',
  type: 'array',
  queryApiTagName: 'events',
  searchable: false,
  options: eventsOptions,
}
filterList.awsLambdaColdStart = {
  filter: 'awsLambdaColdStart',
  label: 'Cold Start',
  name: 'coldStart',
  queryKey: 'value',
  type: 'boolean',
  queryApiTagName: 'coldStart',
  searchable: false,
}
filterList.awsLambdaDuration = {
  filter: 'awsLambdaDuration',
  label: 'Duration',
  name: 'duration',
  queryKey: 'duration',
  type: 'minmax',
  queryApiTagName: 'duration',
  searchable: false,
}
filterList.awsLambdaCustomTags = {
  filter: 'awsLambdaCustomTags',
  label: 'Custom Tags',
  name: 'customTags',
  queryKey: 'customTags',
  type: 'customTags',
  queryApiTagName: 'customTags',
  searchable: false,
}

// Explorer
// We want to save this in the URL so that is why it is added here :)
// I may end up moving this to a route later but I think it will be easier to star
// as just another filter
filterList.explorerSubScope = {
  filter: 'explorerSubScope',
  label: 'Sub Scope',
  type: 'string',
}
filterList.explorerTraceId = {
  filter: 'explorerTraceId',
  label: 'Trace ID',
  type: 'string',
}

filterList.explorerTraceSpanId = {
  filter: 'explorerTraceSpanId',
  label: 'Trace Span ID',
  type: 'string',
}
filterList.explorerTraceTime = {
  filter: 'explorerTraceTime',
  label: 'Trace Timestamp',
  type: 'string',
}

// Dev Mode
filterList.devModeFunctionName = {
  filter: 'devModeFunctionName',
  label: 'Function Name',
  name: 'resource_aws_lambda',
  queryKey: 'aws_lambda_name',
  type: 'array',
  queryApiTagName: 'functionName',
  searchable: true,
}
filterList.devModeCloudAccountId = {
  filter: 'devModeCloudAccountId',
  name: 'tag_account_id',
  queryKey: 'account_id',
  label: 'Accounts',
  type: 'array',
  queryApiTagName: 'accountId',
  searchable: true,
  fetchAlias: filterList.globalCloudAccountId.fetchAlias,
}

/**
 * List available Filters per each view in the app
 */

const globalFilters = [
  filterList.globalCloudAccountId,
  filterList.globalEnvironments,
  filterList.globalNamespace,
  filterList.globalRegions,
  filterList.awsLambdaFunctionNames,
  filterList.globalScope,
  filterList.globalTimeFrame,
]

const pageFilters = {
  'metric#awsLambda': [...globalFilters],
  'explorer#awsLambda': [
    ...globalFilters,
    filterList.explorerSubScope,
    filterList.explorerTraceId,
    filterList.explorerTraceSpanId,
    filterList.explorerTraceTime,
    filterList.awsLambdaEvents,
    filterList.awsLambdaCustomTags,
    filterList.awsLambdaDuration,
    filterList.awsLambdaColdStart,
  ],

  'devMode#': [filterList.devModeCloudAccountId, filterList.devModeFunctionName],
}

// Remove the filters we don't want to show in the UI, right before render
export const HiddenFilters = [
  'globalScope',
  'globalTimeFrame',
  'explorerSubScope',
  'explorerTraceId',
  'explorerTraceSpanId',
  'explorerTraceTime',
]

/**
 * Set types for URL Query String Parameters
 * Make sure to leave out defaults if they are not specified in Filters.
 * Some Filters in particular (e.g, durations) are known to cause slower query performance.
 * Leaving them out improves the UX.
 */

const queryStringTypes = {}
Object.keys(filterList).forEach((key) => {
  if (filterList[key].type === 'boolean') {
    queryStringTypes[key] = BooleanParam
  }
  if (filterList[key].type === 'string' || filterList[key].type === 'timeframe') {
    queryStringTypes[key] = StringParam
  }
  if (filterList[key].type === 'array' || filterList[key].type === 'autocomplete') {
    queryStringTypes[key] = ArrayParam
  }
  if (filterList[key].type === 'number') {
    queryStringTypes[key] = NumberParam
  }
  if (filterList[key].type === 'minmax') {
    queryStringTypes[key] = NumericObjectParam
  }
  if (filterList[key].type === 'customTags') {
    queryStringTypes[key] = JsonParam
  }
})

/**
 * Filter Context & Provider
 */

export const FilterContext = createContext({})

export const FilterProvider = ({ page, children }) => {
  const { pathname } = useLocation()
  const { activeOrg } = useContext(AppContext)
  const [aliasMap, setAliasMap] = useState({})
  const [query, setQuery] = useQueryParams(queryStringTypes)

  const [currentTimeFrameInit, setCurrentTimeFrameInit] = useState(false)

  const [filterCount, setFilterCount] = useState(0)
  const [currentTimeFrame, setCurrentTimeFrame] = useState(getTimeFrame(query.globalTimeFrame))

  const currentQueryFilters = useRef()
  const filters = cloneDeep(pageFilters[`${page}#${query.globalScope || ''}`]) || []

  const refreshTimeFrame = () => {
    currentQueryFilters.current = queryApiTags.filters
    if (currentTimeFrameInit && query.globalTimeFrame) {
      setCurrentTimeFrame(getTimeFrame(query.globalTimeFrame))
    } else if (query.globalTimeFrame) {
      setCurrentTimeFrameInit(true)
    } else if (currentTimeFrame) {
      setCurrentTimeFrameInit(false)
    }
  }
  /**
   * Get a the Filters for a specific scope and page, regardless of what current page/scope the user is on
   * @param {*} filterName
   * @returns
   */
  const getScopeAndPageFilterList = (targetScope, targetPage) => {
    const combinedName = `${targetPage}#${targetScope}`
    const defaultFilters = pageFilters[combinedName]
    if (!defaultFilters) {
      throw new Error(`The Page "${combinedName}" is not valid`)
    }
    return cloneDeep(defaultFilters)
  }

  /**
   * Get a Value of a specific current Filter by Filter name
   * @param {*} filterName
   * @returns
   */
  const getFilterValue = (filterName) => {
    const currentFilter = filters.find(({ filter }) => filter === filterName)
    // We want zero values to still return
    if (currentFilter?.value === 0) return currentFilter?.value
    return currentFilter?.value || null
  }

  /**
   * Sets the value of a specific Filter by name and value, and auto-updates the query params in the URL and Query API query
   * @param {*} filterName
   * @returns
   */
  const setFilterValue = (filterName, newValue) => {
    const currentFilter = filters.find((f) => f.filter === filterName)
    if (!currentFilter) {
      throw new Error(
        `Could not find filter: "${filterName}".  Are you sure it's available in the current Scope and page?`
      )
    }
    currentFilter.value = newValue
    setAllFilterValues(filters)
  }

  /**
   * Set all Filter Values, simultaneously.
   * Adds a default "globalTimeFrame", if missing
   * @param {*} newFilters
   */
  const setAllFilterValues = (newFilters = []) => {
    const newQuery = cloneDeep(query)
    // Update query params with the new values
    newFilters.forEach((f) => {
      if (f.value !== undefined) {
        newQuery[f.filter] = f.value
      }
      // If the Filter Value is nonexistant, be sure to set it as undefined or it will still show up in the URL query params
      if (f.value === undefined || f.value === 'undefined' || f.value === null) {
        newQuery[f.filter] = undefined
      }
    })

    // If TimeFrame is missing (always the case on first load), default to 24 hours
    if (
      (!newQuery.globalTimeFrame || newQuery.globalTimeFrame === 'undefined') &&
      !/dev-mode/.test(pathname) &&
      !/explorer/.test(pathname)
    ) {
      newQuery.globalTimeFrame = '24h'
    }

    setQuery(newQuery, 'push')
  }

  /**
   * Fetch/save alias values for a specific filter
   * @param {filter} filter
   */
  const loadAliasForFilter = async (filter) => {
    const filterMap = await filter.fetchAlias({ orgId: activeOrg.orgId })
    setAliasMap({
      ...aliasMap,
      ...filterMap,
    })
  }

  /**
   * Users the "filters" data from the FilterContext as a closure and structures the data for saving
   * to the Metrics Views API in a simple {filter:value} format. Skips TimeFrame, and anything that
   * doesn't have a value, to minimize what's saved, and reduce mirgration headaches if the Filter data evolves.
   *
   * @param optionalFilters By default this uses the currently set Filters, but you can pass in some optional
   * Filters to use instead.
   */
  const serializeFiltersForStorage = (optionalFilters) => {
    optionalFilters = optionalFilters || filters

    return optionalFilters
      .filter(({ filter, value }) => {
        if (filter === 'globalTimeFrame') {
          return false
        }
        if (value === undefined || value === null) {
          return false
        }
        if (Array.isArray(value) && !value.length) {
          return false
        }
        return true
      })
      .map(({ filter, value }) => {
        return { [filter]: value }
      })
  }

  /**
   * Creates a new Filters array for Filter Context that is enriched with serialized
   * Filter Value data from the Metrics View.  This does not update the update the FilterContext
   * automatically, so you will still have to use setAllFilterValues() with this
   *
   * @param serializedFilters Filter and Value data stored in the Metrics View API to use to enrich
   * the Filters in Filter Context
   */
  const deserializeFiltersFromStorage = (serializedFilters) => {
    // Clone, don't mutate the "filtersList" directly
    const clonedFilterList = cloneDeep(filterList)
    serializedFilters = serializedFilters.map((sf) => {
      let key = Object.keys(sf)[0]
      // Protects against changing names from what's been saved in the back-end
      if (!clonedFilterList[key]) {
        console.warn(
          `Warning, the following key ${key} is not in the filter list.  It must have been changed on the client-side, but not in previously saved records.`
        )
        return []
      }
      const filter = clonedFilterList[key]
      filter.value = sf[key]
      return filter
    })
    return serializedFilters
  }

  // Add values from the updated query string to the appropriate filters
  for (const filter of filters) {
    if (query[filter.filter] !== undefined) {
      filter.value = query[filter.filter]
    }
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Map the Filters to OTEL Resource Tags to use for requests with the Query API
  const queryApiTags = {
    ...currentTimeFrame,
    timezone,
    filters: {},
  }

  filters.forEach((filter) => {
    if (!filter.queryApiTagName) return
    if (filter.type === 'string' && query[filter.filter]) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'boolean' && query[filter.filter] === true) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'array' && query[filter.filter] && query[filter.filter].length > 0) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'autocomplete' && query[filter.filter] && query[filter.filter].length > 0) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'customTags' && query[filter.filter] && !isEmpty(query[filter.filter])) {
      queryApiTags.filters[filter.queryApiTagName] = keys(query[filter.filter]).reduce(
        (acc, key) => ({ ...acc, [key]: [query[filter.filter][key]] }),
        {}
      )
    }
    if (
      filter.type === 'minmax' &&
      query[filter.filter] &&
      !Number.isNaN(typeof query[filter.filter].min) &&
      !Number.isNaN(typeof query[filter.filter].max) &&
      JSON.stringify(query[filter.filter]) !== JSON.stringify(filter.defaultValue || {})
    ) {
      if (query[filter.filter].custom) {
        queryApiTags.filters[filter.queryApiCustomTagName] = {
          max: query[filter.filter].max,
          min: query[filter.filter].min,
        }
      } else {
        queryApiTags.filters[filter.queryApiTagName] = {
          min: durationIntervals[query[filter.filter].min]?.value,
          max: durationIntervals[query[filter.filter].max]?.value,
        }
      }
    }
  })

  /**
   * This effect keeps our current time frame in sync with the query params.
   * We also want to ignore the first run of this effect since currentTimeFrame is already
   * initialized to the query params.
   */
  useEffect(() => {
    refreshTimeFrame()
  }, [query.globalTimeFrame, query.explorerSubScope, query.globalScope])
  useEffect(() => {
    if (!isEqual(queryApiTags.filters, currentQueryFilters.current)) {
      refreshTimeFrame()
    }
  }, [queryApiTags.filters])

  // Fetch all alias values when the context is first loaded
  useEffect(() => {
    const loadAliases = async () => {
      const keys = Object.keys(query)
      for (let key of keys) {
        const list = query[key]
        const filter = filters.find(({ filter }) => filter === key)
        if (list && filter && 'fetchAlias' in filter) {
          await loadAliasForFilter(filter)
        }
      }
    }
    loadAliases()
  }, [])

  /**
   * Filter Bubble: Listen to Filters and count the number of Filters
   * with Values currently applied to show in the nav bar
   */
  useEffect(() => {
    let count = 0
    filters.forEach((f) => {
      if (f.value === undefined || f.value === null) {
        return
      }
      // Skip Scope and TimeFrame Filters since these don't really count as Filters
      if (f.filter === 'globalScope' || f.filter === 'globalTimeFrame') {
        return
      }
      // Skip empty arrays
      if (Array.isArray(f.value)) {
        if (!f.value.length) {
          return
        }
        // Add every array item to the count
        count = count + f.value.length
        return
      }

      // Skip false booleans
      if (f.type === 'boolean' && !f.value) {
        return
      }

      // If the current value is equal to its default value
      // we ignore this from our count
      if (f.defaultValue && f.value && JSON.stringify(f.defaultValue) === JSON.stringify(f.value)) {
        return
      }

      // Otherwise increase count
      count++
    })
    setFilterCount(count)
  }, [filters])

  return (
    <FilterContext.Provider
      value={{
        filters,
        allFilters: filters.reduce(
          (obj, filter) => ({
            ...obj,
            [filter.filter]: filter.value,
          }),
          {}
        ),
        getScopeAndPageFilterList,
        getFilterValue,
        setFilterValue,
        setAllFilterValues,
        serializeFiltersForStorage,
        deserializeFiltersFromStorage,
        setCurrentTimeFrame,
        queryApiTags,
        currentTimeFrame,
        filterCount,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
