import { useMemo } from 'react'
import { isAfter } from 'date-fns'
import useSWR from 'swr'
import { getBillingLimits } from 'util/core.api'
import { coreApiClient } from 'util/coreApiClient'
import { useDiscounts } from './useDiscounts'
import { basePlans } from '../plans'

/**
 * Default monthly limits in case not returned from API
 */
const MONTHLY_TRACES_LIMIT = 100000000
const MONTHLY_ACTIVE_RESOURCES_LIMIT = 100000000

/**
 * Usage refresh interval
 */
const REFRESH_INTERVAL = 5000

/**
 * enterprise and pro are legacy plan names
 */
const FREE_PLAN = 'free'
const TEAM_PLAN = 'team'
const PRO_PLAN = 'pro' // Legacy plan name
const BUSINESS_PLAN = 'business'
const ENTERPRISE_PLAN = 'enterprise' // Legacy plan name

const businessNames = [BUSINESS_PLAN, ENTERPRISE_PLAN]
const teamNames = [TEAM_PLAN, PRO_PLAN]

/**
 * A custom hook to return org usage and org current plan.
 */

export const useOrgUsage = ({ orgId }) => {
  /**
   * Get org current subscription
   */
  const {
    data: orgSubscription,
    error: orgSubscriptionError,
    mutate: mutateBilling,
  } = useSWR(orgId && `/billing/orgs/${orgId}`, coreApiClient, { shouldRetryOnError: false })

  const { plan, status } = orgSubscription || {}
  const isStatusActive = status === 'active'
  const isStatusCanceled = status === 'canceled'
  const currentPlan =
    isStatusActive && teamNames.includes(plan)
      ? TEAM_PLAN
      : isStatusActive && businessNames.includes(plan)
      ? BUSINESS_PLAN
      : FREE_PLAN

  const isFreePlan = currentPlan === FREE_PLAN
  const isTeamPlan = teamNames.includes(currentPlan)
  const isBusinessPlan = businessNames.includes(currentPlan)

  /**
   * Get org usage
   */
  const {
    data: orgUsage,
    error: orgUsageError,
    mutate: mutateUsage,
  } = useSWR(orgId && `/usage/mtd/${orgId}`, coreApiClient, {
    refreshInterval: isFreePlan && REFRESH_INTERVAL,
  })

  /**
   * Get billing limits
   */
  const { data: limits } = useSWR(orgId, () => getBillingLimits(), {})
  /**
   * Loading
   */
  const loadingUsage = !orgUsage && !orgUsageError
  const loadingSubscription = !orgSubscription && !orgSubscriptionError
  /**
   * Get discounts
   */
  const discounts = useDiscounts()
  /**
   * If status is 'canceled' it means org had previous subscription and card is stored in Stripe
   */
  const hadSubscriptionBefore = isStatusCanceled

  /**
   * Limits
   */
  const { traces: tracesUsage, activeResources: activeResourcesUsage } = orgUsage || {}

  const currentPlanLimit = limits && limits[currentPlan]
  const tracesLimit = currentPlanLimit?.traces || MONTHLY_TRACES_LIMIT
  const activeResourcesLimit = currentPlanLimit?.activeResources || MONTHLY_ACTIVE_RESOURCES_LIMIT

  /**
   * Check if org is over limit for traces, active resources, or dev-mode
   */
  const isTracesOverLimit = isFreePlan && tracesUsage > tracesLimit
  const isTracesApproachingLimit =
    tracesLimit * 0.8 < tracesUsage && isFreePlan && orgUsage?.traces > 0
  const isActiveResourcesOverLimit = isFreePlan && activeResourcesUsage > activeResourcesLimit
  const isOverDevModeLimit = isFreePlan && tracesUsage > tracesLimit

  /**
   * Usage percentage for traces and active resource, used for progress bars
   */
  const tracesUsagePercent = isTracesOverLimit
    ? 100
    : ((tracesUsage * 100) / tracesLimit).toFixed(2) || 0

  const activeResourcesPercent = isActiveResourcesOverLimit
    ? 100
    : ((activeResourcesUsage * 100) / activeResourcesLimit).toFixed(2) || 0

  const plans = useMemo(
    () =>
      basePlans.map((plan) => {
        const findEnabledDiscount = discounts.filter((discount) => {
          // If the discount does not apply to everyone ignore :)
          if (!discount.globalDiscount) return false
          // If discount code has an expiration make sure it is not expired
          if (discount.expiration && isAfter(new Date(), discount.expiration)) return false

          // Check if the plan should be applied to plan
          return (
            isFreePlan &&
            discount.enabledForPlans.includes('free') &&
            discount.targetPlan === plan.title
          )
        })
        return {
          ...{
            ...plan,
            details: findEnabledDiscount?.[0]?.replacedDetails || plan.details,
          },
          cta: findEnabledDiscount?.[0]?.cta || plan.cta,
          discount: findEnabledDiscount?.[0],
        }
      }),
    [isFreePlan, discounts]
  )

  const refresh = () => {
    mutateUsage()
    mutateBilling()
  }

  return {
    isTracesApproachingLimit,
    tracesLimit,
    tracesUsage,
    teamNames,
    businessNames,
    refresh,
    orgUsage,
    orgSubscription,
    currentPlan,
    isFreePlan,
    isTeamPlan,
    isBusinessPlan,
    loadingUsage,
    loadingSubscription,
    limit: currentPlanLimit,
    isTracesOverLimit,
    isActiveResourcesOverLimit,
    isOverDevModeLimit,
    tracesUsagePercent,
    activeResourcesPercent,
    hadSubscriptionBefore,
    plans,
    discounts,
  }
}
