import { memo } from 'react'
import classes from './EventMessage.module.css'
import ReactJson from 'react-json-view'
import { IconDangerTriangle } from 'common/icons/IconDangerTriangle'
import { eventsOptions } from 'filter/context/FilterContext'

export const EventMessage = memo(function EventMessage({ activity, theme }) {
  let message = 'Captured Event'
  let payload = {}
  let error = false
  let customTags = {}
  try {
    const parsedTags =
      typeof activity.customTags === 'object'
        ? activity.customTags
        : JSON.parse(activity.customTags)
    customTags = Object.keys(parsedTags).length > 0 ? { customTags: parsedTags } : {}
  } catch (error) {}
  if (activity.eventName === 'telemetry.error.generated.v1') {
    error = true
    payload = {
      ...activity?.tags?.error,
      ...customTags,
    }
    message = eventsOptions.reduce((acc, option) => {
      if (option.id !== activity?.tags?.error?.type) return acc
      if (option.isUncaughtError) {
        return 'ERROR • Uncaught'
      } else if (option.isCaughtError) {
        return 'ERROR • Caught'
      } else {
        return 'ERROR • Unspecified'
      }
    }, '')
  } else if (activity.eventName === 'telemetry.warning.generated.v1') {
    message = `WARNING`
    payload = { ...activity?.tags?.warning, ...customTags }
  }

  return (
    <div className={classes.eventContainer}>
      <div
        className={`${classes.eventHeader} ${error ? classes.error : ''}`}
        style={error ? { color: theme.palette.colors.redPrimary } : {}}
      >
        <IconDangerTriangle
          style={{
            fill: error ? theme.palette.colors.redPrimary : theme.palette.primary.main,
            width: '24px',
          }}
        />
        {message}
      </div>
      {Object.keys(payload).length > 0 && (
        <pre className="log-stream-log clickable">
          <ReactJson
            theme={
              error
                ? {
                    base00: theme.palette.text.red,
                    base01: theme.palette.text.red,
                    base02: theme.palette.text.red,
                    base03: theme.palette.text.red,
                    base04: theme.palette.text.red,
                    base05: theme.palette.text.red,
                    base06: theme.palette.text.red,
                    base07: theme.palette.text.red,
                    base08: theme.palette.text.red,
                    base09: theme.palette.text.red,
                    base0A: theme.palette.text.red,
                    base0B: theme.palette.text.red,
                    base0C: theme.palette.text.red,
                    base0D: theme.palette.text.red,
                    base0E: theme.palette.text.red,
                    base0F: theme.palette.text.red,
                  }
                : {
                    base00: theme.palette.text.secondary,
                    base01: theme.palette.text.secondary,
                    base02: theme.palette.border.main,
                    base03: theme.palette.text.secondary,
                    base04: theme.palette.text.secondary,
                    base05: theme.palette.text.secondary,
                    base06: theme.palette.text.secondary,
                    base07: theme.palette.text.primary,
                    base08: theme.palette.text.secondary,
                    base09: theme.palette.text.secondary,
                    base0A: theme.palette.text.secondary,
                    base0B: theme.palette.text.secondary,
                    base0C: theme.palette.text.secondary,
                    base0D: theme.palette.text.secondary,
                    base0E: theme.palette.text.secondary,
                    base0F: theme.palette.text.secondary,
                  }
            }
            name={null}
            indentWidth={4}
            iconStyle="triangle"
            displayDataTypes={false}
            enableClipboard={true}
            displayObjectSize={false}
            collapsed={2}
            quotesOnKeys={false}
            style={{
              backgroundColor: 'transparent',
            }}
            src={payload}
          />
        </pre>
      )}
    </div>
  )
})
