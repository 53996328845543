import { useContext } from 'react'
import { ClickAwayListener } from '@mui/material'
import { TimeFrame } from 'filter/components/TimeFrame'
import { FilterContext, timeFrameDefaults } from 'filter/context/FilterContext'
import { NavBarItemDropdown, NavBarItemDropdownInner } from '../../../common/components/NavBarItem'

export const TimeFrameDropdown = ({ setDropdown }) => {
  const { getFilterValue, setFilterValue } = useContext(FilterContext)
  const globalTimeFrame = getFilterValue('globalTimeFrame')
  return (
    <ClickAwayListener onClickAway={() => setDropdown(null)}>
      <NavBarItemDropdown
        width="350px"
        initial={{ height: '0' }}
        animate={{ height: 'auto', transition: { duration: 0.35, ease: 'easeOut' } }}
      >
        <NavBarItemDropdownInner sx={{ padding: '30px' }}>
          <TimeFrame
            timeFrameDefaults={timeFrameDefaults}
            timeFrameKey="globalTimeFrame"
            globalTimeFrame={globalTimeFrame}
            setTimeFrame={({ key, timeFrameCSV }) => {
              setFilterValue(key, timeFrameCSV)
            }}
            closeDropdown={() => setDropdown(null)}
          />
        </NavBarItemDropdownInner>
      </NavBarItemDropdown>
    </ClickAwayListener>
  )
}
