import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  LinearProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ROW_HEIGHT, TraceContext } from '../context/Trace.context'
import { useContext } from 'react'
import { useTheme } from '@mui/styles'
import { FilterContext } from 'filter/context/FilterContext'
import { getSpanDetails, getSpanId } from '../util/getSpanDetails'

const SpanChartItem = ({ span }) => {
  const theme = useTheme()

  const { maxSpanVal, hoveredSpan, setHoveredSpan, selectedSpan } = useContext(TraceContext)

  const { setFilterValue } = useContext(FilterContext)
  const { id, isEvent, hasError } = span
  const selectedSpanId = getSpanId(selectedSpan)
  const hoveredSpanId = getSpanId(hoveredSpan)

  const isSelected = selectedSpanId === id
  const isHovered = hoveredSpanId === id

  const onSpanClick = (spanID) => {
    if (!spanID) {
      return
    }
    setFilterValue('explorerTraceSpanId', spanID)
  }

  const offset =
    100 - (span?.chart?.[1] / maxSpanVal) * 100 + (span?.chart?.[0] / maxSpanVal) * 100 > 99
      ? 1.5
      : 0
  const paddingStartPercent = (span?.chart?.[0] / maxSpanVal) * 100
  const paddingEndPercent = 100 - (span?.chart?.[1] / maxSpanVal) * 100 - offset

  return (
    <StyledBox
      is-cold-start={span?.label === 'ColdStart' ? 'true' : undefined}
      selected={isSelected}
      onMouseEnter={() => {
        setHoveredSpan(span)
      }}
      onMouseLeave={() => {
        setHoveredSpan()
      }}
      hovered-span={isHovered ? 'true' : undefined}
      onClick={() => {
        onSpanClick(id)
      }}
    >
      <Box sx={{ height: ROW_HEIGHT, width: '100%', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '100%',
            height: ROW_HEIGHT,
            position: 'absolute',
            paddingTop: '5px',
            paddingLeft: `${paddingStartPercent}%`,
            paddingRight: `${paddingEndPercent}%`,
          }}
        >
          {isEvent ? (
            <Box
              sx={{
                width: 6,
                height: 6,
                backgroundColor: hasError ? 'error.main' : 'primary.main',
                borderRadius: '50%',
              }}
            />
          ) : (
            <BorderLinearProgress
              variant="determinate"
              color={hasError ? 'error' : 'primary'}
              value={100}
            />
          )}

          <StyledDuration
            style={{
              ...(hasError ? { color: theme.palette.colors.redPrimary } : {}),
              ...(paddingStartPercent >= 93 ? { alignSelf: 'flex-end' } : {}),
            }}
          >
            {span.durationFormatted}
          </StyledDuration>
        </div>
      </Box>
    </StyledBox>
  )
}

export const RenderSpansChart = ({ span }) => {
  const { timelineSpans, expanded, traceEvents } = useContext(TraceContext)

  const currentSpan = getSpanDetails({ span, timelineSpans, traceEvents })
  const { id } = currentSpan
  const isSpanExpanded = expanded?.includes(id)

  if (!currentSpan) {
    return null
  }

  if (currentSpan?.spans?.length) {
    return (
      <StyledAccordion expanded={isSpanExpanded}>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1a-content"
          style={{ padding: 0, minHeight: ROW_HEIGHT }}
        >
          <SpanChartItem span={currentSpan} />
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {currentSpan?.spans?.map((newCurrentSpan) => (
            <RenderSpansChart
              span={newCurrentSpan}
              key={`trace-spans-chart-span-${newCurrentSpan?.eventId || newCurrentSpan?.spanId}`}
            />
          ))}
        </AccordionDetails>
      </StyledAccordion>
    )
  } else {
    return <SpanChartItem span={currentSpan} />
  }
}

const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '0 ',
  height: '100%',
  width: '100%',

  '& .MuiAccordionSummary-root': {
    padding: '0px',
    justifyContent: 'flex-start',
    minHeight: ROW_HEIGHT,
  },

  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },

  '& .MuiAccordionSummary-expandIcon': {
    display: 'none',
  },

  '& .MuiAccordionDetails-root': {
    display: 'block',
  },
}))

const StyledBox = styled(Grid)(({ theme, ...props }) => ({
  paddingLeft: 4,
  width: '100%',
  cursor: props['is-cold-start'] ? 'not-allowed' : 'pointer',
  ...(props['selected'] || (props['hovered-span'] && !props['is-cold-start'])
    ? { backgroundColor: theme.palette.grey.dark }
    : {}),
  '&:hover': {
    backgroundColor: props['is-cold-start'] ? 'none' : theme.palette.grey.dark,
  },
}))

const StyledDuration = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.textSecondary.fontSize,
  zIndex: 5,
  padding: '2px 0 0 0',
}))

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
  width: '100%',
  zIndex: 4,
}))
