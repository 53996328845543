import React, { memo, useContext, useMemo, useState } from 'react'
import numeral from 'numeral'
import {
  ComposedChart,
  Scatter,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ZAxis,
  Area,
  Cell,
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import CustomizedTicks from './CustomizedTicks'
import { useTheme } from '@mui/styles'
import { useQuery } from 'metrics/hooks/useQuery'
import ChartHeader from './ChartHeader'
import { random, range, uniqBy, upperFirst } from 'lodash'
import { Box } from '@mui/system'
import { FilterContext } from 'filter/context/FilterContext'
import LatencyLegend from './LatencyLegend'
import { motion } from 'framer-motion'
import { formatNumber, medianValue } from '../helpers/graph-helpers'
import ErrorState from 'common/components/ErrorState'
import { Skeleton } from '@mui/material'
import CustomResponsiveContainer from './CustomResponsiveContainer'
import { stringifyUrl } from 'query-string'
import { AppContext } from 'app/context/AppContext'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 10, height } = {}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      margin: '20px 20px 20px 50px',
      height,
      position: 'relative',
    }}
  >
    {range(size)?.map((num) => (
      <Box
        key={`metrics-latency-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton
          variant="circular"
          width={8}
          height={8}
          sx={{ bottom: random(0, 20), position: 'absolute' }}
        />
        <Skeleton
          variant="circular"
          width={8}
          height={8}
          sx={{ bottom: random(20, 120), position: 'absolute' }}
        />
      </Box>
    ))}
  </Box>
)

const LatencyGraph = ({ scatterClick, useCustomContainer }) => {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const [focusedBarIndex, setFocusedBarIndex] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()

  const { orgName } = activeOrg
  const lineStrokeWidth = 2
  const height = 180

  const dotColor = theme.palette.text.secondary
  const errorDotColor = theme.palette.error.main

  const areaFillColor = theme.palette.border.dark
  const { currentTimeFrame, getFilterValue } = useContext(FilterContext)

  const ChartContainer = useCustomContainer ? CustomResponsiveContainer : ResponsiveContainer

  const { data, isValidating, error, refresh, loading } = useQuery({
    query: 'aws_lambda_duration',
  })

  const chartData = useMemo(
    () => data?.results.map((item, idx) => ({ ...item, z: focusedBarIndex === idx ? 130 : 30 })),
    [data, focusedBarIndex]
  )

  const averageAvg = formatNumber(Math.round(medianValue(chartData, 'avg')) || 0)
  const averageP95 = formatNumber(Math.round(medianValue(chartData, 'p95')) || 0)

  let interval = chartData?.length >= 10 ? 1 : 0

  const headingStats = [
    {
      value: `${averageP95}ms`,
      description: 'Average P95 latency',
      isError: true,
    },
    {
      value: !error ? `${averageAvg}ms` : '-',
      description: 'Average latency',
    },
  ]
  const getOkLink = (globalTimeFrame) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        globalTimeFrame: globalTimeFrame || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })

  const onDotClick = (props) => {
    const domain = props?.xAxis?.domain
    const index = domain.indexOf(props.time)

    let startDate
    let endDate
    // if last item
    if (index === domain.length - 1) {
      startDate = new Date(props.time)
      endDate = new Date()
    } else {
      startDate = new Date(props.time)
      endDate = new Date(domain[index + 1])
    }
    const link = getOkLink([`${startDate?.getTime()},${endDate?.getTime()}`])

    navigate(link)
  }

  return (
    <Box
      sx={{
        minHeight: height + 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ChartHeader
        title="Function Latency"
        description={'AWS Lambda invocations count within time frame.'}
        stats={headingStats}
        loading={loading}
        error={error}
        isValidating={isValidating}
      />
      {loading || (!chartData && isValidating) ? (
        <SkeletonLoading size={30} height={height} />
      ) : error ? (
        <ErrorState onReload={refresh} isColumnDirection fullWidth />
      ) : (
        <ChartContainer width="99.9%" height={height}>
          <ComposedChart
            className={scatterClick ? 'scatter-clickable' : ''}
            data={chartData}
            onMouseMove={(state) => {
              if (state.activeTooltipIndex >= 0) {
                setFocusedBarIndex(state.activeTooltipIndex)
              } else {
                setFocusedBarIndex(null)
              }
            }}
            onMouseLeave={() => setFocusedBarIndex(null)}
          >
            <XAxis
              dataKey="time"
              tickLine={false}
              axisLine={false}
              interval={interval}
              allowDataOverflow={false}
              tickMargin={5}
              padding={{ left: 20, right: 15 }}
              tick={
                <CustomizedTicks currentTimeFrame={currentTimeFrame} labelColor={dotColor} dy={0} />
              }
            />
            <Tooltip
              content={<CustomTooltipComponent />}
              payload={chartData}
              wrapperStyle={{ top: -90, outline: 'none' }}
            />
            <YAxis
              width={40}
              domain={[
                (dataMin) => {
                  if (dataMin === Infinity) {
                    return 0
                  }
                  return (dataMin || 0) - 200 > 0 ? (dataMin || 0) - 200 : 0
                },
                (dataMax) => {
                  if (dataMax === -Infinity) {
                    return 100
                  }
                  return (dataMax || 0) + 100
                },
              ]}
              tickLine={false}
              axisLine={false}
              padding={{ bottom: 20 }}
              tick={{ fontSize: theme.typography.textTertiary.fontSize, fill: dotColor }}
              tickFormatter={(value) => numeral(value || 0).format('0.0a')}
            />
            <ZAxis dataKey="z" type="number" range={[30, 130]} />

            <Area
              type="monotone"
              dataKey="avg"
              strokeWidth={0}
              stroke="none"
              fill={areaFillColor}
            />
            <Line
              activeDot={{
                stroke: dotColor,
                strokeWidth: 1,
                r: 3,
                fill: theme.palette.secondary.main,
              }}
              type="monotone"
              dataKey="avg"
              strokeWidth={lineStrokeWidth}
              stroke={dotColor}
              strokeDasharray="5"
              dot={false}
            />
            <Scatter dataKey="p95" onClick={onDotClick}>
              {chartData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.avg * 1.75 < entry.p95 ? errorDotColor : dotColor}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </Scatter>
          </ComposedChart>
        </ChartContainer>
      )}
      <LatencyLegend />
    </Box>
  )
}

const CustomTooltipComponent = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const time = payload[0]?.payload?.time

    const items = uniqBy(payload, 'dataKey')?.map((item) => ({
      title: upperFirst(item.name),
      value: item.value?.toLocaleString('en-US') || '-',
      isError: item.name === 'p95' && item.payload.avg * 1.75 < item.payload.p95,
    }))

    return <CustomTooltip itemDate={time} items={items} isLatencyGraph />
  }

  return null
}

export default memo(LatencyGraph)
