import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
} from '@mui/material'
import { Button } from 'common/components/Button'
import { useContext, useState } from 'react'
import { AppContext } from 'app/context/AppContext'
import { coreApiClient } from 'util/coreApiClient'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { keys } from 'lodash'
import { useTheme } from '@mui/styles'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { Checkbox } from 'common/components/Checkbox'

export const DowngradePlanDialog = ({ openDowngradeDialog, setOpenDowngradeDialog }) => {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgName, orgId } = activeOrg || {}
  /**
   * Get current subscription
   */
  const { refresh } = useOrgUsage({ orgId })
  /**
   * Showing the user some info about downgrading
   * users must check all these to be able to continue.
   */
  const info = [
    <span>
      The <span style={{ color: theme.palette.text.secondary }}> {orgName} </span> org will be
      downgraded to the Free tier.
    </span>,
    <span>
      The <span style={{ color: theme.palette.text.secondary }}> {orgName} </span> org will be
      limited to 100k monthly traces (with logs) per month.
    </span>,
    <span>The credit card on file will be charged for month-to-date usage.</span>,
    <span>
      If the <span style={{ color: theme.palette.text.secondary }}> {orgName} </span> org already
      surpassed 100k traces this month, your org will be rate limited immediately.
    </span>,
  ]
  const initialCheckedValue = info.reduce((acc, i, idx) => ({ ...acc, [idx]: false }), {})
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(initialCheckedValue)
  const [reason, setReason] = useState('')
  const closeDialog = () => {
    setOpenDowngradeDialog(false)
    setChecked(initialCheckedValue)
    setErrorMessage('')
  }
  const handleDowngrade = async () => {
    setLoading(true)
    try {
      setErrorMessage('')
      await coreApiClient({
        url: `billing/orgs/${orgId}/cancel`,
        method: 'post',
        data: {
          reason,
        },
      })
      refresh()
      closeDialog()
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.response?.data?.message || err?.message)
    }
    setLoading(false)
  }

  return (
    <Dialog
      open={!!openDowngradeDialog}
      onClose={closeDialog}
      aria-labelledby="billing-downgrade-dialog-title"
      aria-describedby="billing-downgrade-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle color="text.primary" fontWeight="bold">
        Downgrade {orgName} to Free
      </DialogTitle>
      <DialogContent mt={2}>
        <Typography variant="textPrimary" color="text.secondary" mb={2}>
          Before you downgrade, please confirm you understand the following:
        </Typography>

        {info.map((item, idx) => (
          <Checkbox
            key={`downgrade-info-${idx}`}
            label={item}
            textVariant="textPrimary"
            checked={checked[idx]}
            onChange={(e) => setChecked((prev) => ({ ...prev, [idx]: e.target.checked }))}
          />
        ))}

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="textPrimary" color="text.secondary" fontWeight="bold" mt={3} mb={1}>
            Why are you cancelling?
          </Typography>
          <Typography variant="textSecondary" color="text.secondary" mt={3} mb={1} fontSize={12}>
            {reason.length} / 100
          </Typography>
        </Box>
        <TextField
          id="outlined-basic"
          variant="outlined"
          sx={{ width: '100%' }}
          value={reason}
          onChange={(e) =>
            setReason((prev) => (e.target.value.length <= 100 ? e.target.value : prev))
          }
        />

        <ErrorMessage message={errorMessage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleDowngrade}
          variant="contained"
          color="error"
          loading={loading}
          disabled={!(keys(checked)?.length === 4 && keys(checked).every((key) => checked[key]))} // button disabled if one or more not checked
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
