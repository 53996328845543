import { Box, Typography } from '@mui/material'

export const Title = ({ title, sx }) => (
  <Typography sx={sx} variant="h1" gutterBottom>
    {String(title)?.includes('$') ? (
      <Box component="span" display="flex">
        <Box
          component="span"
          sx={{
            fontSize: 14,
            margin: '-2px 2px auto 0',
          }}
        >
          $
        </Box>
        {title.replace('$', '')}
      </Box>
    ) : (
      title
    )}
  </Typography>
)

export const animateProps = ({ delay = 0 } = {}) => ({
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delay, duration: 0.6, ease: 'easeOut' },
  },
})

export const SectionTitle = ({ title }) => (
  <Typography variant="h2" mb={1.5}>
    {title}
  </Typography>
)
