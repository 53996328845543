import axios from 'axios'
import { useRef } from 'react'
import useSWR from 'swr'

import config from 'config'

export const getIdToken = () => {
  try {
    const dataStr = localStorage.getItem(config.localStorageKey)
    if (!dataStr) throw new Error('No token')
    const data = JSON.parse(dataStr)
    return data?.idToken
  } catch (error) {
    return null
  }
}

export const coreApiClient = axios.create({
  baseURL: config.platform.eventsUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getIdToken()}`,
  },
})

// Add a request interceptor
coreApiClient.interceptors.request.use(
  (config) => {
    const idToken = getIdToken()
    // Do something before request is sent

    if (idToken) {
      config.headers['Authorization'] = idToken
    }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
coreApiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx
    return response?.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

/**
 * Return previous results while revalidating
 */
export function useStickySWR(...args) {
  const val = useRef()

  const { data, isValidating, error, ...rest } = useSWR(...args)

  if (data !== undefined) {
    val.current = data
  }

  return {
    ...rest,
    isValidating,
    error,
    data: val.current,
  }
}
