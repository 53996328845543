import { useContext, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { Search, X, Plus } from 'lucide-react'

import { EmptyFilterArray } from 'filter/components/EmptyFilterArray'
import { useSearch } from 'common/hooks/useSearch'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { LoadingSpinner } from 'common/components/LoadingSpinner'
import { isArray, uniqBy, some, sortBy, unionBy } from 'lodash'
import { slugifyString } from 'filter/components/FiltersWidget'
import { AppContext } from 'app/context/AppContext'
import { useDebounce } from 'react-use'
import { Chip } from './Chip'
import { Checkbox } from './Checkbox'
import { FilterLabel } from 'filter/components/FilterLabel'
import { animateProps } from 'filter/util/filterAnimateProps'

export const FilterSearchDropdown = ({
  filter,
  onChange = {},
  selectedValues,
  collapsable = true,
  allowAddNew = false,
  allowMulti = true,
  animate = true,
}) => {
  const size = 50
  const height = 200

  const { name: type, searchable, icon, queryKey } = filter || {}
  /**
   * Temporary get org id to fetch aliases for aws accounts
   */
  const { activeOrg } = useContext(AppContext)

  const [isOpen, setIsOpen] = useState(!collapsable)
  const [filterText, setFilterText] = useState('')

  const initialOptions = filter?.options || []
  const [dropdownAliasTotal, setDropdownAliasTotal] = useState()
  const [options, setOptions] = useState(initialOptions)
  const [loadingAlias, setLoadingAlias] = useState(false)
  const [page, setPage] = useState(1)
  const [debouncedQuery, setDebouncedQuery] = useState({ page, filterText })
  const selected = useMemo(
    () =>
      selectedValues?.map((value) => {
        const selectedItem = options?.find((op) => op.value === value)
        return {
          label: selectedItem?.label || value,
          value: selectedItem?.value || value,
        }
      }) || [],
    [options, selectedValues]
  )

  useDebounce(
    () => {
      setDebouncedQuery({ page, filterText })
    },
    500,
    [filterText, page]
  )
  const addInputSearch = debouncedQuery?.filterText
    ? [
        {
          multi_match: {
            query: debouncedQuery?.filterText,
            fields: [queryKey || '*'],
            type: 'phrase_prefix',
          },
        },
      ]
    : []
  const query = {
    bool: {
      must: [
        {
          match: {
            type,
          },
        },
        ...addInputSearch,
      ],
    },
  }
  const sort = [
    {
      [`${queryKey}.keyword`]: {
        order: 'asc',
      },
    },
  ]
  const currentPage = debouncedQuery?.page
  /**
   *  Only fetch when:
   * - Dropdown is open.
   * - Filter type is not boolean.
   * - Filter is account ID (needs to fetch aliases).
   */

  const ready = (isOpen && !filter?.options) || (filter.fetchAlias && selected?.length) || false // Only fetch when dropdown is open, no passed options and filter is not boolean

  const { data, loading } = useSearch({
    size,
    ready,
    currentPage,
    query,
    sort,
  })
  const hasMore = useMemo(() => options?.length < data?.total, [data, options])
  const total = useMemo(() => dropdownAliasTotal || data?.total, [data?.total, dropdownAliasTotal])

  useEffect(() => {
    if (data?.hits) {
      let integrations
      let newOptions = data?.hits.map((option) => ({
        ...option,
        value: option[queryKey] || option.id,
        label: option[queryKey] || option.id,
      }))
      // If filter is account id, then fetch aliases
      if (filter.fetchAlias) {
        ;(async () => {
          setLoadingAlias(true)
          integrations = await filter.fetchAlias({ orgId: activeOrg.orgId })
          const accountIds = Object.keys(integrations)
          newOptions = newOptions
            ?.filter((options) => accountIds.includes(options.id))
            ?.map((opt) => ({
              ...opt,
              label: integrations?.[opt?.account_id],
            }))
          setDropdownAliasTotal(newOptions?.length)
          setOptions(newOptions)
          setLoadingAlias(false)
        })()
      } else {
        /**
         * Sort options to show current selected at the top, and combine paginated results without duplicates.
         */

        setOptions((prev) =>
          sortBy(unionBy(newOptions, prev, 'id'), [
            (option) => !selected?.some((n) => n.value === option.value),
            'label',
          ])
        )
      }
    }
  }, [data?.hits])

  const fetchData = () => {
    setPage((prev) => prev + 1)
  }

  /**
   * Toggle collapsed state
   */
  const toggle = () => {
    if (!collapsable) return
    setIsOpen((prev) => !prev)
    setFilterText('')
  }

  const isCurrentOptionChecked = (option) =>
    some(
      selected,
      (n) => (isArray(n?.value) && n?.value?.includes(option.value)) || n?.value === option.value
    )

  const onOptionSelect = (option) => {
    onChange(option)
    if (!allowMulti) {
      setIsOpen(false)
    }
  }

  return (
    <DropdownWrapper is-open={isOpen ? 'true' : undefined}>
      <DropdownTitleWrapper onClick={toggle} is-open={isOpen ? 'true' : undefined}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FilterLabel label={filter.label} />
          {isOpen && (loadingAlias || loading) ? (
            <CircularProgress
              size={11}
              thickness={5}
              sx={{ color: 'primary.main', marginRight: '10px', marginLeft: 'auto' }}
            />
          ) : isOpen && options?.length && total ? (
            <Typography variant="textTertiary" color="text.secondary" sx={{ marginLeft: 'auto' }}>
              {`${options?.length} of ${total}`}
            </Typography>
          ) : null}

          {collapsable && <DropdownIcon isOpen={isOpen} />}
        </Box>
        {/* Show selected tags only when dropdown is closed, and only show first 8 with a message stating the total number of tags selected */}
        {selected?.length && !isOpen ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}
            {...(animate ? animateProps : {})}
          >
            {selected.slice(0, 8)?.map((selectedValue) => (
              <Chip
                key={selectedValue?.value}
                icon={icon}
                label={loadingAlias ? 'loading...' : selectedValue?.label}
                onDelete={(e) => onChange(allowMulti ? selectedValue : undefined)}
                size="small"
                autoTruncate={false}
              />
            ))}
          </Box>
        ) : null}
        {selected?.length > 8 && !isOpen ? (
          <Typography variant="textTertiary" color="text.secondary">
            and {selected?.length - 8} more...
          </Typography>
        ) : null}
        {isOpen && selected?.length ? (
          <Typography variant="textTertiary" color="text.secondary">
            {selected?.length} Selected
          </Typography>
        ) : null}
      </DropdownTitleWrapper>

      {isOpen && (
        <Box
          sx={{
            overflow: 'hidden',
          }}
          {...(animate ? animateProps : {})}
        >
          {searchable && (
            <TextField
              sx={{ padding: '0 10px 10px 10px' }}
              placeholder={`Search ${allowAddNew ? 'or add new tag' : ''}`}
              value={filterText}
              InputProps={{
                style: {
                  paddingLeft: 10,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={18} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {filterText && (
                      <IconButton onClick={() => setFilterText('')}>
                        <X size={16} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setOptions(initialOptions)
                setPage(1)
                setFilterText(e.target.value)
              }}
            />
          )}
          <Divider />

          <Box
            sx={{
              overflow: 'auto',
              height,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <InfiniteScroll
              dataLength={options.length}
              next={fetchData}
              hasMore={hasMore}
              loader={<LoadingSpinner minHeight="100%" />}
              height={height}
            >
              {!options?.length &&
              !loading &&
              !loadingAlias &&
              filterText === debouncedQuery?.filterText ? (
                allowAddNew ? (
                  <ValueItem
                    onClick={(event) => {
                      const newValue = slugifyString({ str: filterText })
                      onChange({ label: newValue, value: newValue })
                      if (!allowMulti) {
                        setIsOpen(false)
                      }
                    }}
                  >
                    Add "{slugifyString({ str: filterText })}" tag
                    <Plus size={20} style={{ marginRight: 18 }} />
                  </ValueItem>
                ) : (
                  <EmptyFilterArray options={options} style={{ height: '40%' }} />
                )
              ) : null}

              {uniqBy(options, 'value').map((option, i) => (
                <DropdownOption
                  key={`filters-${filter?.name}-options-${i}`}
                  option={option}
                  onClick={() => onOptionSelect(option)}
                  isCurrentOptionChecked={isCurrentOptionChecked}
                  icon={icon}
                />
              ))}
            </InfiniteScroll>
          </Box>
        </Box>
      )}
    </DropdownWrapper>
  )
}

const DropdownOption = ({ option, onClick, isCurrentOptionChecked, icon }) => {
  const isSelected = isCurrentOptionChecked(option)
  return (
    <ValueItem onClick={onClick} sx={{ color: isSelected ? 'text.primary' : 'text.secondary' }}>
      <Checkbox checked={isSelected} />
      {icon}
      <Typography variant="textPrimary">{option?.label}</Typography>
    </ValueItem>
  )
}
export const DropdownWrapper = styled('div')((props) => ({
  position: 'relative',
  display: 'block',
  height: 'auto',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  border: `1px solid ${
    props['is-open'] ? props.theme.palette.border.main : props.theme.palette.secondary.main
  }`,

  margin: props.margin || '0',
  marginBottom: '5px',
  transition: 'all 0.2s ease',
  borderRadius: 4,

  '&:hover': {
    background: props['is-open'] ? 'none' : props.theme.palette.grey.dark,
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

export const DropdownTitleWrapper = styled('div')((props) => ({
  zIndex: '10',
  padding: '10px',
  width: '100%',
  boxSizing: 'border-box',
  userSelect: 'none',
  borderRadius: 4,

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: props['is-open'] ? 'none' : props.theme.palette.grey.dark,
  },
}))

const ValueItem = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  width: '100%',
  padding: '5px 10px',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    color: props.theme.palette.text.primary,
    backgroundColor: props.theme.palette.grey.dark,
  },
}))
