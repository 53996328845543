import React, { useState, useContext, useEffect } from 'react'
import { styled, Switch, Typography } from '@mui/material'
import { Button } from 'common/components/Button'
import { motion } from 'framer-motion'
import cloneDeep from 'lodash.clonedeep'
import { FilterContext, HiddenFilters } from 'filter/context/FilterContext'
import { isArray, isString } from 'lodash'
import {
  DropdownTitleWrapper,
  DropdownWrapper,
  FilterSearchDropdown,
} from 'common/components/FilterSearchDropdown'
import FilterDurationWidget from './FilterDurationWidget'
import { CustomTagsFilter } from './CustomTagsFilter'
import { FilterLabel } from './FilterLabel'
import { durationIntervals } from '../util/duration'

export const slugifyString = ({ str = '', lowerCase = true }) => {
  let newString = str
    .replace(/[^a-zA-Z0-9_]+/g, '-') // Replace any run of disallowed chars with a hyphen
    .replace(/^-+/, '') // remove leading hyphens
  if (lowerCase) {
    newString = newString.toLowerCase()
  }
  return newString
}

/**
 * Filters Widget
 *
 * This Component is designed as a reusable Filters UI widget which can be embedded in any page, corresponding to any Scope experience.
 * This is tightly coupled with the Filter Context and the data/methods it exports.
 *
 * @param inDropdown If you pass in false this will render a view that is meant to be persistent on the page. So you will see an auto save when you change filters and some styling updates.
 * @param onSave Pass in a function that receives the new Filters and Values when a user clicks "Save" so you can process the save behavior in a custom way.  This defaults to updating the Query URL only.  The default experience does not persist Filter Values anywhere else.
 * @param onCancel Pass in a function that receives the new Filters and Values when a user clicks "Cancel" so you can process the cancel behavior in a custom way.  By default, nothing happens.
 */

export const FiltersWidget = ({ inDropdown = true, onSave = null, onCancel = () => {} }) => {
  const { filters, setAllFilterValues, getFilterValue, filterCount } = useContext(FilterContext)
  const [newFilters, setNewFilters] = useState(cloneDeep(filters))
  const [isSaving, setIsSaving] = useState(false)
  const showHeader = inDropdown
  const autoSave = !inDropdown
  const explorerSubScope = getFilterValue('explorerSubScope')

  // Wrap the onSave function with saving state
  const saveFilters = async (e) => {
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    setIsSaving(true)
    if (onSave) {
      await onSave(newFilters)
    } else {
      setAllFilterValues(newFilters)
    }
    setIsSaving(false)
  }
  const onFilterChange = (filter, option) => {
    const newValue = option.value
    /**
     * Todo: Simplify this logic to add/remove values
     */
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f
        const prevValue = f.value

        const addedValue = isArray(prevValue)
          ? [...prevValue, newValue]
          : isString(prevValue)
          ? [prevValue, newValue]
          : [newValue]
        const value = prevValue?.includes(newValue)
          ? addedValue.filter((i) => i !== newValue)
          : addedValue

        return { ...f, value }
      })
    )
  }

  useEffect(async () => {
    const sameFilters = JSON.stringify(filters) === JSON.stringify(newFilters)
    if (autoSave && !sameFilters) {
      await saveFilters(newFilters)
    }
  }, [newFilters, autoSave])

  useEffect(() => {
    if (!inDropdown) {
      setNewFilters(cloneDeep(filters))
    }
  }, [filters, inDropdown])

  /**
   * Set the boolean value for the boolean filter
   *
   * @param {string} filter the name of the filter in the filter array
   * @param {*} value the new value for the filter
   */
  const setBooleanFilter = (filter, value) => {
    const tempFilters = [...newFilters]
    tempFilters.forEach((f) => {
      if (f.filter === filter?.filter) {
        f.value = value
      }
    })
    setNewFilters(tempFilters)
  }

  const uiFilters = newFilters.filter(
    (f) =>
      HiddenFilters.indexOf(f.filter) < 0 &&
      !(explorerSubScope === 'errors' && f.filter === 'awsLambdaFunctionErrors')
  )
  const updateMinMaxValues = (filter, value) => {
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f

        // let updatedValue
        const isDefaultValue = value?.min === 0 && value?.max === durationIntervals.length - 1

        if (isDefaultValue || !value || value === null) {
          delete f.value
        } else {
          f.value = {
            min: !isNaN(value.min) ? value.min : 0,
            max: !isNaN(value.max) ? value.max : 0,
          }
        }

        // Remove filter value if new new value is selected
        return f
      })
    )
  }
  const setCustomTags = (filter, tags) => {
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f
        return {
          ...f,
          value: tags?.length
            ? tags.reduce((acc, tag) => ({ ...acc, [tag.key]: tag.value }), {})
            : undefined,
        }
      })
    )
  }

  /**
   * Render
   */
  return (
    <View
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.1, duration: 0.2, ease: 'easeOut' } }}
    >
      {showHeader && (
        <HeaderContainer>
          <Header>
            <HeaderTitle variant="h2">Filters</HeaderTitle>

            <HeaderOptions>
              {filterCount ||
              newFilters
                .filter(({ filter }) => filter !== 'globalScope' && filter !== 'globalTimeFrame')
                .some((e) => e.value) ? (
                <Button
                  color="secondary"
                  size="small"
                  sx={{ margin: '0' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    const defaultFilters = filters
                      .filter(
                        ({ filter }) => filter !== 'globalScope' && filter !== 'globalTimeFrame'
                      )
                      .map(({ value, ...rest }) => ({ ...rest }))
                    setNewFilters(defaultFilters)
                  }}
                >
                  Clear Filters
                </Button>
              ) : (
                <Button
                  size="small"
                  color="secondary"
                  sx={{ margin: '0 0 0 0' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onCancel([...newFilters])
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button size="small" color="primary" loading={isSaving} onClick={saveFilters}>
                Save
              </Button>
            </HeaderOptions>
          </Header>

          <ContentDivider />
        </HeaderContainer>
      )}

      <FilterListContainer indropdown={`${inDropdown}`}>
        <FilterList indropdown={`${inDropdown}`}>
          {uiFilters.map((filter) => {
            if (filter.type === 'boolean') {
              return (
                <DropdownWrapper key={`metrics-filters-${filter.name}`}>
                  <DropdownTitleWrapper
                    onClick={() => setBooleanFilter(filter, !filter.value)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <FilterLabel label={filter?.label} />
                    <Switch
                      checked={Boolean(filter?.value)}
                      onChange={(event) => {
                        event?.stopPropagation()
                        setBooleanFilter(filter, event.target.checked)
                      }}
                      name={filter?.name}
                      inputProps={{ 'aria-label': `${filter?.name} checkbox` }}
                    />
                  </DropdownTitleWrapper>
                </DropdownWrapper>
              )
            } else if (filter.type === 'minmax') {
              return (
                <FilterDurationWidget
                  key={`metrics-filters-${filter.name}`}
                  filter={filter}
                  value={filter.value}
                  onChange={(...e) => updateMinMaxValues(...e)}
                  inDropdown={inDropdown}
                />
              )
            } else if (filter.type === 'customTags') {
              return (
                <CustomTagsFilter
                  key={`metrics-filters-${filter.name}`}
                  filter={filter}
                  onChange={setCustomTags}
                />
              )
            } else {
              return (
                <FilterSearchDropdown
                  key={`metrics-filters-${filter.name}`}
                  filter={filter}
                  onChange={(option) => {
                    onFilterChange(filter, option)
                  }}
                  selectedValues={filter?.value}
                />
              )
            }
          })}
        </FilterList>
      </FilterListContainer>
    </View>
  )
}

/**
 * Styles
 */

const View = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  position: 'relative',
}))

const HeaderContainer = styled('div')(({ theme }) => ({
  marginBottom: 10,
  position: 'sticky',
  top: 0,
  zIndex: 2,

  backgroundColor: theme.palette.secondary.main,
  padding: '30px 30px 0 30px',
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  userSelect: 'none',
  boxSizing: 'border-box',
  height: 'auto',
  width: '100%',
  marginBottom: 20,
}))

const HeaderTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',

  userSelect: 'none',
}))

const HeaderOptions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  justifyItems: 'flex-end',
  height: '100%',

  '& button': {
    marginLeft: '16px',
  },
}))

const ContentDivider = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '2px',
  background: theme.palette.grey.medium,
}))

const FilterListContainer = styled('div')(({ indropdown }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  overflow: 'hidden',

  ...(indropdown === 'true' ? { padding: '0px 30px 30px 30px' } : {}),
}))

const FilterList = styled('div')(({ indropdown }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  margin: '0',
  boxSizing: 'border-box',
  ...(indropdown === 'true' ? { overflow: 'hidden scroll' } : {}),
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
