/**
Cardinality intervals:
1-50
51-100
101-200
201-300
301-500
501-1000
1001-1500
1501-2000
2001-3000
3001-5000
5001-10000
10001-20000
20001-30000
30001-50000
50001-100000
100001-200000
200001-300000
300001-500000
500001-900000
*/
export const durationIntervals = [
  { value: 0, label: '1ms' },
  { value: 50, label: '50ms' },
  { value: 100, label: '100ms' },
  { value: 200, label: '200ms' },
  { value: 300, label: '300ms' },
  { value: 500, label: '500ms' },
  { value: 1000, label: '1s' },
  { value: 1500, label: '1.5s' },
  { value: 2000, label: '2s' },
  { value: 3000, label: '3s' },
  { value: 5000, label: '5s' },
  { value: 10000, label: '10s' },
  { value: 20000, label: '20s' },
  { value: 30000, label: '30s' },
  { value: 50000, label: '50s' },
  { value: 100000, label: '100s' },
  { value: 200000, label: '200s' },
  { value: 300000, label: '5min' },
  { value: 500000, label: '10min' },
  { value: 900000, label: '15min' },
  { value: 900001, label: '15min+' },
]

export const getDurationTags = ({ min = 0, max = 20 }) => {
  const values = []
  for (let i = min; i < max; i++) {
    let s = `${durationIntervals[i]?.value + 1}-${durationIntervals[i + 1]?.value}`
    if (i === durationIntervals.length - 2) {
      s = '>900000'
    }
    values.push(s)
  }
  return values
}
