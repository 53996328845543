import { styled, Box, Typography } from '@mui/material'
import { format, isValid } from 'date-fns'
import { slice } from 'lodash'

const TooltipsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.colors.blackMediumLight,
  color: theme.palette.colors.white,
  padding: 14,
  borderRadius: 6,
  width: '100%',
  position: 'relative',
  outline: 'none',
  border: 'none',
}))

const CustomTooltip = ({ itemDate, items, isLatencyGraph, title }) => {
  const date = new Date(itemDate)
  return (
    <TooltipsWrapper>
      {title ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography variant="textPrimary">{title}</Typography>
        </Box>
      ) : isValid(date) ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography variant="textPrimary">{format(date, 'MMM do, yyy – h:mmaa')}</Typography>
        </Box>
      ) : null}
      {isLatencyGraph ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: 'none',
            outline: 'none',
            gap: '20px',
          }}
        >
          {items?.map((item, index) => (
            <Box key={`tooltip-${JSON.stringify(item)}-${index}`}>
              <Typography variant="textTertiary">{item.title}</Typography>
              <Typography
                variant="textPrimary"
                color={item.isError ? 'error.main' : 'colors.white'}
              >
                {item.value || '-'}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: 'none',
            outline: 'none',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {slice(items, 0, 3).map((item, index) => (
              <Box
                key={`tooltip-${JSON.stringify(item)}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '0 20px',
                }}
              >
                <Typography variant="textTertiary">{item.title}</Typography>
                <Typography
                  variant="textPrimary"
                  color={item.isError ? 'error.main' : 'colors.white'}
                >
                  {item.value || '-'}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              height: '70px',
              width: '1px',
              backgroundColor: 'grey.dark',
              margin: 'auto',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {slice(items, 3, items.length).map((item, index) => (
              <Box
                key={`tooltip-${JSON.stringify(item)}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '0 20px',
                }}
              >
                <Typography variant="textTertiary">{item.title}</Typography>
                <Typography
                  variant="textPrimary"
                  color={item.isError ? 'error.main' : 'colors.white'}
                >
                  {item.value || '-'}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </TooltipsWrapper>
  )
}

export default CustomTooltip
