import { memo, useState } from 'react'
import { Box, Tooltip, Typography, LinearProgress } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { formatShortDateTime } from 'util/date'
import { useQuery } from 'metrics/hooks/useQuery'
import { DataGrid } from '@mui/x-data-grid'

import { getFormattedDuration } from 'trace/util/span-chart'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import ErrorState from 'common/components/ErrorState'
import { Chip } from 'common/components/Chip'
import Button from 'common/components/Button'
import { ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react'
import { useDebounce } from 'react-use'

const initialPage = withDefault(NumberParam, 1)
const size = 50
const debounceDelay = 800
const Pagination = memo(function Pagination({
  setCurrentPage,
  currentPage,
  refresh,
  isValidating,
  currentSize,
  loading,
}) {
  const [page, setPage] = useState(currentPage)

  useDebounce(
    () => {
      setPage(currentPage)
    },
    debounceDelay,
    [currentPage]
  )

  const hasNext = currentSize && currentSize === size

  const from = (page === 1 && currentSize ? 1 : (page - 1) * size) || 0
  const to = page === 1 ? currentSize : from + size || 0
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        padding: '0 10px',
        gap: 2,
      }}
    >
      <Typography variant="textSecondary" color="text.secondary">
        Showing {`${from?.toLocaleString('en-US')}-${to?.toLocaleString('en-US')}`}
      </Typography>
      <Button
        size="small"
        variant="outlined"
        disabled={currentPage === 1 || loading}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1)
          }
        }}
        sx={{
          minWidth: '30px',
          color: 'text.secondary',
        }}
      >
        <ChevronLeft size={15} />
      </Button>
      <Button
        size="small"
        variant="outlined"
        disabled={!hasNext || loading}
        onClick={() => {
          setCurrentPage((prev) => prev + 1)
        }}
        sx={{
          minWidth: '30px',
          color: 'text.secondary',
        }}
      >
        <ChevronRight size={15} />
      </Button>
      <Button
        loading={isValidating}
        variant="text"
        sx={{
          color: 'text.secondary',
          minWidth: 30,
          padding: '5px',
        }}
        onClick={refresh}
      >
        <RefreshCw size={15} />
      </Button>
    </Box>
  )
})

const CustomErrorOverlay = ({ error }) =>
  error ? (
    <NoData>
      <ErrorState reloadButtonLabel="" />
    </NoData>
  ) : null
const CustomNoDataOverlay = () => <NoData>No trace data available for this time frame.</NoData>
const renderHeader = (params) => (
  <Typography variant="textSecondary" color="text.secondary" sx={{ marginRight: '5px' }}>
    {params?.colDef?.headerName}
  </Typography>
)
const renderNumberCell = (params) => {
  const isError = params.field === 'caught_errors' || params.field === 'uncaught_errors'
  return params.value ? (
    <NumberCell
      sx={{
        backgroundColor: isError ? 'error.main' : 'primary.main',
      }}
    >
      {params.value}
    </NumberCell>
  ) : (
    '-'
  )
}

const traceParams = [
  {
    key: 'explorerTraceId',
    value: 'trace_id',
    type: StringParam,
  },
  {
    key: 'explorerTraceTime',
    value: 'start_time',
    type: StringParam,
  },
]
export const ExplorerTable = () => {
  const theme = useTheme()
  const [, setQuery] = useQueryParams(
    traceParams.reduce((acc, param) => ({ ...acc, [param.key]: param.type }), {})
  )

  const [sortModel, setSortModel] = useState([
    {
      field: 'start_time',
      sort: 'desc',
    },
  ])

  const [currentPage, setCurrentPage] = useQueryParam('explorerPage', initialPage)

  const { data, loading, isValidating, error, refresh } = useQuery({
    query: 'aws_lambda_traces',
    size,
    currentPage,
    sort: {
      by: sortModel?.[0]?.field,
      order: sortModel?.[0]?.sort,
    },
    debounceDelay,
  })

  const rows = data?.results || []
  // const total = data?.total || 0
  const currentSize = rows?.length
  const maxDuration = data?.results?.reduce((max, item) => {
    const { duration } = getFormattedDuration(item)
    if (duration >= max) return duration
    return max
  }, 0)

  const columns = [
    {
      headerName: 'Time',
      field: 'start_time',
      flex: 1.1,
      minWidth: 120,
      type: 'dateTime',

      valueGetter: ({ value }) => formatShortDateTime(value),
      renderHeader,
    },
    {
      headerName: 'Resource Name',
      field: 'resource_name',
      type: 'string',
      flex: 3,

      renderCell: ({ value }) => (
        <Tooltip title={value || ''}>
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} color="text.primary">
            {value}
          </Typography>
        </Tooltip>
      ),
      renderHeader,
    },
    {
      headerName: 'Cold Start',
      field: 'is_cold_start',

      flex: 0.7,
      renderHeader,
      renderCell: ({ value }) => (value ? <Chip label="true" /> : '-'),
    },

    {
      headerName: 'Duration',
      field: 'duration',
      type: 'string',
      flex: 1.4,
      renderCell: (params) => {
        const { rawDuration: duration, formatted } = getFormattedDuration(params.row)

        return (
          <Box width="100%">
            <DurationProgressStandard
              variant="determinate"
              color="inherit"
              value={Math.min(100, (100 * duration) / maxDuration)}
            />
            <Typography variant="textTertiary" color="text.secondary" sx={{ minWidth: '50px' }}>
              {formatted.replace(/\s/, '')}
            </Typography>
          </Box>
        )
      },
      renderHeader,
    },

    {
      headerName: 'Uncaught Errors',
      field: 'uncaught_errors',
      flex: 1,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }) => row.uncaught_errors || 0,
      renderHeader,
      renderCell: renderNumberCell,
    },
    {
      headerName: 'Caught Errors',
      field: 'caught_errors',
      flex: 1,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }) => row.caught_errors || 0,
      renderHeader,
      renderCell: renderNumberCell,
    },

    {
      headerName: 'Warnings',
      field: 'warnings',
      type: 'number',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row.warnings || 0,
      renderHeader,
      renderCell: renderNumberCell,
    },
    {
      headerName: 'SDK Errors',
      field: 'sdkErrors',
      flex: 1,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }) => row.sdk_user_errors + row.sdk_internal_errors || 0,
      renderHeader,
      renderCell: renderNumberCell,
    },
  ]

  return (
    <Box
      component={motion.div}
      sx={{
        height: '100%',
        position: 'relative',
      }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.3, ease: 'easeOut' } }}
    >
      {/* <Box
        sx={{
          position: 'absolute',
          top: '-43px',
          left: '20px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: 'text.secondary',
        }}
      >
        <IconAwsLambda size={15} sx={{ color: 'text.primary' }} />
        <Typography variant="h4" color="text.primary">
          Sampled Traces
        </Typography>
      </Box> */}
      <DataGrid
        rows={rows}
        getRowId={(row) => row.trace_id}
        columns={columns}
        components={{
          LoadingOverlay: TableLoadingProgressBar,
          ErrorOverlay: CustomErrorOverlay,
          NoRowsOverlay: CustomNoDataOverlay,
          Pagination,
        }}
        componentsProps={{
          errorOverlay: { error },
          pagination: { currentSize, refresh, currentPage, setCurrentPage, isValidating, loading },
        }}
        error={error}
        sortingMode="server"
        loading={loading || isValidating}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        onRowClick={(trace) => {
          setQuery(
            traceParams.reduce(
              (acc, param) => ({
                ...acc,
                [param.key]:
                  param.key === 'explorerTraceTime'
                    ? new Date(trace.row?.[param.value])?.toISOString()
                    : trace.row?.[param.value],
              }),
              {}
            )
          )
        }}
        rowSpacingType="margin"
        sx={{
          border: 'none',
          borderTop: `1px solid ${theme.palette.border.main}`,
          borderRadius: 0,
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiToolbar-root .MuiTablePagination-displayedRows': {
            color: theme.palette.text.secondary,
          },
          '& .MuiToolbar-root .MuiTablePagination-actions': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: theme.palette.grey.dark,
          },

          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
            color: theme.palette.text.secondary,
          },
          '& .MuiDataGrid-cellContent': {
            color: theme.palette.text.secondary,
          },
        }}
      />
    </Box>
  )
}

const TableLoadingProgressBar = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 0,
  height: 2,
}))
const DurationProgressStandard = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey.dark,
}))

const NoData = styled(Box)(() => ({
  marginTop: '15%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '0 20px',
}))

const NumberCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.secondary.main,
  fontSize: '10px',
  fontWeight: '800',
  background: theme.palette.primary.main,
  borderRadius: '14px',
  width: '18px',
  height: '18px',
  marginLeft: '10px',
  padding: '0 0 0 0.5px',
}))
