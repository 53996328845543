import { useContext, useEffect, useState } from 'react'

import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { Box, styled, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import Button from 'common/components/Button'
import { getIntegrationById, updateIntegration } from 'util/core.api'
import { InfoBox } from '../../InfoBox'
import { ellipsisKeyframes } from 'common/components/LoadingEllipsis'
import { AppContext } from 'app/context/AppContext'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { sleep } from '../../../../../common/utils/sleep'
import { Checkbox } from 'common/components/Checkbox'
import { useEnableBulkInstrumentation } from 'common/hooks/useEnableBulkInstrumentation'

let interval

// create a custom parameter with a default value
const deployRoleViewParam = withDefault(StringParam, 'initialization')

export const DeployRole = ({ handleNext }) => {
  const navigate = useNavigate()
  const { setIsConfigMissing, activeOrg } = useContext(AppContext)
  const [enableDevMode, setEnableDevMode] = useState(true)
  const [redirectUrl] = useQueryParam('redirectUrl', StringParam)
  const [integrationId] = useQueryParam('integrationId', StringParam)
  const [deployRoleView, setDeployRoleView] = useQueryParam('deployRoleView', deployRoleViewParam)

  const { enableTargetFunctions, beginCheckForFunctions } = useEnableBulkInstrumentation({
    orgId: activeOrg?.orgId,
    targetIntegration: integrationId,
  })
  /**
   * Get integration and refresh every 1 second
   */
  const { data: integrationData } = useSWR(
    integrationId && ['integration', integrationId],
    () => getIntegrationById({ integrationId }),
    {
      refreshInterval: 1000,
    }
  )

  const isSettingUp = integrationData && integrationData?.status === 'settingup'
  const isComplete =
    integrationData?.status === 'alive' && integrationData?.syncStatus === 'complete'
  const isFailed =
    integrationData?.status === 'failed' && integrationData?.syncStatus === 'incomplete'

  const errorMessage =
    integrationData?.lastError ||
    'Something went wrong syncing resources. Please try syncing again on the integrations page.'

  const handleIntegrationCompleted = async () => {
    if (enableDevMode) {
      // Sleep for 2 seconds to allow a little extra time for OpenSearch to sync inventory
      // so when we give the option to enable dev mode on all functions with the environment set to dev
      // we have the correct list of inventory to work with
      await sleep(2000)

      // Check For Dev Mode Functions
      const { doesNotHaveInstrumentation, resources } = await beginCheckForFunctions()
      if (doesNotHaveInstrumentation) {
        await enableTargetFunctions({
          incomingResources: resources,
        })
      }
    }
    await updateIntegration({
      integrationId,
      namespace: 'default',
      environment: 'production',
    })
    handleNext({
      params: {
        redirectUrl: undefined,
      },
    })
    await sleep(1000)
    // Let the app context know that we have at least one integration configured
    setIsConfigMissing(false)
    setDeployRoleView(undefined)
  }
  useEffect(() => {
    if (isComplete) {
      handleIntegrationCompleted()
    } else if (isSettingUp) {
      setDeployRoleView('resources')
    } else if (isFailed) {
      setDeployRoleView('failed')
    }
  }, [integrationData])

  const openCloudFormation = async () => {
    window.open(redirectUrl, '_blank').focus()
  }

  useEffect(() => {
    return () => clearInterval(interval)
  }, [])

  return (
    <Box
      margin="-5% auto 0 auto"
      width="100%"
      padding="0 20px"
      maxWidth="600px"
      minHeight="calc(var(--app-height) - 118px)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="30px"
    >
      {deployRoleView !== 'failed' && (
        <motion.div
          initial={{ opacity: [0, 1] }}
          animate={{ opacity: 1, transition: { delay: 2, duration: 2, ease: 'easeOut' } }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '0 0 10px 0',
          }}
        >
          <SlowLinearProgress>
            <div className="progressBarBackground">
              <div className="progressBarValue"></div>
            </div>
          </SlowLinearProgress>
        </motion.div>
      )}
      {deployRoleView === 'resources' ? (
        <>
          <Typography variant="h1">Setting Up Integration</Typography>
          <Title variant="h3">This may take 5-10 minutes</Title>
          <Typography variant="textPrimary" sx={{ margin: '0 0 10px 0' }}>
            We've successfully connected to your AWS account. Now we are performing the following.
            <br />
            <br />
            1. Automatically determining namespace and environment tags for your resources.
            <br />
            <br />
            2. Setting up CloudWatch metric streams to collect invocation data on all Lambda
            functions in your account.
            <br />
            <br />
            3. Subscribing to CloudTrail events to track new Lambda function deployments.
            <br />
            <br />
            4. Configuring Kinesis Firehose for delivery of metrics, logs and trace data.
            <br />
            <br />
            This may take a few minutes depending on how many resources are in your account. If you
            have active Lambda functions you'll start seeing their metrics within a few minutes.
          </Typography>
        </>
      ) : deployRoleView === 'failed' ? (
        <motion.div
          initial={{ x: -20, opacity: [0, 1] }}
          animate={{
            x: [-20, 0],
            opacity: 1,
            transition: { delay: 0.5, duration: 0.75, ease: 'easeOut' },
          }}
        >
          <ErrorMessage message={errorMessage} />

          <Button
            sx={{
              marginTop: 4,
            }}
            onClick={() => {
              handleNext({ endMe: true })
              navigate(`/${activeOrg?.orgName}/settings/integrations`)
            }}
            size="large"
            fullWidth
          >
            Go to integrations page
          </Button>
        </motion.div>
      ) : (
        <>
          <motion.div
            initial={{ x: -20, opacity: [0, 1] }}
            animate={{
              x: [-20, 0],
              opacity: 1,
              transition: { delay: 0.5, duration: 0.75, ease: 'easeOut' },
            }}
          >
            <Title variant="h1">Awaiting CloudFormation Deployment</Title>
          </motion.div>

          <motion.div
            initial={{ x: -20, opacity: [0, 1] }}
            animate={{
              x: [-20, 0],
              opacity: 1,
              transition: { delay: 0.75, duration: 0.75, ease: 'easeOut' },
            }}
          >
            <Typography variant="textPrimary">
              1. Log into the AWS account you want to connect.
              <br />
              <br />
              2. Click the button below to open AWS CloudFormation.
              <br />
              <br />
              3. Click “Create” in the AWS CloudFormation screen and come back here. This view will
              update when it succeeds.
              <br />
              <br />
            </Typography>
            <Checkbox
              label={'Auto-instrument AWS Lambdas that we detect are in development'}
              checked={enableDevMode}
              textVariant="textPrimary"
              labelColor="text.primary"
              style={{ paddingLeft: '0' }}
              onChange={(event) => setEnableDevMode(event.target.checked)}
            />
          </motion.div>

          <motion.div
            initial={{ x: -20, opacity: [0, 1] }}
            animate={{
              x: [-20, 0],
              opacity: 1,
              transition: { delay: 1, duration: 0.75, ease: 'easeOut' },
            }}
          >
            <Box display="flex" alignItems="center" sx={{ mb: '35px' }}>
              <Button
                publishEventProps={{
                  elementId: 'integration-open-cloudformation',
                  action: 'link_external',
                  actionDetails: {
                    href: redirectUrl,
                  },
                }}
                onClick={openCloudFormation}
                size="large"
                fullWidth
              >
                Deploy AWS CloudFormation
              </Button>
            </Box>
          </motion.div>

          <motion.div
            initial={{ x: -20, opacity: [0, 1] }}
            animate={{
              x: [-20, 0],
              opacity: 1,
              transition: { delay: 1.25, duration: 0.75, ease: 'easeOut' },
            }}
          >
            <InfoBox />
          </motion.div>
        </>
      )}
    </Box>
  )
}

const Title = styled(Typography)({
  '&:after': {
    display: 'inline-block',
    animation: `${ellipsisKeyframes} steps(1,end) 2s infinite`,
    content: '""',
  },
})

const SlowLinearProgress = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '2px',
  padding: '0',
  boxSizing: 'border-box',
  borderRadius: '10px',
  overflowX: 'hidden',

  '& .progressBarBackground': {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '2px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.grey.medium,
  },

  '& .progressBarValue': {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    animation: 'indeterminateAnimation 4s infinite ease-in-out',
    transformOrigin: '0% 50%',
  },
}))
