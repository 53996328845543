import { useContext, useRef, useState } from 'react'
import { AppBar, Box, Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { RenderSpansChart } from './RenderSpansChart'
import { formatLongDateTime } from 'util/date'
import { useTheme } from '@mui/styles'
import { has, range } from 'lodash'
import { Inspector } from 'inspector/components/Inspector'
import { Allotment } from 'allotment'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { TraceNotFound } from './TraceNotFound'
import CloseButton from 'common/components/CloseButton'
import { LoadingPage } from 'app/components/LoadingPage'
import { ROW_HEIGHT, TraceContext, TraceProvider } from '../context/Trace.context'
import { RenderSpansTree } from './RenderSpansTree'
import { FilterContext } from 'filter/context/FilterContext'
import { formatDuration } from 'util/format'

const RenderTrace = ({ close }) => {
  const theme = useTheme()
  const {
    trace,
    maxSpanVal,
    selectedSpan,
    selectedSpanDuration,
    logs,
    isLoading,
    isNotFound,
    traceEvents,
  } = useContext(TraceContext)
  const { setFilterValue } = useContext(FilterContext)
  const spansTreeRef = useRef()
  const spansChartRef = useRef()

  const [expandLogs, setExpandLogs] = useState(false)

  // Set Page Title
  usePageTitle(`Trace`)

  // Scroll handler
  const onScroll = (e, type) => {
    try {
      if (type === 'spansTree') {
        spansChartRef.current.scrollTop = e.target.scrollTop
      } else {
        spansTreeRef.current.scrollTop = e.target.scrollTop
      }
    } catch (err) {}
  }
  const selectedSpanErrorEventId =
    has(selectedSpan, 'parentSpanId') &&
    !selectedSpan?.parentSpanId &&
    traceEvents?.data?.find((event) => event?.tags?.error)?.eventId

  // Set Trace Headers
  const headerItems = []
  if (trace?.startTime) {
    headerItems.push({
      title: 'Date',
      value: formatLongDateTime(trace?.startTime),
    })
  }
  if (trace?.tags?.environment) {
    headerItems.push({
      title: 'Environment',
      value: trace?.tags?.environment,
    })
  }
  if (trace?.tags?.namespace) {
    headerItems.push({
      title: 'Namespace',
      value: trace?.tags?.namespace,
    })
  }
  if (trace?.tags?.aws?.lambda?.name) {
    headerItems.push({
      title: 'Function Name',
      value: trace.tags.aws.lambda.name,
    })
  }
  // Get selected span name for inspector header
  const selectedChildSpanName =
    selectedSpan?.name ||
    selectedSpan?.tags?.error?.name ||
    (selectedSpan?.tags?.warning && 'Warning')

  const Pane = Allotment.Pane

  // Handle Loading and Not Found
  if (isLoading) {
    return <LoadingPage />
  }
  if (isNotFound) {
    return <TraceNotFound close={close} />
  }
  const maxValMilliseconds = maxSpanVal * 0.001
  return (
    <BodyBackground>
      <Body
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.3, ease: 'easeOut' },
          borderTop: `1px solid ${theme.palette.border.main}`,
          borderRight: `1px solid ${theme.palette.border.main}`,
          borderBottom: `1px solid ${theme.palette.border.main}`,
          borderRadius: '0 6px 6px 6px',
        }}
      >
        <StyledAppBar position="sticky">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  paddingRight: 40,
                  marginRight: 20,
                  borderRight: `1px solid ${theme.palette.border.main}`,
                }}
              >
                Trace
              </Typography>
              <Divider sx={{ margin: '0 20px 0 0' }} />
              {headerItems.map((headerData, index) => (
                <Box margin={'0 50px 0 0'} key={`trace-header-item-${index}`}>
                  <Typography variant="textSecondary" color="text.secondary">
                    {headerData.title}
                  </Typography>
                  <Typography variant="textSecondary" color="text.primary">
                    {headerData.value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <CloseButton onClick={close} aria-label="close details" size="medium" />
          </Box>
        </StyledAppBar>

        <Box
          sx={{
            height: `calc(var(--app-height) - 74px)`,
          }}
        >
          <Allotment horizontal onVisibleChange={(_index, value) => setExpandLogs(value)}>
            {/* Spans items tree */}
            <Pane snap preferredSize={300} visible={!expandLogs}>
              <Box
                height="100%"
                ref={spansTreeRef}
                onScroll={(e) => onScroll(e, 'spansTree')}
                sx={{
                  paddingTop: `${ROW_HEIGHT}px`,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <RenderSpansTree />
              </Box>
            </Pane>
            {/* Spans progress bars chart */}
            <Pane minSize={1} visible={!expandLogs}>
              <Box
                position="relative"
                height="100%"
                ref={spansChartRef}
                onScroll={(e) => onScroll(e, 'spansChart')}
                sx={{
                  paddingTop: `${ROW_HEIGHT}px`,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Box>
                  <Box
                    style={{
                      height: ROW_HEIGHT,
                      width: '100%',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Intervals left="5px">0ms</Intervals>
                    <Intervals left="25%">{formatDuration(maxValMilliseconds * 0.25)}</Intervals>
                    <Intervals left="50%">{formatDuration(maxValMilliseconds * 0.5)}</Intervals>
                    <Intervals left="75%">{formatDuration(maxValMilliseconds * 0.75)}</Intervals>
                    <Intervals paddingRight={'5px'} alignSelf="flex-end">
                      {formatDuration(maxValMilliseconds)}
                    </Intervals>
                  </Box>
                </Box>
                <RenderSpansChart span={trace} />
                {range(3).map((num) => (
                  <Box
                    key={`timeline-line-${num}`}
                    position="absolute"
                    borderLeft={`1px solid ${theme.palette.grey.dark}`}
                    height="100%"
                    top={0}
                    left={`${(num + 1) * 25}%`}
                    zIndex={3}
                  />
                ))}
              </Box>
            </Pane>
            <Pane minSize={300}>
              {selectedSpan && (
                <Inspector
                  isSpan
                  parentSpanName={'aws.lambda'}
                  childSpanName={
                    selectedChildSpanName !== 'aws.lambda' ? selectedChildSpanName : null
                  }
                  selectedSpanName={selectedSpan.name || selectedSpan.eventName}
                  timestamp={selectedSpan.timestamp}
                  startTime={selectedSpan.startTime}
                  endTime={selectedSpan.endTime}
                  tags={selectedSpan.tags}
                  durations={selectedSpanDuration}
                  activityGroup={logs}
                  input={selectedSpan.input}
                  output={selectedSpan.output}
                  customTags={selectedSpan.customTags}
                  span={selectedSpan}
                  defaultExpanded={selectedSpan.eventId && 'event'}
                  onErrorEventClick={
                    selectedSpanErrorEventId
                      ? () => {
                          setFilterValue('explorerTraceSpanId', selectedSpanErrorEventId)
                        }
                      : null
                  }
                />
              )}
            </Pane>
          </Allotment>
        </Box>
      </Body>
    </BodyBackground>
  )
}
export const Trace = ({ close }) => {
  return (
    <TraceProvider>
      <RenderTrace close={close} />
    </TraceProvider>
  )
}
const Intervals = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  color: theme.palette.text.secondary,
  height: '100%',
  zIndex: 10,
  fontSize: theme.typography.textSecondary.fontSize,
  paddingBottom: 10,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderBottom: `1px solid ${theme.palette.border.main}`,
  zIndex: '5',
  padding: '15px 25px 20px 25px',
  boxShadow: 'none',
}))

const BodyBackground = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'var(--app-height)',
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.background.default,
}))

const Body = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 'var(--app-height)',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
}))
