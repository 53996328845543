export const getSpanId = (span) => {
  if (!span) return null
  const isEvent = !!span?.eventId
  const idKey = isEvent ? 'eventId' : 'spanId'
  const id = span[idKey]
  return id
}
/**
 * Check if span is an event or trace span and return correct ids
 * @param {Object} span - span object to get details
 * @param {Array} timelineSpans - timeline spans array to get more enriched details
 * @returns
 */
export const getSpanDetails = ({ span, timelineSpans, traceEvents }) => {
  if (!span) return {}
  const isEvent = !!span?.eventId

  const id = getSpanId(span)
  const currentSpan = timelineSpans?.find((sp) => getSpanId(sp) === id) || span
  const isRootSpan = !currentSpan?.parentSpanId
  const hasWarning = currentSpan?.tags?.warning?.type

  const hasError =
    (isRootSpan && traceEvents?.data?.some((item) => item?.tags?.error?.type)) ||
    currentSpan?.tags?.error?.type ||
    currentSpan?.spans?.some((span) => span?.tags?.error?.type)

  return {
    isEvent,
    hasError,
    hasWarning,
    id,
    ...currentSpan,
  }
}
