import { useContext } from 'react'
import { styled, Box } from '@mui/material'
import { FilterProvider } from 'filter/context/FilterContext'
import { ExplorerTable } from 'explorer/components/ExplorerTable'
import { ExplorerSidebar } from 'explorer/components/ExplorerSidebar'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { ModalTrace } from 'trace/components/ModalTrace'
import { useNotificationTopBannerIsVisible } from 'app/components/NotificationTopBanner'
import { EnableBulkInstrumentation } from 'common/components/EnableBulkInstrumentation'
import { AppContext } from 'app/context/AppContext'
import InvocationsGraph from 'widgets/components/InvocationsGraph'
import { useQuery } from 'metrics/hooks/useQuery'

const Explorer = () => {
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const { data: hasData } = useQuery({ query: 'metrics_exist' })

  const { notificationBarHeight } = useNotificationTopBannerIsVisible()

  usePageTitle(`Explorer`)

  return (
    <EnableBulkInstrumentation
      orgId={orgId}
      hasExistingData={hasData}
      bypassKey="sls-prod-mode-prompt"
      instrumentationType="prod"
    >
      <Container notibarheight={notificationBarHeight}>
        <Box width="25%">
          <ExplorerSidebar />
        </Box>
        <Box
          width="75%"
          sx={{
            height: 'var(--app-height)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              padding: '20px',
              height: 'fit-content',
              width: '100%',
            }}
          >
            <InvocationsGraph showRefreshButton />
          </Box>
          <ExplorerTable />
        </Box>
      </Container>
    </EnableBulkInstrumentation>
  )
}

export const ExplorerWrapper = () => {
  return (
    <ErrorBoundary>
      <FilterProvider page="explorer">
        <Explorer />
        <ModalTrace />
      </FilterProvider>
    </ErrorBoundary>
  )
}

const Container = styled('div')(({ notibarheight }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: `calc(100vh - ${notibarheight}px)`,
  width: '100%',
  boxSizing: 'border-box',
  minWidth: '800px',
  maxWidth: 'calc(100vw - 80px)', // view width minus sidebar
  overflow: 'hidden',
}))

export default ExplorerWrapper
