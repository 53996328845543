import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

export const NavBarItem = styled(motion.div)(({ theme, type, disabled }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: type === 'view' ? 'flex-start' : 'center',
  padding: '0 45px 0 45px',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: '100%',
  flex: type === 'view' ? 8 : type === 'timeframe' ? 2 : 0,
  transition: 'all 0.13s ease',
  userSelect: 'none',
  borderRight: `1px solid ${theme.palette.border.main}`,
  opacity: disabled ? 0.6 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
  '& svg': {
    transition: 'all 0.1s ease-in-out',
  },
  '&:hover:not([disabled])': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey.dark,
  },
}))

export const NavBarItemDropdown = styled(motion.div)(
  ({ theme, width = '420px', left = '50%', top = 100 }) => ({
    position: 'absolute',
    zIndex: 2,
    top,
    left,
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    width,
    maxHeight: '70vh',
    borderRadius: 4,
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.border.main}`,
    boxShadow: `0px 4px 6px ${theme.palette.shadow.main}`,
    cursor: 'auto',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 30,
      top: -16,
      left: '50%',
      width: 30,
      height: 30,
      borderRadius: '4px 0 0 0 ',
      background: theme.palette.background.default,
      transform: 'translateX(-50%) rotate(45deg)',
      borderTop: `1px solid ${theme.palette.border.main}`,
      borderLeft: `1px solid ${theme.palette.border.main}`,
    },
  })
)

export const NavBarItemDropdownInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 2,
  overflow: 'scroll',
  borderRadius: '4px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
