import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  styled,
  Box,
  FormControl,
  Typography,
  InputAdornment,
  TextField,
  CircularProgress,
  IconButton,
  Skeleton,
  Badge,
  Popper,
  ClickAwayListener,
} from '@mui/material'
import InputUnstyled from '@mui/base/InputUnstyled'
import { useSnackbar } from 'notistack'
import { IconPencil } from 'common/icons/IconPencil'
import { motion } from 'framer-motion'
import { Search, X, Check } from 'lucide-react'
import Button from 'common/components/Button'
import { updateIntegration } from 'util/core.api'
import { IntegrationContext, IntegrationProvider } from '../../context/integration.context'
import { Resource } from './Resource'
import { ResourcesFilterDropdown } from './ResourcesFilterDropdown'
import { isEmpty, keys, range } from 'lodash'
import { getOS } from 'util/platform'
import { LabelsRow } from './LabelsRow'
import { ConfirmResourcesChangesDialog } from './ConfirmResourcesChangesDialog'
import { FilterSearchDropdown } from '../../../common/components/FilterSearchDropdown'
import { integrationFilters } from 'filter/context/FilterContext'
import { CustomPagination } from 'common/components/CustomPagination'

const maxWidth = '1800px'

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 20 } = {}) => (
  <Box>
    {range(size)?.map((num) => (
      <Box
        key={`resources-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton variant="rect" width="100%" height="52px" sx={{ marginBottom: 1.5 }} />
      </Box>
    ))}
  </Box>
)
export const EnableResourcesContent = ({ handleNext, headerLogo }) => {
  const listContainer = useRef()
  /**
   * Search input ref used to focus on input when hotkey  is pressed
   */
  const searchInputRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    integrationId,
    integration,
    changedResources,
    total,
    resources,
    loading,
    refresh,
    setOpenConfirmDialog,
    setSearch,
    search,
    currentPage,
    setCurrentPage,
    size,
    isValidating,
    isMaximumSelected,
    onResourcePropertyChange,
  } = useContext(IntegrationContext)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [savingAlias, setSavingAlias] = useState(false)
  const [aliasInput, setAliasInput] = useState(
    integration?.alias || integration?.vendorAccount || 'Loading integration...'
  )

  const [editAlias, setEditAlias] = useState(false)

  const pagesCount = useMemo(() => Math.ceil(total / size) || 1, [total, size])

  useEffect(() => {
    setAliasInput(integration?.alias || integration?.vendorAccount)
  }, [integration])

  /**
   * Clear any active snackbar on unmount
   */
  useEffect(() => {
    return () => {
      closeSnackbar()
    }
  }, [])

  const closeDialog = () => {
    handleNext()
  }

  // Process short cut keys
  const shortCutCommands = useCallback((e) => {
    const cmdSelected = e.ctrlKey || e.metaKey
    if (cmdSelected && e.key === 'e') {
      e.preventDefault()
      searchInputRef?.current?.focus()
    }
  }, [])

  // Register short cut keys with the keydown event
  useEffect(() => {
    window.addEventListener('keydown', shortCutCommands)
    return () => {
      window.removeEventListener('keydown', shortCutCommands)
    }
  }, [shortCutCommands])

  const saveAlias = async () => {
    setSavingAlias(true)
    try {
      await updateIntegration({
        integrationId,
        alias: aliasInput,
      })
      setEditAlias(false)
    } catch (error) {
      enqueueSnackbar('Failed to save integration alias. Pleas try again.', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    } finally {
      setSavingAlias(false)
    }
  }
  const openPopper = Boolean(anchorEl?.target)
  const handleClosePopper = () => {
    setAnchorEl(null)
  }
  return (
    <Container>
      <ConfirmResourcesChangesDialog />
      {/* Popper to show environment and namespace dropdowns */}

      <StyledPopper
        id={openPopper ? anchorEl?.resource?.id : undefined}
        open={openPopper}
        anchorEl={anchorEl?.target}
      >
        <ClickAwayListener onClickAway={handleClosePopper}>
          <div>
            <FilterSearchDropdown
              filter={integrationFilters?.find((filter) => filter.name === anchorEl?.key)}
              onChange={(option) => {
                setAnchorEl(null)
                onResourcePropertyChange({
                  value: option?.value,
                  key: anchorEl?.key,
                  resource: anchorEl.resource,
                })
              }}
              selectedValue={anchorEl?.resource?.[anchorEl?.key]}
              collapsable={false}
              allowAddNew
              animate={false}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
      <Header>
        <HeaderRow>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="5px">
            {headerLogo}
            <Typography
              variant="h4"
              onClick={() => {
                setEditAlias(true)
              }}
            >
              /
            </Typography>

            {!editAlias ? (
              <>
                <Typography
                  variant="h4"
                  onClick={() => {
                    setEditAlias(true)
                  }}
                >
                  {aliasInput}
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditAlias(true)
                  }}
                >
                  <IconPencil size={16} />
                </IconButton>
              </>
            ) : (
              <>
                <AliasInput
                  autoFocus
                  onKeyUp={(event) => {
                    if (event.code === 'Enter') {
                      saveAlias()
                    }
                  }}
                  onBlur={() => {
                    if (aliasInput !== integration?.alias) {
                      saveAlias()
                    }
                  }}
                  onChange={(e) => setAliasInput(e.currentTarget.value)}
                  value={aliasInput}
                />
                {savingAlias ? (
                  <CircularProgress size={15} />
                ) : (
                  <IconButton onClick={saveAlias}>
                    <Check size={15} />
                  </IconButton>
                )}
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="15px">
            <Typography variant="textSecondary" color="text.secondary">
              Account Number: {integration?.vendorAccount}
            </Typography>
            <Button color="secondary" sx={{ minWidth: '91px' }} onClick={closeDialog}>
              Close
            </Button>
            <Badge
              badgeContent={keys(changedResources)?.length}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Button
                sx={{ minWidth: '91px' }}
                onClick={() => setOpenConfirmDialog(true)}
                disabled={isEmpty(changedResources) || isMaximumSelected}
              >
                Save
              </Button>
            </Badge>
          </Box>
        </HeaderRow>

        <HeaderRow>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="15px">
            <FormControl sx={{ maxWidth: '100%', width: '350px' }} variant="standard">
              <TextField
                inputRef={searchInputRef}
                id="search-input"
                onChange={(e) => setSearch(e.target.value)}
                value={search || ''}
                InputProps={{
                  style: {
                    paddingLeft: 10,
                    paddingRight: 10,
                  },
                  placeholder: 'Search for anything...',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {search ? (
                        <IconButton onClick={() => setSearch(undefined)}>
                          <X size={16} />
                        </IconButton>
                      ) : (
                        <Typography variant="textTertiary" color="text.secondary">
                          {getOS() === 'macos' || 'ios' ? '⌘ + E' : 'ctrl + E'}
                        </Typography>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <ResourcesFilterDropdown />
          </Box>
          <CustomPagination
            size={size}
            total={total}
            pageCount={pagesCount}
            currentPage={currentPage}
            isValidating={isValidating}
            loading={loading}
            onChange={(page) => {
              setCurrentPage(page)
              listContainer.current.scrollTop = 0
            }}
            refresh={refresh}
          />
        </HeaderRow>
        <LabelsRow maxWidth={maxWidth} />
      </Header>
      <BorderBottom />
      <ListContainer
        ref={listContainer}
        height={
          listContainer.current
            ? `calc(100vh - ${listContainer.current.getBoundingClientRect().top + 25}px)`
            : undefined
        }
        id="scrollableDiv"
      >
        <ListContainerInner>
          {loading || !integration ? (
            <SkeletonLoading size={size} />
          ) : Array.isArray(resources) && resources?.length === 0 && loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              minHeight="calc(100vh - 350px)"
              height="100%"
            >
              No resources found{search?.trim() === '' ? ' in this account' : ''}.
            </Box>
          ) : (
            resources.map((resource, index) => (
              <Resource
                key={resource.id}
                resource={resource}
                index={index}
                setAnchorEl={setAnchorEl}
              />
            ))
          )}
        </ListContainerInner>
      </ListContainer>
    </Container>
  )
}

export const EnableResources = ({ handleNext, headerLogo }) => {
  return (
    <IntegrationProvider handleNext={handleNext}>
      <EnableResourcesContent handleNext={handleNext} headerLogo={headerLogo} />
    </IntegrationProvider>
  )
}

const AliasInput = styled(InputUnstyled)(({ theme }) => ({
  '& input': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    border: 'none',
    padding: '0',
    fontSize: '15px',
    lineHeight: 'unset',
    '&:focus-visible': {
      outline: 'none',
    },
  },
}))

const BorderBottom = styled(Box)(({ theme }) => ({
  bottom: 0,
  width: '100%',
  backgroundColor: 'transparent',
  height: '1',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}))

const ListContainer = styled(Box)({
  width: '100%',
  overflow: 'scroll',
})
const ListContainerInner = styled(Box)({
  padding: '15px 25px',
  width: '100%',
  minHeight: '50px',
  overflowX: 'hidden',
  maxWidth,
  margin: '0 auto',
})

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const Header = styled(Box)(() => ({
  width: '100%',
  padding: '25px 25px 10px 25px',
  maxWidth,
  margin: '0 auto',
}))
const HeaderRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '20px',
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  // border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  borderRadius: 4,
  width: 300,
  zIndex: theme.zIndex.modal,
  // padding: 10,
  backgroundColor: theme.palette.background.paper,
}))
