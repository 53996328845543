import { Button, Box, Typography, Skeleton, Grid } from '@mui/material'
import { isFunction, range, upperFirst } from 'lodash'
import { animateProps, SectionTitle, Title } from './billingUtils'
import { motion } from 'framer-motion'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'app/context/AppContext'
import { useTheme } from '@mui/styles'
import { DowngradePlanDialog } from './DowngradePlanDialog'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import { Link } from 'common/components/Link'

const cardWidth = '100%'

export const Plans = () => {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgId, orgName } = activeOrg

  /**
   * Query param to show/hide upgrade dialog
   */
  const [, setOpenPaymentDialog] = useQueryParams({
    upgradePlan: BooleanParam,
    discountCode: StringParam,
  })

  const [openDowngradeDialog, setOpenDowngradeDialog] = useState(false)

  useEffect(() => {
    if (openDowngradeDialog) {
      setOpenPaymentDialog({
        upgradePlan: undefined,
      })
    }
  }, [openDowngradeDialog])

  /**
   * Fetch current subscription and usage
   */
  const {
    isBusinessPlan,
    loadingSubscription,
    isTeamPlan,
    teamNames,
    businessNames,
    currentPlan,
    orgSubscription,
    plans,
  } = useOrgUsage({
    orgId,
  })
  const isEqualCurrentPlan = (plan) =>
    plan === currentPlan ||
    (teamNames.includes(plan) && isTeamPlan) ||
    (businessNames.includes(plan) && isBusinessPlan)
  const getButtonProps = (plan, cta) => {
    const props = {
      free: {
        color: currentPlan === 'free' ? 'primary' : 'error',
        children: currentPlan === plan ? 'Current Plan' : 'Downgrade',
        disabled: currentPlan === plan,
        onClick: () => setOpenDowngradeDialog(true),
      },
      team: {
        color: 'primary',
        children: isTeamPlan ? 'Current Plan' : 'Upgrade',
        disabled: isTeamPlan && teamNames.includes(plan),
        onClick: cta ? cta((params) => setOpenPaymentDialog(params)) : () => {},
      },
      business: {
        color: 'primary',
        children: cta ? 'Upgrade' : 'Contact Sales',
        disabled: false,
        onClick: cta ? cta((params) => setOpenPaymentDialog(params)) : () => {},
        href: cta ? undefined : 'https://www.serverless.com/sales',
        target: cta ? undefined : '_blank',
      },
    }
    return props[plan]
  }

  return (
    <Box>
      <DowngradePlanDialog
        openDowngradeDialog={openDowngradeDialog}
        setOpenDowngradeDialog={setOpenDowngradeDialog}
      />
      <Box component={motion.div} {...animateProps()}>
        <SectionTitle title="Plans" />
      </Box>
      <Grid container spacing={2}>
        {loadingSubscription
          ? range(3).map((num) => (
              <Grid item xs={4} key={`skeleton-plan-${num}`}>
                <Box {...animateProps({ delay: `0.${num + 1}` })} component={motion.div}>
                  <Skeleton
                    variant="rectangular"
                    width={cardWidth}
                    height={320}
                    style={{ borderRadius: '6px' }}
                  />
                </Box>
              </Grid>
            ))
          : plans.map(({ title, details, cta }, idx) => (
              <Grid item xs={4} key={`billing-plan-${title}`}>
                <Box
                  sx={{
                    borderRadius: '6px',
                    padding: 3,
                    width: cardWidth,
                    minHeight: 380,
                    display: 'flex',
                    flexDirection: 'column',

                    border: `1px solid ${
                      isEqualCurrentPlan(title)
                        ? theme.palette.primary.main
                        : theme.palette.border.main
                    }`,
                  }}
                  component={motion.div}
                  {...animateProps({ delay: `0.${idx + 1}` })}
                >
                  <Typography variant="h1" gutterBottom>
                    {upperFirst(title)}
                  </Typography>
                  <Box mt={1}>
                    {isBusinessPlan && title === 'business' ? (
                      <Typography variant="h6">
                        <Link to={`/${orgName}/settings/billing/businessPlan`}>
                          Click here to view your pricing plan.
                        </Link>
                      </Typography>
                    ) : (
                      details.map((option, idx) => (
                        <Box
                          key={`billing-plan-${title}-option-${idx}`}
                          sx={{ marginBottom: '15px' }}
                        >
                          {option.render ? (
                            option.render
                          ) : (
                            <>
                              <Title
                                title={
                                  isFunction(option.title)
                                    ? option.title(orgSubscription)
                                    : option.title
                                }
                              />
                              <Typography color="text.secondary">{option.subtitle}</Typography>
                            </>
                          )}
                        </Box>
                      ))
                    )}
                  </Box>

                  {!isBusinessPlan && (
                    <Button
                      style={{ marginTop: 'auto' }}
                      variant="contained"
                      {...getButtonProps(title, cta)}
                    />
                  )}
                </Box>
              </Grid>
            ))}
      </Grid>
      {!isBusinessPlan && (
        <Box component={motion.div} {...animateProps({ delay: `0.${plans.length + 1}` })} mt={2}>
          <Typography variant="subtitle1" color="text.secondary">
            Traces include end to end tracing with spans and logs.
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            All plans include unlimited members.
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            You can upgrade and downgrade at any time.
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Visit{' '}
            <Link target="_blank" to="https://www.serverless.com/console/pricing">
              serverless.com/console/pricing
            </Link>{' '}
            for pricing details and billing FAQ.
          </Typography>
        </Box>
      )}
    </Box>
  )
}
