import { Box, CircularProgress, styled, Tooltip, Typography } from '@mui/material'
import React, { isValidElement } from 'react'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import Button from 'common/components/Button'
import { RefreshCw } from 'lucide-react'
import { Link } from 'common/components/Link'

const HeadingContainer = styled('div')(({ height }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'space-between',
  height,
  marginBottom: '20px',
}))

const LabelsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 40,
}))

const ChartHeader = ({
  title,
  description,
  stats,
  onRefresh = null,
  isValidating,
  loading,
  error,
}) => {
  return (
    <HeadingContainer>
      <Tooltip title={description}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <IconAwsLambda size={15} />
          <Typography variant="h4">{title}</Typography>

          {isValidating && !loading && <CircularProgress size={12} thickness={5} />}
        </Box>
      </Tooltip>
      {!loading && !error && (
        <LabelsContainer>
          {stats?.map(({ value, description, isError, link }, index) =>
            isValidElement(value) ? (
              <Box key={`chart-heading-stats-${index}`}>{value}</Box>
            ) : (
              <Link
                to={link}
                underline="none"
                style={{ cursor: link ? 'pointer' : 'default' }}
                key={`chart-heading-stats-${index}`}
              >
                <Tooltip title={description}>
                  <Typography variant="h2" color={isError ? 'error' : 'text.primary'}>
                    {value}
                  </Typography>
                </Tooltip>
              </Link>
            )
          )}

          {onRefresh && (
            <Button
              loading={isValidating}
              variant="text"
              sx={{
                color: 'text.secondary',
                minWidth: 30,
              }}
              onClick={onRefresh}
            >
              <RefreshCw size={15} />
            </Button>
          )}
        </LabelsContainer>
      )}
    </HeadingContainer>
  )
}

export default ChartHeader
