import { useContext } from 'react'
import { styled, Box, Typography, Tooltip, tooltipClasses } from '@mui/material'
import { motion } from 'framer-motion'
import { IntegrationContext } from '../../context/integration.context'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { get } from 'lodash'
import { HelpCircle, Plus } from 'lucide-react'
import { useTheme } from '@mui/styles'
import { format } from 'date-fns'

import { Chip } from 'common/components/Chip'
import { ModeSelect } from './ModeSelect'

const getTypeInfo = () => {
  return {
    title: 'AWS Lambda',
    Icon: <IconAwsLambda sx={{ marginRight: '10px' }} size={12} />,
  }
}

const AddNewTag = ({ onTagClick }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={onTagClick}
    >
      <Plus size={15} style={{ margin: '0 5px 0 0', color: theme.palette.grey.light }} />
      <Typography variant="textTertiary" color="text.secondary">
        Add Tag
      </Typography>
    </Box>
  )
}
export const Resource = ({ setAnchorEl, resource }) => {
  const theme = useTheme()

  const {
    resourceRowStyles,
    changedResources,
    onResourcePropertyChange,
    isMaximumSelected,
    resourceCompatibility,
  } = useContext(IntegrationContext)

  const {
    id,
    aws_lambda_name,
    aws_lambda_description,
    tag_region,
    aws_lambda_runtime,
    aws_lambda_last_modified,
    sdk_version,
    extension_version,
    tag_namespace,
    tag_environment,
    instrument_mode,
  } = resource

  const isChangeDisabled = isMaximumSelected && !changedResources[id]
  /**
   * Check if values have changed and use the new value instead of the initial
   */
  const environment = get(changedResources, `${id}.tag_environment`, tag_environment)
  const namespace = get(changedResources, `${id}.tag_namespace`, tag_namespace)

  const resourceMode = get(changedResources, `${id}.instrument_mode`, instrument_mode)

  const canUseProd = resourceCompatibility?.mode?.prod?.runtimes?.includes(aws_lambda_runtime)
  const canUseDev = resourceCompatibility?.mode?.dev?.runtimes?.includes(aws_lambda_runtime)

  const { Icon } = getTypeInfo()

  const onChange = async ({ key, value }) => {
    if (!isChangeDisabled) {
      onResourcePropertyChange({ key, value, resource })
    }
  }
  const onTagClick = (e, key) => setAnchorEl({ target: e.currentTarget, resource, key })
  const tags = [
    { key: 'tag_environment', value: environment },
    { key: 'tag_namespace', value: namespace },
  ]

  return (
    <ResourceContainer
      sx={{
        ...resourceRowStyles?.wrapper,
      }}
      component={motion.div}
      initial={{ y: 5, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.2, ease: 'easeInOut' },
      }}
    >
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box display="flex" alignItems="center" component="span">
          {Icon}

          <Typography sx={{ wordBreak: 'break-word' }}>{aws_lambda_name}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box component="span">
          <Typography variant="textTertiary" color="text.secondary">
            {tag_region}
          </Typography>
        </Box>
        <Box component="span">
          <Typography variant="textTertiary" color="text.secondary">
            {aws_lambda_runtime}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Typography variant="textTertiary" color="text.secondary">
          {format(new Date(aws_lambda_last_modified), 'MMM dd, yyyy hh:mm aa')}
        </Typography>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        {tags.map(({ key, value }) => (
          <Box component="span" key={`resource-${id}-tag-${key}`}>
            {value ? (
              <Chip
                label={value}
                onClick={(e) => onTagClick(e, key)}
                tooltipProps={{
                  sx: {
                    maxWidth: '100%',
                  },
                }}
                onDelete={() => {
                  onChange({
                    value: '',
                    key,
                  })
                }}
              />
            ) : (
              <AddNewTag onTagClick={(e) => onTagClick(e, key)} />
            )}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <ModeSelect
          value={resourceMode}
          canUseProd={canUseProd}
          canUseDev={canUseDev}
          onChange={(value) => onChange({ value, key: 'instrument_mode' })}
        />
      </Box>

      <Box
        sx={{
          ...resourceRowStyles?.children,
          justifyContent: 'flex-end',
        }}
      >
        {(aws_lambda_description || sdk_version || extension_version) && (
          <CustomTooltip
            title={
              <Box minWidth={300}>
                <Box>
                  <Typography variant="textTertiary" color="text.secondary">
                    Description
                  </Typography>
                  <Typography variant="textSecondary">{aws_lambda_description || '-'}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography variant="textTertiary" color="text.secondary">
                      SDK Version
                    </Typography>
                    <Typography variant="textSecondary">{sdk_version || '-'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="textTertiary" color="text.secondary">
                      Extension Version
                    </Typography>
                    <Typography variant="textSecondary">{extension_version || '-'}</Typography>
                  </Box>
                </Box>
              </Box>
            }
            placement="left"
          >
            <HelpCircle style={{ marginLeft: 6, color: theme.palette.text.secondary }} size={14} />
          </CustomTooltip>
        )}
      </Box>
    </ResourceContainer>
  )
}

const ResourceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  minWidth: '1000px',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.border.main}`,
  marginBottom: '15px',
  borderRadius: 4,
  transition: 'all 0.25s ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}))

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})
