import { ROW_HEIGHT, TraceContext } from '../context/Trace.context'
import { forwardRef, useContext } from 'react'
import { TreeItem, TreeView, treeItemClasses, useTreeItem } from '@mui/lab'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { FilterContext } from 'filter/context/FilterContext'
import { Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { IconDangerTriangle } from 'common/icons/IconDangerTriangle'
import { getSpanDetails, getSpanId } from '../util/getSpanDetails'
import { eventsOptions } from 'filter/context/FilterContext'

const countStyles = { marginTop: '1px', marginLeft: '4px' }

const TREE_NODE_BASE_SPACING = 15

const getTreeLines = ({ currentSpan, isGroup, borderColor, hasSiblings }) => {
  const { parentSpanId, indent, childCount } = currentSpan
  const isRootSpan = !parentSpanId

  const diffStyles = isGroup
    ? {
        left: `${indent * TREE_NODE_BASE_SPACING}px`,
        width: `10px`,
        display: hasSiblings ? 'block' : 'none',
        borderLeft: `1px solid ${borderColor}`,
      }
    : {
        width: `${isRootSpan ? TREE_NODE_BASE_SPACING : TREE_NODE_BASE_SPACING * 2}px`,
        left: `${(indent || 1) * TREE_NODE_BASE_SPACING}px`,
        display: isRootSpan && !childCount ? 'none' : 'block',
        borderLeft: isRootSpan ? 'none' : `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
      }
  return {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: `-${ROW_HEIGHT / 2}px`,
      height: '100%',
      zIndex: 1,
      ...diffStyles,
    },
  }
}
const SpanTreeItem = forwardRef(({ nodeId, currentSpan }, ref) => {
  const theme = useTheme()
  const { hoveredSpan, setHoveredSpan, selectedSpan } = useContext(TraceContext)
  const { expanded: isCurrentSpanExpended, handleExpansion, handleSelection } = useTreeItem(nodeId)
  const { id, isEvent, hasError, childCount, tags, name, indent } = currentSpan
  const selectedSpanId = getSpanId(selectedSpan)
  const hoveredSpanId = getSpanId(hoveredSpan)

  const isSelected = selectedSpanId === id
  const isHovered = hoveredSpanId === id

  let label = eventsOptions.reduce((acc, option) => {
    if (option.id !== tags?.error?.type && option.id !== tags?.warning?.type) return acc
    const errorName = tags?.error?.name === 'string' ? tags?.error?.message : tags?.error?.name
    if (option.isUncaughtError) {
      return `Uncaught • ${errorName}`
    } else if (option.isCaughtError) {
      return `Caught • ${errorName}`
    } else if (errorName) {
      return `Unspecified • ${errorName}`
    } else if (option.id === tags?.warning?.type) {
      return `Warning • ${tags?.warning?.message}`
    } else {
      return name
    }
  }, name)

  const tooltipTitle = label

  const lineStyles = getTreeLines({
    currentSpan,
    borderColor: theme.palette.border.main,
  })

  return (
    <Box
      sx={{
        height: ROW_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingLeft: `${indent * TREE_NODE_BASE_SPACING + 30}px`,
        cursor: 'pointer',
        background: isHovered || isSelected ? theme.palette.grey.dark : 'none',
        // Show tree node left lines
        ...lineStyles,
      }}
      onMouseEnter={() => {
        setHoveredSpan(currentSpan)
      }}
      onMouseLeave={() => {
        setHoveredSpan(null)
      }}
      onClick={handleSelection}
      ref={ref}
    >
      {isEvent ? (
        <Box
          sx={{
            marginRight: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconDangerTriangle
            style={{
              fill: hasError ? theme.palette.colors.redPrimary : theme.palette.primary.main,
            }}
          />
        </Box>
      ) : childCount ? (
        <Box
          onClick={handleExpansion}
          sx={{
            marginRight: '10px',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <CounterBox>
            {childCount}
            {isCurrentSpanExpended ? (
              <ChevronDown size={12} sx={countStyles} />
            ) : (
              <ChevronRight size={12} sx={countStyles} />
            )}
          </CounterBox>
        </Box>
      ) : null}
      <Tooltip title={tooltipTitle} enterDelay={1000}>
        <Typography
          variant="textSecondary"
          sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}
          color={hasError ? 'error.main' : 'text.primary'}
        >
          {label}
        </Typography>
      </Tooltip>
    </Box>
  )
})

const RenderTreeNodes = ({ span, hasSiblings }) => {
  const theme = useTheme()
  const { timelineSpans, traceEvents } = useContext(TraceContext)
  const currentSpan = getSpanDetails({ span, timelineSpans, traceEvents })
  const { spans, id } = currentSpan

  const lineStyles = getTreeLines({
    hasSiblings,
    currentSpan,
    isGroup: true,
    borderColor: theme.palette.border.main,
  })
  return (
    <TreeItem
      key={`trace-spans-tree-span-${id}`}
      ContentComponent={SpanTreeItem}
      nodeId={id}
      ContentProps={{
        currentSpan,
      }}
      sx={{
        [`& .${treeItemClasses.group}`]: {
          marginLeft: 0,
          position: 'relative',
          ...lineStyles,
        },
      }}
    >
      {spans?.map((span, i) => (
        <RenderTreeNodes
          key={`trace-spans-tree-span-${id}-${i}`}
          span={span}
          hasSiblings={!!spans[i + 1]}
        />
      ))}
    </TreeItem>
  )
}
export const RenderSpansTree = () => {
  const { trace, timelineSpans, expanded, setExpanded } = useContext(TraceContext)
  const { setFilterValue } = useContext(FilterContext)

  if (!trace || !timelineSpans?.length) {
    return null
  }
  return (
    <TreeView
      aria-label="file system navigator"
      expanded={expanded}
      onNodeToggle={(event, expandedSpanIds) => {
        /**
         * If toggle expanded state
         */

        setExpanded(expandedSpanIds)
      }}
      onNodeSelect={(event, spanId) => setFilterValue('explorerTraceSpanId', spanId)}
      sx={{
        height: '100%',
      }}
    >
      <RenderTreeNodes span={trace} />
    </TreeView>
  )
}

const CounterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  fontSize: theme.typography.textSecondary.fontSize,
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey.medium,
  padding: '2px 6px 2px 8px',
  borderRadius: 2,
}))
